
import {Injectable} from '@angular/core';
// import {AuthHttp} from 'angular2-jwt';
import {Response, Http, Headers, RequestOptions} from '@angular/http';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
@Injectable(/* {
  providedIn: 'root'
} */)
export class PagosService {
  private baseUrl = 'http://lfdi.bitronedev.com/public/';
constructor(public http: Http ) {


   }

   getPagos(): any {
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();

    if (jwt) {
      authHeader.append('Authorization', jwt);
    }

    let options = new RequestOptions({headers: authHeader});

    return this.http.get(`${this.baseUrl}pagos/getPagos2`, options)
      .map(this.extractData)
      .catch(this.handleError);
  }

  getPagosPresupuestos(presupuesto): Observable<any> {
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');

    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'presupuesto=' + presupuesto 
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}pagos/getPagosPresupuestos`, body, options)
      .map(this.extractData)
      .catch(this.handleError);
  }
  addPagos(cliente, proyecto, servicio, importe_total, importe_pagado, fecha_inicio): Observable<any> {
    console.log(servicio);
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'cliente=' + cliente + '&proyecto=' + proyecto + '&servicio=' + servicio + '&importe_total=' + importe_total + '&importe_pagado=' + importe_pagado + '&fecha_inicio=' + fecha_inicio  ;
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}pagos_r/addPagos2`, body, options)
      .map(this.extractData)
      .catch(this.handleError);
  }



  
  addPayment(clientData, allData, importe, datefecha2, aplazado, pendientedepago): Observable<any> {
    console.log(allData);
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'nombre=' + clientData.nombre  + '&CIF=' + clientData.CIF + '&fecha_contacto=' + datefecha2 + '&fecha_ini=' + '-' + '&telefono=' + clientData.telefono + '&proyectos=' + allData.proyecto + '&email=' + clientData.email + '&direccion=' + clientData.direccion + '&codigo_postal=' + clientData.codigo_postal + '&ciudad=' + clientData.ciudad + '&empresa=' + clientData.empresa  + '&servicios=' + allData.servicio + '&servicios_id=' + allData.id_servicio + '&sectornames=' + '-' + '&personas_encargados=' + '-' + '&estado=' + '-' + '&manager=' + '-' + '&comentarios=' + '-' + '&uid=' + clientData.id + '&pagado=' + importe + '&aplazado=' + aplazado + '&pais=' + clientData.pais + '&remitente=' + allData.remitente + '&totalapagar=' + allData.totalapagar
    + '&id_anterior=' + allData.venta_id + '&presupuesto=' + allData.numero + '&pendientedepago=' + pendientedepago;
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}pagos/addpayment`, body, options)
      .map(this.extractData)
      .catch(this.handleError);
  }

  /* SELECT CONCAT(Clientes.nombre, ' ', Clientes.apellido, ' ', Clientes.apellido2 ) AS Cliente, Pagos.presupuesto, COUNT(Pagos.presupuesto) as Veces_pagados, pagos_aplazados.plazos-COUNT(Pagos.presupuesto) AS plazos_pendientes, Presupuestos.importe as Total_a_pagar, SUM(importe_pagado) AS Pagado, Presupuestos.importe-SUM(importe_pagado) AS DEBE, Proyectos.nombre FROM Pagos, Clientes, Presupuestos, pagos_aplazados, Proyectos WHERE Pagos.cliente=Clientes.id AND Pagos.presupuesto=Presupuestos.numero AND Pagos.presupuesto=pagos_aplazados.presupuesto AND Proyectos.cliente=Pagos.cliente AND Presupuestos.servicio=Proyectos.servicios GROUP BY Pagos.presupuesto HAVING COUNT(Pagos.presupuesto) >= 1 AND (Pagado!=Total_a_pagar AND Pagado<Total_a_pagar) */

  addPayment2(clientData, allData, importe, datefecha2, aplazado, pendientedepago): Observable<any> {
    console.log(allData);
    console.log(clientData);

    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'nombre=' + clientData.cliente  + '&CIF=' + clientData.CIF + '&fecha_contacto=' + datefecha2 + '&fecha_ini=' + '-' + '&telefono=' + clientData.tel + '&proyectos=' + allData.proyecto + '&email=' + clientData.email + '&direccion=' + clientData.direccion + '&codigo_postal=' + clientData.codigo_postal + '&ciudad=' + clientData.ciudad + '&empresa=' + clientData.empresa  + '&servicios=' + allData.servicio + '&servicios_id=' + allData.id_servicio + '&sectornames=' + '-' + '&personas_encargados=' + '-' + '&estado=' + '-' + '&manager=' + '-' + '&comentarios=' + '-' + '&uid=' + clientData.id_anterior + '&pagado=' + importe + '&aplazado=' + aplazado  + '&remitente=' + allData.remitente + '&totalapagar=' + allData.totalapagar
    + '&id_anterior=' + clientData.id_anterior + '&presupuesto=' + allData.numero + '&pendientedepago=' + pendientedepago;
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}pagos/addpayment`, body, options)
      .map(this.extractData2)
      .catch(this.handleError);
  }


  modPagos( datatomod ): any {
    
    // let jwt = localStorage.getItem('id_token');
   // let user_id= localStorage.getItem('user_login');
   let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
   let body = 'id=' + datatomod.id + '&nombre=' + datatomod.nombre + '&apellido=' + datatomod.apellido + '&apellido2=' + datatomod.apellido2 + '&CIF=' + datatomod.cif + '&telefono=' + datatomod.telefono + '&email=' + datatomod.email + '&direccion=' + datatomod.direccion + '&codigo_postal=' + datatomod.codigo_postal + '&ciudad=' + datatomod.ciudad + '&empresa=' + datatomod.empresa + '&comentarios=' + datatomod.comentarios ;
    let authHeader = new Headers();

    if (jwt) {
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');

      authHeader.append('Authorization', jwt);
    //  console.log(authHeader);
    }

    let options = new RequestOptions({headers: authHeader});
 //   console.log(body);

    return this.http.post(`${this.baseUrl}pagos/modPagos`, body,  options).map(this.extractData).catch(this.handleError);
  }














  remPagos(datatosend): Observable<any> {
   
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'id=' + datatosend  ;
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}pagos/remPagos`, body, options)
      .map(this.extractData2)
      .catch(this.handleError);
  }
  /**
   * Extracting data.
   *
   * @param res
   * @returns {any|{}}
   */
  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private extractData2(res: Response) {
    let body = res;
    return body || {};
  }
 /**
  * Handling errors.
  *
  * @param error
  * @returns {ErrorObservable}
  */
 private handleError(error: any) {
  console.log(error);
   let errMsg = (error.message) ? error.message :
     error.status ? `${error.status} - ${error.statusText}` : 'Server error';
   console.log(errMsg); // log to console instead
   return Observable.throw(errMsg);
 }
}

