import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector   : 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog5.component.html',
    styleUrls  : ['./confirm-dialog5.component.scss']
})
export class FuseConfirmDialog5Component
{
    public confirmMessage: string;

    constructor(public dialogRef: MatDialogRef<FuseConfirmDialog5Component>)
    {
    }

}
