
var navigationPre = [
    {
        'id'       : 'Contactos2',
        'title'    : 'Contactos',
        'translate': 'NAV.Contactos2',
        'type'     : 'item',
        'icon'     : 'contact_phone',
        'url'      : '/contactos2'
    },
    {
        'id'   : 'Ventas',
        'title': 'Ventas',
        'type' : 'item',
        'url'  : '/ventas',
        'icon'     : 'ring_volume',
    },
    
    /* {
        'id'       : 'Ventas',
        'title'    : 'Ventas',
        'translate': 'NAV.Ventas',
        'type'     : 'collapse',
        'icon'     : 'ring_volume',
      
        'children' : [
            {
                'id'   : 'Ventas',
                'title': 'Ventas',
                'type' : 'item',
                'url'  : '/ventas',
                'icon'     : 'ring_volume',
            },
            {
                'id'   : 'deleted',
                'title': 'Eliminados',
                'type' : 'item',
                'url'  : '/ventas/deleted',
                'icon'     : 'delete_outline',
            },
    ]}, */
    {
        'id'       : 'Clientes',
        'title'    : 'Clientes',
        'translate': 'NAV.CLIENTES',
        'type'     : 'item',
        'icon'     : 'face',
        'url'      : '/clientes'
    },
    {
        'id'       : 'Clientespend',
        'title'    : 'Clientes pendientes',
        'translate': 'NAV.CLIENTESPEND',
        'type'     : 'item',
        'icon'     : 'account_box',
        'url'      : '/clientespend'
    },
    {
        'id'       : 'Presupuestos',
        'title'    : 'Presupuestos',
        'translate': 'NAV.PRESUPUESTOS',
        'type'     : 'item',
        'icon'     : 'assignment',
        'url'      : '/presupuestos'
    },
    {
        'id'       : 'Patentes',
        'title'    : 'Patentes',
        'translate': 'NAV.PATENTES',
        'type'     : 'item',
        'icon'     : 'class',
        'url'      : '/patentes'
    },
    {
        'id'       : 'ProjectTracking',
        'title'    : 'Project-Tracking', 
        
        'type'     : 'item',
        'url'      : '/project_tracking',
        'icon'     : 'developer_board'
      
    },
        {
        'id'       : 'Informes',
        'title'    : 'Informes', 
        
        'type'     : 'item',
        'url'      : '/informes',
        'icon'     : 'developer_board'
      
    },
    /*  {
        'id'       : 'temp_pro',
        'title'    : 'Proyectos-Estado',
        'translate': 'NAV.temp_pro',
        'type'     : 'item',
        'icon'     : 'category',
        'url'      : '/temp_pro'
    },
    {
        'id'       : 'temp_pro_history',
        'title'    : 'Proyectos-Estado-Log',
        'translate': 'NAV.temp_pro_history',
        'type'     : 'item',
        'icon'     : 'category',
        'url'      : '/temp_pro_history'
    },
   {
        'id'       : 'Proyectos',
        'title'    : 'Proyectos',
        'translate': 'NAV.PROYECTOS',
        'type'     : 'item',
        'icon'     : 'category',
        'url'      : '/proyectos'
    },
    
    {
        'id'       : 'Sectores',
        'title'    : 'Sectores',
        'translate': 'NAV.SECTORES',
        'type'     : 'item',
        'icon'     : 'scatter_plot',
        'url'      : '/sectores'
    },
    {
        'id'       : 'ServiceEnLine',
        'title'    : 'ServiceEnLine',
        'translate': 'NAV.ServiceEnLine',
        'type'     : 'item',
        'icon'     : 'timeline',
        'url'      : '/ServiceEnLine'
    },
    {
        'id'       : 'Servicios',
        'title'    : 'Servicios',
        'translate': 'NAV.SERVICIOS',
        'type'     : 'item',
        'icon'     : 'extension',
        'url'      : '/servicios'
    }, */
    /* {
        'id'       : 'RRHH',
        'title'    : 'RRHH',
        'translate': 'NAV.RRHH',
        'type'     : 'item',
        'icon'     : 'people_outline',
        'url'      : '/rrhh'
    }, */
    {
        'id'       : 'Pagos',
        'title'    : 'Resumen de Pagos',
        'translate': 'NAV.PAGOS',
        'type'     : 'item',
        'icon'     : 'equalizer',
        'url'      : '/pagos'
    },
    {
        'id'       : 'Pagos Aplazados',
        'title'    : 'Deudores',
        'translate': 'NAV.PAGOSAPLAZADOS',
        'type'     : 'item',
        'icon'     : 'equalizer',
        'url'      : '/pagos_aplazados'
    },
    {
        'id'       : 'Tareas',
        'title'    : 'Tareas',
        'translate': 'NAV.TAREAS',
        'type'     : 'item',
        'icon'     : 'equalizer',
        'url'      : '/tareas'
    },
    /* {
    'id'       : 'Facturas',
    'title'    : 'Facturas',
    'translate': 'NAV.FACTURAS',
    'type'     : 'item',
    'icon'     : 'equalizer',
    'url'      : '/facturas'
}, */
/*     {
        'id'       : 'Pagos2',
        'title'    : 'Pagos',
        'translate': 'NAV.PAGOS2',
        'type'     : 'item',
        'icon'     : 'euro_symbol',
        'url'      : '/pagos_r'
    }, */
/*     {
        'id'      : 'applications',
        'title'   : 'Applications',
        'translate': 'NAV.APPLICATIONS',
        'type'    : 'group',
        'children': [
            {
                'id'   : 'sample',
                'title': 'Sample',
                'translate': 'NAV.SAMPLE.TITLE',
                'type' : 'item',
                'icon' : 'email',
                'url'  : '/sample',
                'badge': {
                    'title': 25,
                    'translate': 'NAV.SAMPLE.BADGE',
                    'bg'   : '#F44336',
                    'fg'   : '#FFFFFF'
                }
            }
        ]
    } */
];
/* 
else

if (localStorage.getItem('user_level') === '2'){    
    var navigationPre = [

        {
            'id'   : 'Ventas',
            'title': 'Ventas',
            'type' : 'item',
            'url'  : '/ventas',
            'icon'     : 'ring_volume',
        },

        {
            'id'       : 'Clientes',
            'title'    : 'Clientes',
            'translate': 'NAV.CLIENTES',
            'type'     : 'item',
            'icon'     : 'face',
            'url'      : '/clientes'
        },
        {
            'id'       : 'Clientespend',
            'title'    : 'Clientes pendientes',
            'translate': 'NAV.CLIENTESPEND',
            'type'     : 'item',
            'icon'     : 'account_box',
            'url'      : '/clientespend'
        },
        {
            'id'       : 'Presupuestos',
            'title'    : 'Presupuestos',
            'translate': 'NAV.PRESUPUESTOS',
            'type'     : 'item',
            'icon'     : 'assignment',
            'url'      : '/presupuestos'
        },



        {
            'id'       : 'Pagos',
            'title'    : 'Resumen de Pagos',
            'translate': 'NAV.PAGOS',
            'type'     : 'item',
            'icon'     : 'equalizer',
            'url'      : '/pagos'
        },
        {
            'id'       : 'Pagos Aplazados',
            'title'    : 'Deudores',
            'translate': 'NAV.PAGOSAPLAZADOS',
            'type'     : 'item',
            'icon'     : 'equalizer',
            'url'      : '/pagos_aplazados'
        },
        {
            'id'       : 'Tareas',
            'title'    : 'Tareas',
            'translate': 'NAV.TAREAS',
            'type'     : 'item',
            'icon'     : 'equalizer',
            'url'      : '/tareas'
        },

    ];
    }


 else   if (localStorage.getItem('user_level') === '3'){    
        var navigationPre = [

            

            {
                'id'       : 'Clientes',
                'title'    : 'Clientes',
                'translate': 'NAV.CLIENTES',
                'type'     : 'item',
                'icon'     : 'face',
                'url'      : '/clientes'
            },
            {
                'id'       : 'Clientespend',
                'title'    : 'Clientes pendientes',
                'translate': 'NAV.CLIENTESPEND',
                'type'     : 'item',
                'icon'     : 'account_box',
                'url'      : '/clientespend'
            },


            {
                'id'       : 'ProjectTracking',
                'title'    : 'Project-Tracking', 
                
                'type'     : 'item',
                'url'      : '/project_tracking',
                'icon'     : 'developer_board'
              
            },
            {
                'id'       : 'Tareas',
                'title'    : 'Tareas',
                'translate': 'NAV.TAREAS',
                'type'     : 'item',
                'icon'     : 'equalizer',
                'url'      : '/tareas'
            },
            {
                'id'       : 'temp_pro',
                'title'    : 'Proyectos-Estado',
                'translate': 'NAV.temp_pro',
                'type'     : 'item',
                'icon'     : 'category',
                'url'      : '/temp_pro'
            },
            {
                'id'       : 'temp_pro_history',
                'title'    : 'Proyectos-Estado-Log',
                'translate': 'NAV.temp_pro_history',
                'type'     : 'item',
                'icon'     : 'category',
                'url'      : '/temp_pro_history'
            },



        ];
        }
        else

        if (localStorage.getItem('user_level') === '4'){    
            var navigationPre = [
    
                  
            {
                'id'       : 'Clientes',
                'title'    : 'Clientes',
                'translate': 'NAV.CLIENTES',
                'type'     : 'item',
                'icon'     : 'face',
                'url'      : '/clientes'
            },
            {
                'id'       : 'Clientespend',
                'title'    : 'Clientes pendientes',
                'translate': 'NAV.CLIENTESPEND',
                'type'     : 'item',
                'icon'     : 'account_box',
                'url'      : '/clientespend'
            },


            {
                'id'       : 'Tareas',
                'title'    : 'Tareas', 
                
                'type'     : 'item',
                'url'      : '/tareas',
                'icon'     : 'equalizer'
              
            },
      
        

                
    
 
    
    
    
            ];
            } */


export const navigation = navigationPre

