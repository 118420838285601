import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Response, Http, Headers, RequestOptions} from '@angular/http';

import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

import 'rxjs/Rx';
import 'rxjs/add/operator/map';
@Injectable()
export class ProfileService  implements Resolve<any>
{
    private baseUrl = 'http://lfdi.bitronedev.com/public/';
    timeline: any;
    about: any;
    photosVideos: any;

    timelineOnChanged: BehaviorSubject<any> = new BehaviorSubject({});
    aboutOnChanged: BehaviorSubject<any> = new BehaviorSubject({});
    photosVideosOnChanged: BehaviorSubject<any> = new BehaviorSubject({});

    constructor(private http: Http)
    {


    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getTimeline(),
                this.getAbout(),
                this.getPhotosVideos()
                
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get timeline
     */



     getServiciosbypresup(cliente):  Observable<any>  {
        let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
        let authHeader = new Headers();
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
        if (jwt) {
          authHeader.append('Authorization', jwt);
        }
        let body = 'cliente=' + cliente ;
        console.log(body)
        let options = new RequestOptions({headers: authHeader});
    
     
        return this.http.post(`${this.baseUrl}presupuestos/getPresupuestosCliente`, body, options)
          .map(this.extractData)
          .catch(this.handleError);
      }

      getServices(cliente):  Observable<any>  {
        let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
        let authHeader = new Headers();
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
        if (jwt) {
          authHeader.append('Authorization', jwt);
        }
        let body = 'cliente=' + cliente ;
        console.log(body)
        let options = new RequestOptions({headers: authHeader});
    
     
        return this.http.post(`${this.baseUrl}presupuestos/getServicesOfClient`, body, options)
          .map(this.extractData)
          .catch(this.handleError);
      }


      getClientesById(cliente): Observable<any> {
        console.log(cliente)
        let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
        let authHeader = new Headers();
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
        if (jwt) {
          authHeader.append('Authorization', jwt);
        }
        let body = 'id=' + cliente 
        let options = new RequestOptions({headers: authHeader}); 
    
        return this.http.post(`${this.baseUrl}clientes/getClientesById`, body, options)
          .map(this.extractData)
          .catch(this.handleError);
      }
    

      UpdateClientesById(cliente, color): Observable<any> {
        console.log(color)
        let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
        let authHeader = new Headers();
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
        if (jwt) {
          authHeader.append('Authorization', jwt);
        }
        let body = 'id=' + cliente + '&color=' + color
        let options = new RequestOptions({headers: authHeader}); 
    
        return this.http.post(`${this.baseUrl}clientes/UpdateClientesById`, body, options)
          .map(this.extractData2)
          .catch(this.handleError);
      }


      getPagosbyID(cliente): Observable<any> {
        console.log(cliente)
        let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
        let authHeader = new Headers();
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
        if (jwt) {
          authHeader.append('Authorization', jwt);
        }
        let body = 'cliente=' + cliente 
        let options = new RequestOptions({headers: authHeader}); 
    
        return this.http.post(`${this.baseUrl}pagos/getPagosbyID`, body, options)
          .map(this.extractData)
          .catch(this.handleError);
      }
    




    getTimeline(): Promise<any[]>
    {
        return new Promise((resolve, reject) => {

            this.http.get('api/profile-timeline')
                .subscribe((timeline: any) => {
                    this.timeline = timeline;
                    this.timelineOnChanged.next(this.timeline);
                    resolve(this.timeline);
                }, reject);
        });
    }

    /**
     * Get about
     */
    getAbout(): Promise<any[]>
    {
        return new Promise((resolve, reject) => {

            this.http.get('api/profile-about')
                .subscribe((about: any) => {
                    this.about = about;
                    this.aboutOnChanged.next(this.about);
                    resolve(this.about);
                }, reject);
        });
    }

    /**
     * Get photos & videos
     */
    getPhotosVideos(): Promise<any[]>
    {
        return new Promise((resolve, reject) => {

            this.http.get('api/profile-photos-videos')
                .subscribe((photosVideos: any) => {
                    this.photosVideos = photosVideos;
                    this.photosVideosOnChanged.next(this.photosVideos);
                    resolve(this.photosVideos);
                }, reject);
        });
    }


  /**
   * Extracting data.
   *
   * @param res
   * @returns {any|{}}
   */
   private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private extractData2(res: Response) {
    
    return res;
  }
 /**
  * Handling errors.
  *
  * @param error
  * @returns {ErrorObservable}
  */
 private handleError(error: any) {
   let errMsg = (error.message) ? error.message :
     error.status ? `${error.status} - ${error.statusText}` : 'Server error';
   console.log(errMsg); // log to console instead
   return Observable.throw(errMsg);
 }

}
