import { Component, ViewEncapsulation, ViewContainerRef } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { ProfileService } from '../profile/profile.service';
import { ColorPickerService, Cmyk } from 'ngx-color-picker';
@Component({
    selector     : 'fuse-profile',
    templateUrl  : './profile.component.html',
    styleUrls    : ['./profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class FuseProfileComponent
{
    toggle: boolean ;

     rgbaText: string ;
  
  public colorList = [
      { key: "verde", value: "#0da713", friendlyName: "Verde" },
      {key: "orange", value: "#ff9800", friendlyName: "Orange" },
      {key: "Red",     value: "#e60606", friendlyName: "Infrared" },


  ];
  
  
    presetValues: string[] = [];
  
    selectedColor: string ;
  
    public cmykColor: Cmyk = new Cmyk(0, 0, 0, 0);

    userlevel: any;
    data: any;
    color: any;
    constructor(private router: Router, private route: ActivatedRoute, private profileService: ProfileService, public vcRef: ViewContainerRef, private cpService: ColorPickerService)
    {
      this.color = '#06394a'
        this.presetValues = this.getColorValues();
        this.toggle = false
        this.rgbaText = 'rgba(165, 26, 214, 0.2)'
        this.selectedColor = 'color1'
        this.userlevel = localStorage.getItem('user_level')
        let cliente = localStorage.getItem('user_profile')
/*         let id = +this.route.snapshot.params;
        console.log(cliente) */

        this.profileService.getClientesById(cliente).subscribe(about => {
            this.data = about.clientes[0];
            console.log(this.data)
            let b = {key: "new", value: this.data.color, friendlyName: "new"}
            this.colorList.push(b)
            console.log(this.colorList)
        });
    }


    getColorValues(){
        return this.colorList.map(c => c.value);
        }
        public onEventLog(event: string, data: any): void {
            console.log(event, data);
          }
        
          public onChangeColorCmyk(color: string): Cmyk {
            const hsva = this.cpService.stringToHsva(color);
        
            if (hsva) {
              const rgba = this.cpService.hsvaToRgba(hsva);
        
              return this.cpService.rgbaToCmyk(rgba);
            }
        
            return new Cmyk(0, 0, 0, 0);
          }
        
          public onChangeColorHex8(color: string): string {
            const hsva = this.cpService.stringToHsva(color, true);
        
            if (hsva) {
              return this.cpService.outputFormat(hsva, 'rgba', null);
            }
        
            return '';
          }      

          somethingChanged(){
            console.log(this.colorList[3].value)
            this.profileService.UpdateClientesById(this.data.id, this.colorList[3].value).subscribe(result => {
              /* this.data = about.clientes[0]; */
              console.log(this.data)
         
          });
            
          }
test(){
    console.log(this.colorList[0].value)
}
}
