import { FuseMatSidenavHelperDirective } from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.directive';
import { Component, ViewChild, OnInit, RendererStyleFlags2, OnDestroy, AfterViewInit, Input, Inject } from '@angular/core';
import { ProjectTracking } from './project_tracking.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ButtonRendererComponent } from './renderer.component';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {AsignarComponent} from '../asignar/asignar.component'
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect, VERSION } from '@angular/material';
import { LoginService } from 'app/main/content/login/login.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { stubFalse } from 'lodash';
declare var jquery: any; declare var $: any;
interface Bank {
  id: string;
  name: string;

}

/* interface Fases {
  value: string;
  viewValue: string;
    service: string;
} */



@Component({
  selector: 'fuse-project-tracking',
  templateUrl: './project_tracking.component.html',
  styleUrls: ['./project_tracking.component.scss']
})
export class ProjectTrackingComponent implements OnInit {
  public loading = false;
  public gridApi;
  private gridColumnApi;
  public gridOptions;
  // private rowData: any[];
  trigger: any;
  // private columnDefs;
  // private rowData;
  private rowSelection;
  checked = true;

  butonrowclicked = false;
  addbutton = false;
  dat: any;
  datatosend: any;
  sometrig: any;
  datatomod: any;
  columnDefs: any;
  editrigger =  false;
  newItem: any;
  columnTypes: any;
  rowData: any;
  data3: any;
  data4: any;
  data5: any;
  data6: any;
  table: any;
  htmlid: any;
  selectedRow: any;
  fases: any;
  fase: any;
  allUsers: any;
 manager: any;
 managers: any;
 pressed: any;
 frameworkComponents: any;
  public trig2;
  editType: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>; private components;
  constructor(private projects: ProjectTracking, private router: Router,
    private loginService1: LoginService, public dialog: MatDialog, ) {
      this.frameworkComponents = {
        buttonRenderer: ButtonRendererComponent,
      }
    this.editType = 'fullRow';
    this.rowSelection = 'single';
    this.htmlid = "algo";

  }

  ngOnInit() {
    this.trigger = false;
    this.allUsers = []
    this.loading = true;
    this.pressed = false;
    this.projects.getFases()
    .subscribe(res => {
      console.log(res)
      this.fases = res.fases
console.log(this.fases)
this.data4 = {};
for (var i = 0; i < res.fases.length; i++) {
  this.data4 [res.fases[i].id] = res.fases[i].fase_name;
}
this.data4['0'] = '-'
console.log(this.data4)
this.projects.getUsuarios().subscribe(usuarios => {
  this.allUsers = usuarios.members
  console.log(this.allUsers) 

  this.data3 = {};
  for (var i = 0; i < usuarios.members.length; i++) {
    this.data3 [usuarios.members[i].id] = usuarios.members[i].nombre;
  }
 // this.data3['0'] = '-'
  console.log(this.data3)
  
 /*  this.managers = usuarios.members
  this.manager = usuarios.members[0] */
  this.getAllProjects()
})
   
    });


 
    // this.getPersonas();
    this.trig2 = false;
    this.sometrig = false;
    // this.getSectores();
    if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') || localStorage.getItem("id_token") === null) {
      console.log(localStorage)
      this.router.navigate(['/login']);
    } else {
      this.loginService1.checkToken().subscribe(rescat => {
        console.log(rescat);
        if (rescat.response) {
          if (rescat.response === 1) {



            // this.getPersonas();
          }
          else {

            this.router.navigate(['/login']);
          }
        }
        else {
          this.router.navigate(['/login']);
        }

      })
    }






    // this.getProyectos(this.carMappings);
  }
  onBtnClick1(e) {
    window.open("http://lfdi.bitronedev.com/public/" + e.rowData.numero + ".pdf", "_blank");
    console.log(e)
  }
  openDialog2(item): void {
  }

  openDialog(item): void {

    console.log(item)
    /*  var dataformod2 = this.gridApi.getRowNode(rowindex);
     console.log(dataformod2);
     this.proyectos.modProyectos(dataformod2.data)
     .subscribe(res => {
         
       console.log(res);
           }); */


    const dialogRef = this.dialog.open(AsignarComponent, {
      width: '650px',
      height: '600',
      data: {
        item: item.rowData
      }

    });

    dialogRef.afterClosed().subscribe(result => {
      this.projects.getAllProject()
      .subscribe(res2 => {
        this.rowData = res2.projects;
        this.gridApi.setRowData(this.rowData);

      })
    });
  }



getAllProjects(){
  let data2 = this.data3

  function extractValues(mappings) {
    return Object.keys(mappings);
  }
  function lookupValue(mappings, key) {
    return mappings[key];
  }
  function lookupKey(mappings, name) {
    for (var key in mappings) {
      if (mappings.hasOwnProperty(key)) {
        if (name === mappings[key]) {
          return key;
        }
      }
    }
  }


let data5 = this.data4

  function extractValues2(mappings2) {


    console.log(mappings2)
    return Object.keys(mappings2);
  }
  function lookupValue2(mappings2, key2) {
    console.log(mappings2)
    console.log(key2)

    return mappings2[key2];
  }
  function lookupKey2(mappings2, name2) {
    console.log(mappings2)
    console.log(name2)

    for (var key2 in mappings2) {
      if (mappings2.hasOwnProperty(key2)) {
        if (name2 === mappings2[key2]) {
          return key2;
        }
      }
    }
  }


  let data6 = {0: '-', 1: 'Pendiente', 2: 'Pagado'}
  function extractValues3(mappings3) {
    return Object.keys(mappings3);
  }
  function lookupValue3(mappings3, key3) {
    return mappings3[key3];
  }
  function lookupKey3(mappings3, name3) {
    for (var key3 in mappings3) {
      if (mappings3.hasOwnProperty(key3)) {
        if (name === mappings3[key3]) {
          return key3;
        }
      }
    }
  }



  this.components = { datePicker: getDatePicker() };
  function getDatePicker() {
    function Datepicker() {}
    Datepicker.prototype.init = function(params) {
      this.eInput = document.createElement("input");
      this.eInput.value = params.value;
      $(this.eInput).datepicker({ dateFormat: "dd/mm/yy" });
    };
    Datepicker.prototype.getGui = function() {
      return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function() {
      this.eInput.focus();
      this.eInput.select();
    };
    Datepicker.prototype.getValue = function() {
      return this.eInput.value;
    };
    Datepicker.prototype.destroy = function() {};
    Datepicker.prototype.isPopup = function() {
      return false;
    };
    return Datepicker;
  }
  this.columnDefs = [];
  this.projects.getAllProject()
  .subscribe(res2 => {
    this.rowData = res2.projects;
  //  this.table = res2.projects

this.loading = false;
this.components = {datePicker: getDatePicker()};
for (let key in res2.projects[0]) {

  if (key === 'id') {
    this.dat = {headerName: key, field: key, hide: true };
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    // console.log(this.columnDefs);

}   
else if (key === 'fecha_in') {
  this.dat = {headerName: 'Fecha inicio', field: key, hide: false, width: 120 };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}      
else if (key === 'fecha_prev_ent') {
  this.dat = {headerName: 'Fecha prevista', field: key, hide: false, width: 120 };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);


} 
else if (key === 'fecha_ent') {
  this.dat = {headerName: 'Fecha entrega', field: key, hide: false, width: 120 };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

} 
else if (key === 'serviceName') {
  console.log(this.rowData)
  this.dat = {headerName: 'Servicio', field: key, hide: false, cellStyle:  this.cellStyling,  editable: false };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}   
else if (key === 'Cliente') {
  this.dat = {headerName: key, field: key, hide: false,  editable: false };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}  
else if (key === 'proyectoName') {
  this.dat = {headerName: 'Proyecto', field: key, hide: false,  editable: false };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}    
else if (key === 'DEBE') {
  this.dat = {headerName: 'Pagado', field: key, hide: false, width: 87, cellStyle:  params => params.value > 1 ? { backgroundColor: 'green', color: 'white' } : { backgroundColor: 'red', color: 'white' }, editable: false, 
  cellEditor: "agSelectCellEditor", 
 
  cellEditorParams: {values: extractValues3(data6) },
  valueFormatter: function(params) {
  return lookupValue3(data6, params.value);
  },
  valueParser: function(params) {
  return lookupKey3(data6, params.newValue);
  }
 };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

} 
else  if (key === 'Fases') {
  this.dat = {headerName: 'Fases', width: 240, field: key, hide: false,  editable: false/* ,  cellEditor: "agSelectCellEditor", 
 
  cellEditorParams: {values: extractValues2(data5) },
  valueFormatter: function(params) {
  return lookupValue2(data5, params.value);
  },
  valueParser: function(params) {
  return lookupKey2(data5, params.newValue);
  } */

  };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  
  
  }
else if (key === 'asignado') {
  this.dat = {headerName: 'Asignado', field: key, hide: false, cellStyle:  this.cellStyling2,   editable: false,  cellRenderer: 'buttonRenderer', 
  cellRendererParams: {
     onClick: this.openDialog.bind(this),
  },

  
  
 };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

} /* else if (key === 'comments') {
  this.dat = {headerName: '', field: key, hide: false, cellStyle:  this.cellStyling2,   editable: false,  cellRenderer: 'buttonRenderer', 
  cellRendererParams: {
     onClick: this.openDialog2.bind(this),
  },

  
  
 };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}  */

/* else if (key === 'status') {
  this.dat = {headerName: 'status', field: key, hide: this.button1,  editable: false
};
console.log('      key:', key);
this.columnDefs.push(this.dat);
} */

/*       else  if (key === 'fecha2') {
this.dat = {headerName: 'Fecha', hide: true, field: key, pinned: 'left',   comparator: this.dateComparator, width: 100, editable: true,    cellEditor: "datePicker", 
 };
console.log('      key:', key);
this.columnDefs.push(this.dat);
// console.log(this.columnDefs);

} */


/* else  if (key === 'Fases') {
this.dat = {headerName: key, field: key,   editable: true, width: 300, 
filterParams: {
filterOptions: ['contains', 'notContains'],
textFormatter: function(r) {
if (r == null){ return null}
var r = r.toLowerCase();
// r = r.replace(new RegExp("\\s", 'g'),"");
r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
r = r.replace(new RegExp('æ', 'g'), 'ae');
r = r.replace(new RegExp('ç', 'g'), 'c');
r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
r = r.replace(new RegExp('ñ', 'g'), 'n');
r = r.replace(new RegExp('[òóôõøö]', 'g'), 'o');
r = r.replace(new RegExp('œ', 'g'), 'oe');
r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
return r;
},
debounceMs: 0,
caseSensitive: false,

},



};
console.log('      key:', key);
this.columnDefs.push(this.dat);


} */


else  if (key === 'gestor') {
this.dat = {headerName: 'Gestor', field: key, width: 87,  editable: true, cellStyle:  this.cellStyling3, cellEditor: "agSelectCellEditor", 
cellEditorParams: {values: extractValues(data2) },
valueFormatter: function(params) {
return lookupValue(data2, params.value);
},
valueParser: function(params) {
return lookupKey(data2, params.newValue);
}
};
console.log('      key:', key);
this.columnDefs.push(this.dat);


}



else{
  this.dat = {headerName: key, field: key, 
  

  hide: true,
  editable : true};
  console.log('      key2:', key);
  this.columnDefs.push(this.dat);

}
  }



this.trigger = true;
console.log(this.columnDefs);



this.loading = false;
  })
}
 abValueGetter(params) {
  return params.data.asignado + params.data.comments;
}
cellStyling(params: any){     

  return {'background-color': params.data.color};
}

cellStyling2(params: any){     
console.log(params)
return {'background-color': params.data.us_color};

}
cellStyling3(params: any){     
  console.log(params)
  return {'background-color': params.data.gest_color};
  
  }

  cellStyling4(params: any){     
    console.log(params)
    return {'background-color': params.data.gest_color};
    
    }

/* button1(params: any){ 
  console.log(params)
  if (params.data.status === '0'){
    return false
  }
  else{ return false}
} */



alert1(){

  this.router.navigate(['/tareas'])
}


ProjectTrackingTerminado(){
  this.pressed = true
  this.projects.ProjectTrackingTerminado().subscribe(res2 => {
    this.rowData = res2.projects;
    this.gridApi.setRowData(this.rowData);
  })
}

allProjectsAgain(){
  this.pressed = false
  this.projects.getAllProject()
  .subscribe(res2 => {
    this.rowData = res2.projects;
    this.gridApi.setRowData(this.rowData);

  })

}

  editCom(e){
    console.log(e)
    this.editrigger = true 
    this.newItem = e.id
    (document.getElementById(e.id) as any).disabled = false;
    
  }

saveCom(e){


(document.getElementById(e.id) as any).disabled  = true;
this.editrigger = false;
this.newItem = null
this.projects.update(e).subscribe(res => {
console.log(res)

});
}

saveData3(event){
console.log(event)


}


saveData2( event, item){
  console.log(event)
  // if (event.isUserInput && event.source.value !== event.source.viewValue) { 
 // console.log(fase)
  console.log(item)
  console.log(event)

this.projects.updateFase(event.value.id, item).subscribe(res => {
  console.log(res)

})

  
// }
}



compareFn(c1: any, c2: any): boolean {
 
  return c1 && c2 ? c1.fase_name === c2 : c1 === c2;
}
  dateFormatter(params) {

    // return params.value ? (new Date(params.value)).toLocaleDateString() : '';
    var timestamp = Date.parse(params.value);

    if (isNaN(timestamp) === false) {
      var d = new Date(timestamp).toLocaleDateString();
      console.log(d)
      return d

    }
    console.log("222222222")
    // return timestamp
  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }
  onRowClicked(event: any) { console.log('row', event);
  this.selectedRow = event.data.service_id
  this.projects.getFasesbyID(this.selectedRow)
  .subscribe(res => {
   // this.data6 = res.fases
    this.data6 = {};
    for (var i = 0; i < res.fases.length; i++) {
      this.data6 [res.fases[i].id] = res.fases[i].fase_name;
    }
    this.data6['0'] = '-'
    console.log(this.data6)
  })

/* this.butonrowclicked = true;
this.clientepresup = event.data.nombre;
this.proyectopresup = event.data.proyecto;
this.CIFpresup = event.data.CIF;
this.telefonopresup = event.data.telefono;
this.emailpresup = event.data.email;
this.commentpresup = event.data.comentarios;
this.fechapresup = event.data.fecha;
this.idtoupdate = event.data.id;
this.empresa = event.data.Empresa; */


}

onCellDoubleClicked(event1: any) { console.log('row', event1); 

}

countryCellRenderer(params: any){
  
console.log(params)
return params.value
}


onRowValueChanged(event: any){console.log('row', event.data);
this.datatomod = event.data;
console.log(this.datatomod)
if (this.datatomod){
  this.projects.modGestor(this.datatomod.gestor, this.datatomod.id)
  .subscribe(res => {
    this.projects.getAllProject()
    .subscribe(res2 => {
      this.rowData = res2.projects;
      this.gridApi.setRowData(this.rowData);
  
    })
  })

 

      }

}

}



































