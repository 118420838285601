import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { ProyectosComponent } from './proyectos.component';

import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule } from '@ngx-translate/core';
// import { MatButtonModule, MatCheckboxModule, MatIconModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { PatentesService } from '../../patentes.service';
import { MuComponent, DialogDataExampleDialogComponent } from './mu.component';
import { NgxLoadingModule } from 'ngx-loading';

import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';

import { MatButtonModule, MatDatepickerModule, MatFormFieldModule, MatCheckboxModule, MatIconModule, MatInputModule, MatSlideToggleModule, MatToolbarModule, MatSelectModule } from '@angular/material';
import { FuseConfirmDialogModule } from '@fuse/components';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
const routes = [
    {
        path     : 'patentes',
        component: MuComponent
    }
];
@NgModule({
  imports: [ 
    CommonModule,
   // RouterModule.forChild(routes),
    MatButtonModule,
    MatCheckboxModule,
    SweetAlert2Module,
    NgxLoadingModule,
    MatIconModule,
    MatInputModule,
    TranslateModule,
    AgGridModule.withComponents([]),
    FuseSharedModule,
    HttpClientModule, HttpModule,
    FuseConfirmDialogModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
MatToolbarModule,
MatFormFieldModule
  ],
  exports     : [
    
],
  declarations: [  DialogDataExampleDialogComponent ],
  providers: [PatentesService],
  entryComponents: [DialogDataExampleDialogComponent],
})
export class MuModule { }
