// Author: T4professor

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid';
import { ProjectTracking } from './project_tracking.service';
@Component({
    selector: 'fuse-app-button-renderer',
  template: `
    <button type="button" (click)="onClick($event)">{{params.data.asignado}}<div *ngIf="params.data.comments" style="display: inline-table;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    background-color: #ffb000;
    border-radius: 50%;
    margin-left: 5px;">
  <span style="display: table-cell;
  vertical-align: middle;
  text-align: center;">{{params.data.comments}}</span>
</div></button>
    `
})

export class ButtonRendererComponent implements ICellRendererAngularComp {
data3: any;
  params;
  label: string;

  agInit(params): void {

    this.params = params;
  

    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(params);

    }
  }
}
