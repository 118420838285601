import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatButtonModule, MatDividerModule, MatIconModule, MatTabsModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { ProfileService } from './profile.service';
import { FuseProfileComponent } from './profile.component';
import { FuseProfileTimelineComponent } from './tabs/timeline/timeline.component';
import { FuseProfileAboutComponent } from './tabs/about/about.component';
import { FuseProfilePagosComponent } from './tabs/pagos/pagos.component'
import { ColorPickerModule } from 'ngx-color-picker';
const routes = [
    {
        path     : 'profile',
        component: FuseProfileComponent,
        resolve  : {
            profile: ProfileService
        }
    }
];

@NgModule({
    declarations: [
        FuseProfileComponent,
        FuseProfileTimelineComponent,
        FuseProfileAboutComponent,
        FuseProfilePagosComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatTabsModule,
        FusePipesModule,
        FuseSharedModule,
        ColorPickerModule
    ],
    providers   : [
        ProfileService
    ]
})
export class ProfileModule
{
}
