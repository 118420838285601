import { Component, ViewChild, OnInit, RendererStyleFlags2, Inject, OnDestroy, AfterViewInit, Input } from '@angular/core';
import {VentasService} from '../../ventas.service';
import {PresupuestosService} from '../../../presupuestos/presupuestos.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseConfirmDialog2Component } from '@fuse/components/confirm-dialog2/confirm-dialog2.component';
import { FuseConfirmDialog3Component } from '@fuse/components/confirm-dialog3/confirm-dialog3.component';
import { FuseConfirmDialog4Component } from '@fuse/components/confirm-dialog4/confirm-dialog4.component';
import {CrearPresupuestoComponent} from '../../../crearpresupuestos/crearpresupuestos.component';


import {AnadirpagocomponentComponent} from '../../../addpago/anadirpagocomponent/anadirpagocomponent.component'
import {Anadirpagocomponent3Component} from '../../../anadirpagocomponent3/anadirpagocomponent3.component'
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatSelect, VERSION } from '@angular/material';
import {LoginService} from 'app/main/content/login/login.service';

import * as moment from 'moment';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
declare var jquery: any; declare var $: any;
interface Bank {
  id: string;
  name: string;
}
@Component({
  selector: 'fuse-tab-continuacion',
  templateUrl: './continuacion.component.html',
  styleUrls  : ['./continuacion.component.scss'],
})
export class ContinuacionComponent implements OnInit  {
  public loading = false;
  public gridApi;
  public gridOptions;
  private gridColumnApi;
 // private rowData: any[];
  trigger: any; 
  trigger2: any; 

 // private columnDefs;
  // private rowData;
  private rowSelection;
  checked = true;
  nombre = true;
  apellido = true;
  apellido2 = true;
  idtoupdate: any;
  id_anterior: any;
  butonrowclicked = false;
  addbutton = false;
  dat: any;
  calendar: any;
  sortState: any;
  clientepresup: any;
  proyectopresup: any;
  uid: any;
CIFpresup: any;
telefonopresup: any;
emailpresup: any;
commentpresup: any;
fechapresup: any;
curfecha: any;
clienteexsitente: any;
  armtest: any;
  datatosend: any;
  datatomod: any;
  columnDefs: any;
  id_anterior_venta: any;
  rowData: any;
  editType: any;
  sidebar: any;
  empresa: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  confirmDialogRef2: MatDialogRef<FuseConfirmDialog2Component>;
  confirmDialogRef3: MatDialogRef<FuseConfirmDialog3Component>;
  confirmDialogRef4: MatDialogRef<FuseConfirmDialog4Component>;



  private components;


/** control for the selected bank */














  
  constructor(private clientes: VentasService, private presupuestos: PresupuestosService, private router: Router,
    private loginService1: LoginService,   public dialog: MatDialog) {
 
    this.editType = 'fullRow';
    this.rowSelection = 'single';
    this.sidebar = 'exav';
    this.calendar = false;

  }
  ngOnInit() {
    this.calendar = false;
    this.trigger2 = false;
    // set initial selection
    if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') ||  localStorage.getItem("id_token") === null) {
      console.log(localStorage)
      this.router.navigate(['/login']);
    } else {
      this.loginService1.checkToken().subscribe(rescat => {
        console.log(rescat);
        if (rescat.response){
          if (rescat.response === 1){


            
            this.getClientes2();
        }
        else{

          this.router.navigate(['/login']);
        }
      }
        else{
          this.router.navigate(['/login']);
        }

      })
        }

    this.trigger = false;
  // this.getClientes2();

  }





/** list of banks filtered by search keyword */













openDialog(rowindex): void {
  this.curfecha = new Date()
  console.log(this.curfecha)
  const dialogRef = this.dialog.open(CrearPresupuestoComponent, {
    width: '800px',
    height: '800px',
    data: {
      cliente: this.clientepresup,
      proyecto: this.proyectopresup,
     
      fecha: this.curfecha,
      email: this.emailpresup,
      descrip: this.commentpresup,
      tel: this.telefonopresup,
      uid: this.idtoupdate,
      id_anterior: this.id_anterior
    }
    
  });

  dialogRef.afterClosed().subscribe(result => {
    var newItem =  {
      cliente: result.client,
      proyecto: result.proyecto,
      servicio: result.servicio,
      fecha: result.fecha,
      numero: result.numero,
      importe: result.importe_total
      /* importe1: result.importe1,
      importe2: result.importe2,
      importe3: result.importe3,
      importe4: result.importe4,
      importe5: result.importe5,
      importe6: result.importe6,
      importe7: result.importe7,
      importe8: result.importe8,
      concepto1: result.concepto1,
      concepto2: result.concepto2,
      concepto3: result.concepto3,
      concepto4: result.concepto4,
      concepto5: result.concepto5,
      concepto6: result.concepto6,
      concepto7: result.concepto7,
      concepto8: result.concepto8,
       */
    };
  
   // var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0 });
    window.open('http://lfdi.bitronedev.com/public/' + result.numero + '.pdf', "_blank");
    
  });
}




anadirpago(rowindex): void {

if (this.id_anterior && this.id_anterior_venta){

this.anadirpago2()
}
else{
  this.anadirpago1()
}

}





anadirpago1() {
  this.curfecha = new Date()
  console.log(this.curfecha)
  const dialogRef1 = this.dialog.open(AnadirpagocomponentComponent, {
    width: '800px',
    height: '800px',
    data: {
      cliente: this.clientepresup,
      proyecto: this.proyectopresup,
      fecha: this.curfecha,
      tel: this.telefonopresup,
      email: this.emailpresup,
      uid: this.idtoupdate,
      id_anterior: this.id_anterior,
      id_anterior_venta: this.id_anterior_venta,
      empresa: this.empresa,
    }
    
  });

  dialogRef1.afterClosed().subscribe(result => {

    console.log(result)
  // window.location.reload()
  this.clientes.getVentas2()
  .subscribe(ress => {
    this.rowData = ress.clientes;
    this.gridApi.setRowData(this.rowData);
  })
    
  });


}


anadirpago2() {
  this.curfecha = new Date()
  console.log(this.curfecha)
  const dialogRef2 = this.dialog.open(Anadirpagocomponent3Component, {
    width: '800px',
    height: '800px',
    data: {
      cliente: this.clientepresup,
      proyecto: this.proyectopresup,
      fecha: this.curfecha,
      tel: this.telefonopresup,
      email: this.emailpresup,
      uid: this.idtoupdate,
      id_anterior: this.id_anterior,
      id_anterior_venta: this.id_anterior_venta,

    }
    
  });

  dialogRef2.afterClosed().subscribe(result => {

    console.log(result)
  // window.location.reload()
  this.clientes.getVentas2()
  .subscribe(ress => {
    this.rowData = ress.clientes;
    this.gridApi.setRowData(this.rowData);
  })
    
  });


}



openDialog2(rowindex): void {
  var selectedData = this.gridApi.getSelectedRows();
  const dialogRef = this.dialog.open(Dialog2DataExampleDialogComponent, {
    width: '800px',
    height: '800px',
    data: {
      cliente: this.clientepresup,
      proyecto: this.proyectopresup,
      CIF: this.CIFpresup,
      telefono: this.telefonopresup,
      email: this.emailpresup,
      descrip: this.commentpresup,
      fecha: this.fechapresup,
      idtoupdate: this.idtoupdate,
      id_anterior: this.id_anterior
    }
    
  });

  dialogRef.afterClosed().subscribe(result => {
    if ( result )
    {
     // var selectedData = this.gridApi.getSelectedRows();

      
      var res = this.gridApi.updateRowData({ remove: selectedData });
      console.log(res.remove[0].data.id);
/*       this.clientes.remVentas(res.remove[0].data.id)
      .subscribe(res2 => {
  console.log(res2);
      }); */
     
    }

  
   // var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0 });
   
    
  });
}





openDialog1(rowindex): void {
  const dialogRef = this.dialog.open(CrearPresupuestoComponent, {
    width: '450px',
    height: '450px',
    
  });

  dialogRef.afterClosed().subscribe(result => {
    var rowNode = this.gridApi.getDisplayedRowAtIndex(rowindex);
    rowNode.setDataValue('apellido',  result.age);
   // this.armtest = result.age;
   var dataformod = this.gridApi.getRowNode(rowindex);
   this.clientes.modVentas3(dataformod.data)
   .subscribe(res => {
       
     console.log(res);
         });
   console.log(this.armtest);
  });
}
  getClientes2(){

    let data2 = {0: '-', 1: 'Si', 2: 'No'}

    function extractValues(mappings) {
      return Object.keys(mappings);
    }
    function lookupValue(mappings, key) {
      return mappings[key];
    }
    function lookupKey(mappings, name) {
      for (var key in mappings) {
        if (mappings.hasOwnProperty(key)) {
          if (name === mappings[key]) {
            return key;
          }
        }
      }
    }
    this.columnDefs = [];

    this.components = { datePicker: getDatePicker() };
    function getDatePicker() {
      function Datepicker() {}
      Datepicker.prototype.init = function(params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        $(this.eInput).datepicker({ dateFormat: "dd/mm/yy" });
      };
      Datepicker.prototype.getGui = function() {
        return this.eInput;
      };
      Datepicker.prototype.afterGuiAttached = function() {
        this.eInput.focus();
        this.eInput.select();
      };
      Datepicker.prototype.getValue = function() {
        return this.eInput.value;
      };
      Datepicker.prototype.destroy = function() {};
      Datepicker.prototype.isPopup = function() {
        return false;
      };
      return Datepicker;
    }






    this.loading = true;
    this.clientes.getVentas2()
    .subscribe(res => {
      this.rowData = res.clientes;
      console.log(res);

        for (let key in res.clientes[0]) {
          if (key === 'id') {
            this.dat = {headerName: key, field: key, hide: true,  filter: 'agTextColumnFilter', rowDrag: true, width: 90,   type: 'numericColumn'  };
            console.log('      key:', key);
            this.columnDefs.push(this.dat);
            // console.log(this.columnDefs);

        }     
        else if (key === 'timestamp') {
          this.dat = {headerName: key, field: key, hide: true  };
          console.log('      key:', key);
          this.columnDefs.push(this.dat);
          // console.log(this.columnDefs);

      }   
           
/*       else  if (key === 'fecha2') {
        this.dat = {headerName: 'Fecha', hide: true, field: key, pinned: 'left',   comparator: this.dateComparator, width: 100, editable: true,    cellEditor: "datePicker", 
         };
        console.log('      key:', key);
        this.columnDefs.push(this.dat);
        // console.log(this.columnDefs);

    } */
    else  if (key === 'fecha') {
      this.dat = {headerName: 'Fecha', field: key,  width: 84,   valueFormatter: this.dateFormatter,  cellEditor: "datePicker", cellRenderer: 'singleClickEditRenderer', editable: true

  
}; /*  editable: this.checkEditable.bind(this)  */

      console.log('      key:', key);
      this.columnDefs.push(this.dat);
      // console.log(this.columnDefs);

  }


  else  if (key === 'id_anterior_venta') {
    this.dat = {headerName: 'id_anterior_ventaha', field: key,  hide: true};

    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    // console.log(this.columnDefs);

}
else  if (key === 'Empresa') {
  this.dat = {headerName: key, field: key,  editable: true,  cellEditor: "agSelectCellEditor", width:91, 
  cellEditorParams: {values: extractValues(data2) },
  valueFormatter: function(params) {
    return lookupValue(data2, params.value);
  },
  valueParser: function(params) {
    return lookupKey(data2, params.newValue);
  }
};
console.log('      key:', key);
this.columnDefs.push(this.dat);
}

  else  if (key === 'id_anterior') {
    this.dat = {headerName: 'Fecha', field: key, hide: true,  width: 100, editable: true,  valueFormatter: this.dateFormatter,  cellEditor: "datePicker"}
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
  }
    else  if (key === 'comentarios') {
      this.dat = {headerName: 'Comentarios', field: key,  filter: 'agTextColumnFilter', 
      filterParams: {
        filterOptions: ['contains', 'notContains'],
        textFormatter: function(r) {
          if (r == null){ return null}
          var r = r.toLowerCase();
          // r = r.replace(new RegExp("\\s", 'g'),"");
          r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
          r = r.replace(new RegExp('æ', 'g'), 'ae');
          r = r.replace(new RegExp('ç', 'g'), 'c');
          r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
          r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
          r = r.replace(new RegExp('ñ', 'g'), 'n');
          r = r.replace(new RegExp('[òóôõøö]', 'g'), 'o');
          r = r.replace(new RegExp('œ', 'g'), 'oe');
          r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
          r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
          return r;
        },
        debounceMs: 0,
        caseSensitive: false,
       // suppressAndOrCondition: true,
      },
      
      editable: true};
      console.log('      key:', key);
      this.columnDefs.push(this.dat);
      // console.log(this.columnDefs);

  }

  else  if (key === 'accion') {
    this.dat = {headerName: 'Fases de Venta', field: key,  filter: 'agTextColumnFilter', width: 360,
    filterParams: {
      filterOptions: ['contains', 'notContains'],
      textFormatter: function(r) {
        if (r == null){ return null}
        var r = r.toLowerCase();
        // r = r.replace(new RegExp("\\s", 'g'),"");
        r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
        r = r.replace(new RegExp('æ', 'g'), 'ae');
        r = r.replace(new RegExp('ç', 'g'), 'c');
        r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
        r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
        r = r.replace(new RegExp('ñ', 'g'), 'n');
        r = r.replace(new RegExp('[òóôõøö]', 'g'), 'o');
        r = r.replace(new RegExp('œ', 'g'), 'oe');
        r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
        r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
        return r;
      },
      debounceMs: 0,
      caseSensitive: false,
     // suppressAndOrCondition: true,
    },
    
    editable: true};
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    // console.log(this.columnDefs);

}
  else  if (key === 'nombre') {
    this.dat = {headerName: 'Nombre y Apellidos', field: key,  filter: 'agTextColumnFilter',   editable: true, width: 156,
    filterParams: {
      filterOptions: ['contains', 'notContains'],
      textFormatter: function(r) {
        if (r == null){ return null}
        var r = r.toLowerCase();
        // r = r.replace(new RegExp("\\s", 'g'),"");
        r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
        r = r.replace(new RegExp('æ', 'g'), 'ae');
        r = r.replace(new RegExp('ç', 'g'), 'c');
        r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
        r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
        r = r.replace(new RegExp('ñ', 'g'), 'n');
        r = r.replace(new RegExp('[òóôõøö]', 'g'), 'o');
        r = r.replace(new RegExp('œ', 'g'), 'oe');
        r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
        r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
        return r;
      },
      debounceMs: 0,
      caseSensitive: false,
     // suppressAndOrCondition: true,
    },
  
  
  
  };
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    // console.log(this.columnDefs);

}
  else  if (key === 'telefono') {
    this.dat = {headerName: 'teléfono', field: key,  filter: 'agTextColumnFilter',  width: 84, editable: true, };
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    // console.log(this.columnDefs);

}
else  if (key === 'email') {
  this.dat = {headerName: 'E-mail', field: key,  filter: 'agTextColumnFilter',  editable: true, width: 151 };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}
else  if (key === 'estado') {
  this.dat = {headerName: 'estado', field: key,  filter: 'agTextColumnFilter',  hide: true };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}
        else{
          this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', 
          
          filterParams: {
            filterOptions: ['contains', 'notContains'],
            textFormatter: function(r) {
              if (r == null){ return null}
              var r = r.toLowerCase();
              // r = r.replace(new RegExp("\\s", 'g'),"");
              r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
              r = r.replace(new RegExp('æ', 'g'), 'ae');
              r = r.replace(new RegExp('ç', 'g'), 'c');
              r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
              r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
              r = r.replace(new RegExp('ñ', 'g'), 'n');
              r = r.replace(new RegExp('[òóôõøö]', 'g'), 'o');
              r = r.replace(new RegExp('œ', 'g'), 'oe');
              r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
              r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
              return r;
            },
            debounceMs: 0,
            caseSensitive: false,
           // suppressAndOrCondition: true,
          },
          
          editable : true };
          console.log('      key2:', key);
          this.columnDefs.push(this.dat);

        }
          }
          this.trigger = true;
          console.log(this.columnDefs);
         
    

          this.loading = false;

      });
  }
/*  
  setPriceOnToyota() {   
    var rowNode = this.gridApi.getRowNode("aa");
    var newPrice = Math.floor(Math.random() * 100000);
    rowNode.setDataValue("price", newPrice);
  } */
  dateFormatter(params) {
  console.log(params)
   // return params.value ? (new Date(params.value)).toLocaleDateString() : '';
   var timestamp = Date.parse(params.value);

if (isNaN(timestamp) === false) {
  var d = new Date(timestamp).toLocaleDateString();
  console.log(d)
  return d
 
}
console.log("222222222")
// return timestamp
  }

  sortwaschanged(event: any){console.log('sort', event);

  /* this.sortState = this.gridApi.getSortModel();
   console.log(this.sortState[0].sort) */
  }

  sortByAthleteAsc() {
    
    var sort = [
        {colId: 'fecha', sort: 'asc'}
    ];
    this.gridApi.setSortModel(sort);
}

onFocusOut(){
  this.calendar = false;
}

checkEditable(params){
  console.log(params)
  console.log(this.calendar)
 
  if (this.calendar){
      return true;
  }
}
onCellDoubleClicked(event){
  console.log(event);
  if (event.value.indexOf('-') === 4)
  {
      this.gridApi.stopEditing();
      console.log(true);
      this.calendar = true;
      this.gridApi.startEditingCell({
          rowIndex: event.rowIndex,
          colKey: 'fecha',
      })
  }
 
 

}
  saveRow(){  
  this.trigger2 = true; 

   console.log(this.datatosend.fecha)
   this.gridApi.stopEditing();
    console.log(this.datatosend)
    if (this.datatosend.nombre){
    if (this.datatosend.nombre !== "" && this.datatosend.telefono !== "" && this.datatosend.email !== "" &&  this.datatosend.fecha !== "undefined"){
    this.clientes.addVentas(this.datatosend)
    .subscribe(res => {
      console.log(res)
      if (res.response.status === "0"){
      this.clienteexsitente = res.response.id[0]
         console.log(this.clienteexsitente)
        this.addbutton = false;
        if (res.response.id.length > 0){
          
          this.confirmDialogRef2 = this.dialog.open(FuseConfirmDialog2Component, {
              disableClose: false 
          });
    
          this.confirmDialogRef2.componentInstance.confirmMessage = 'Ya existe el cliente con el mimso email o telefono.' + '  Nombre: ' + res.response.id[0].nombre + ', telefono: ' + res.response.id[0].telefono + ', email: ' + res.response.id[0].email + '';
    
          this.confirmDialogRef2.afterClosed().subscribe(result => {
            console.log(result)
              if ( result )
              {


                this.clientes.addVentasExistente(this.datatosend, 'NULL')
                .subscribe(res2 => {
                  console.log(res2)
                 // window.location.reload() arman
                 this.clientes.getVentas2()
    .subscribe(ress => {
      this.rowData = ress.clientes;
      this.gridApi.setRowData(this.rowData);
    })

                })
               
              }
              else {
              /*   this.clientes.addVentasExistente(this.datatosend, res.response[0].id)
                .subscribe(res2 => {
                  console.log(res2)
                 window.location.reload()
                }) */

                this.chooseClient()

              }
    
              this.confirmDialogRef2 = null;
              this.addbutton = false;
          });
        }
        else{
         
         // window.location.reload()
        }
      }
      else{
        this.addbutton = false;
       // window.location.reload() arman
       this.clientes.getVentas2()
       .subscribe(ress => {
         this.rowData = ress.clientes;
         this.gridApi.setRowData(this.rowData);
         this.trigger2 = false
       })
      }
      console.log("stexem")
      
     // this.cancelrow2();
console.log(res);



// window.location.reload() 
/* var params = {
  force: true
}; 
 this.gridApi.refreshCells(params); */
 // this.router.navigate(['/ventas'])
// this.sortByAthleteAsc()
    });
  }
  else { 
this.onBtStartEditing();
   // this.addbutton = false;
/*     this.gridApi.forEachNode(function(node) {
      if (node.rowIndex === 0) {
        node.setSelected(true);
        var selectedData = node.data;
        console.log(selectedData);
        
  
      }
    }); */
    
    alert ("Debes rellenar los campos de fecha, nombre, telefono y email");

  }
}
else{
  alert ("Debes rellenar los campos de fecha, nombre, telefono y email");
}
}


  getRowData() {
    var rowData = [];
    this.gridApi.forEachNode(function(node) {
      rowData.push(node.data);
    });
    console.log('Row Data:');
    console.log(rowData);
  }

  clearData() {
    this.gridApi.setRowData([]);
  }




checkfirst(){
  this.confirmDialogRef3 = this.dialog.open(FuseConfirmDialog3Component, {
    disableClose: false,

});

this.confirmDialogRef3.componentInstance.confirmMessage = '¿Es un cliente nuevo?';

this.confirmDialogRef3.afterClosed().subscribe(result => {
  console.log(result)
    if ( result )
    {
      this.onAddRow()

/*       this.clientes.addVentasExistente(this.datatosend, 'NULL')
      .subscribe(res2 => {
        console.log(res2)
       window.location.reload()
      })
      */
    }
    else { 
      this.chooseClient()
/*       console.log(result)
      this.onAddRow2()
      this.clientes.addVentasExistente(this.datatosend, res2.response[0].id)
      .subscribe(res2 => {
        console.log(res2)
        window.location.reload()
      }) */

    }

   /*  this.confirmDialogRef3 = null;
    this.addbutton = false; */
});

}

chooseClient(){
  console.log(this.datatosend)
  this.confirmDialogRef4 = this.dialog.open(FuseConfirmDialog4Component, {
    disableClose: false,
    width: '450px',
    height: '450px',
    data: {
      cliente: this.clienteexsitente,
      datosnuevos: this.datatosend
    }
});


/* 
https://lafabricadeinventos.com/wp-admin/options-general.php?page=wp-mail-smtp&tab=auth222191504717-c4vbktpk4190484c94t1bvdm4thebpda.apps.googleusercontent.com
TlbuCZWuLRwFilNPG9Ob08jw
*/

/* this.confirmDialogRef4.componentInstance.confirmMessage = '¿Es un cliente nuevo?'; */

this.confirmDialogRef4.afterClosed().subscribe(result => {
  console.log(result)
    if ( result )
    {
    //  window.location.reload() arman
    this.clientes.getVentas2()
    .subscribe(ress => {
      this.rowData = ress.clientes;
      this.gridApi.setRowData(this.rowData);
    })
     
/* 
      this.clientes.addVentasExistente(this.datatosend, 'NULL')
      .subscribe(res2 => {
        console.log(res2)
        window.location.reload()
      }) */
     
    }
    else {
      console.log(result)
/*       this.clientes.addVentasExistente(this.datatosend, res.response[0].id)
      .subscribe(res2 => {
        console.log(res2)
        window.location.reload()
      }) */

    }

   /*  this.confirmDialogRef3 = null;
    this.addbutton = false; */
});

}


onAddRow2() {
  var params = {
    force: true
  }; 
   // this.gridApi.refreshCells(params);
  // this.gridApi.ensureColumnVisible('fecha')
  // this.gridApi.setSortModel(null);
  this.gridApi.setFilterModel(null)
  this.gridApi.setSortModel(null);
   this.gridApi.ensureIndexVisible(0);
   this.gridApi.ensureNodeVisible(0)
   this.gridApi.setFocusedCell(0, 'nombre', null);
   this.addbutton = true;
   var newItem = this.createNewRowData2();
   var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0});
   this.datatosend = res.add[0].data;
}

  onAddRow() {
    var params = {
      force: true
    }; 
     // this.gridApi.refreshCells(params);
    // this.gridApi.ensureColumnVisible('fecha')
    // this.gridApi.setSortModel(null);
    this.gridApi.setFilterModel(null)
    this.gridApi.setSortModel(null);
     this.gridApi.ensureIndexVisible(0);
     this.gridApi.ensureNodeVisible(0)
     
     
/* if (this.sortState && (this.sortState[0].sort === "asc" || this.sortState[0].sort === "desc")){
alert("no se puede")

}
else{ */
 
    // this.sortByAthleteAsc();
    this.gridApi.setFocusedCell(0, 'nombre', null);
    this.addbutton = true;
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0});
    this.datatosend = res.add[0].data;

     
    
    console.log( this.datatosend);
//}
  }
  modclient(){
    console.log(this.datatomod);
    

  }

   createNewRowData2() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    var yyyy = today.getFullYear();
    
    let fecha = mm + '/' + dd + '/' + yyyy;
    var newData = {
       fecha: fecha,
      comentarios: 'commmments',
      nombre: 'armancho',
      telefono: '652552703',
      email: 'armang206+++56@gmail.com',
      proyecto: 'proyecto', 
      estado: '0',
      id_anterior: '25'
    };
    newCount++;
    return newData;
  }



  addItems() {
    var newItems = [createNewRowData(), createNewRowData(), createNewRowData()];
    var res = this.gridApi.updateRowData({ add: newItems });
    printResult(res);
  }

  addItemsAtIndex() {
    var newItems = [createNewRowData(), createNewRowData(), createNewRowData()];
    var res = this.gridApi.updateRowData({
      add: newItems,
      addIndex: 2
    });
    printResult(res);
  }

  updateItems() {
    var itemsToUpdate = [];
    this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
      if (index >= 5) {
        return;
      }
      var data = rowNode.data;
      data.price = Math.floor(Math.random() * 20000 + 20000);
      itemsToUpdate.push(data);
    });
    var res = this.gridApi.updateRowData({ update: itemsToUpdate });
    printResult(res);
  }

  onInsertRowAt2() {
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({
      add: [newItem],
      addIndex: 0
    });
    printResult(res);
  }
  onRemoveSelected()
  {
    var selectedData = this.gridApi.getSelectedRows();
    if (selectedData.length !== 0){
      console.log(selectedData);
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = '¿Eliminar ' + selectedData[0].nombre + '?';

      this.confirmDialogRef.afterClosed().subscribe(result => {
        console.log(result)
          if ( result )
          {
            console.log(result)
           // var selectedData = this.gridApi.getSelectedRows();

            
            var res = this.gridApi.updateRowData({ remove: selectedData });
            console.log(res.remove[0].data.id);
            this.clientes.remVentas(res.remove[0].data.id)
            .subscribe(res2 => {
        console.log(res2);
            });
            printResult(res);
          }

          this.confirmDialogRef = null;
          this.addbutton = false;
      });
    }
  }
/*   onCellDoubleClicked(event1: any) { console.log('row', event1); 

if (event1.colDef.field === 'fecha'){

  var armindex = this.gridApi.getFocusedCell();
  console.log(armindex);  
  // this.gridApi.stopEditing();
 // this.openDialog(armindex.rowIndex);

}

} */

onBtStartEditing() { 
  this.gridApi.setFocusedCell(0, 'fecha', 0)
 

  this.gridApi.startEditingCell({
      rowIndex: 0,
      colKey: 'fecha',
      // set to 'top', 'bottom' or undefined
     
  });
}

testfunc(){

 var armpordz = this.gridApi.getRowNode(0);
 console.log(armpordz);
}


  onRowClicked(event: any) { console.log('row', event);
  
this.butonrowclicked = true;
this.clientepresup = event.data.nombre;
this.proyectopresup = event.data.proyecto;
this.CIFpresup = event.data.CIF;
this.telefonopresup = event.data.telefono;
this.emailpresup = event.data.email;
this.commentpresup = event.data.comentarios;
this.fechapresup = event.data.fecha;
this.idtoupdate = event.data.id;
this.empresa = event.data.Empresa;
this.id_anterior = event.data.id_anterior;
this.id_anterior_venta = event.data.id_anterior_venta;



}


onRowValueChanged(event: any){console.log('row', event.data);
this.datatomod = event.data;
if (this.datatomod && !this.addbutton){
  if (this.datatomod.nombre !== "" && this.datatomod.telefono !== "" && this.datatomod.email !== ""){
    
    
  this.clientes.modVentas3(this.datatomod)
  .subscribe(res => {
      
    console.log(res);
        });
      }
      else {
       // this.addbutton = false;
        this.gridApi.forEachNode(function(node) {
          if (node.rowIndex === 0) {
            node.setSelected(true);
            var selectedData = node.data;
            console.log(selectedData);
           
        // dataforcancel(selectedData);
          }
        });
     //   this.cancelrow2();
        alert ("Debes rellenar los campos de nombre, telefono y email");

      }
// this.modclient();
}
else if (this.addbutton && !this.trigger2){
this.saveRow()

}

}
  
  showHide(e) {
    // var checked = e.target.checked;
    console.log(e.source.id);
   this.gridColumnApi.setColumnVisible(e.source.id, e.checked);
  }


  cancelrow(){
    this.addbutton = false;
    this.gridApi.forEachNode(function(node) {
      if (node.rowIndex === 0) {
        node.setSelected(true);
        var selectedData = node.data;
        console.log(selectedData);
       
    // dataforcancel(selectedData);
      }
    });
    this.cancelrow2();

    }  
    
    
    cancelrow2()
    {
      var selectedData = this.gridApi.getSelectedRows();
      var res = this.gridApi.updateRowData({ remove: selectedData });
      this.addbutton = false;
    }



  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
 
 /*    this.gridColumnApi.applyColumnState({
      state: [
          { colId: 'fecha' },
          { colId: 'comentarios' },
          { colId: 'accion' },
          { colId: 'nombre' },
          { colId: 'telefono' },
          { colId: 'email' },
          { colId: 'proyecto' },
          { colId: 'Empresa' },
          { colId: 'proyecto' }




      ],
      applyOrder: true
  }); */
  }
}
function dataforcancel(selectedData){
  // var res = this.gridApi.updateRowData({ remove: selectedData });
  printResult(selectedData);
  //   printResult(res2);

   }
var newCount = 1;


function createNewRowData() {
  var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) {
      month = '0' + month;
  }
  if (day.length < 2) {
      day = '0' + day;
  }
  var amis = [year, month, day].join('-')
 console.log(amis)
  var newData = {
    fecha:  amis
    /*  nombre: '',
    apellido: '',
    CIF: '',
    telefono: '',
    email: '',
    direccion: ''  */
  };
  newCount++;
  return newData;
}
function printResult(res) {
  console.log('---------------------------------------');
  if (res.add) {
    res.add.forEach(function(rowNode) {
      console.log('Added Row Node', rowNode);
    });
  }
  if (res.remove) {
    res.remove.forEach(function(rowNode) {

      console.log('Removed Row Node', rowNode.data.id);
    });
  }
  if (res.update) {
    res.update.forEach(function(rowNode) {
      console.log('Updated Row Node', rowNode);
    });
  }
}



@Component({
  selector: 'fuse-dialog-data-example-dialog',
  templateUrl: 'dialog-data-example-dialog.html',
})

export class DialogDataExampleDialogComponent implements OnInit, OnDestroy, AfterViewInit {  
  armtest: any;
  client: any;
  fecha: any;
  remitentes: any;
  selectedValue: any;
  selectedValue2: any;
  username: any;
  proyectos: any;
  servicios: any;
  Clientes2: any ;
  uid: any;
  numero_presup: any;
  choosedservice: any;
  servicename: any;
  fechafalta: any;
  triger: any;
  tel: any;
  email: any;
  servicedata: any;
  importe_total: any;
  importe1: any;
  importe2: any;
  importe3: any;
  importe4: any;
  importe5: any;
  importe6: any;
  importe7: any;
  importe8: any;
concepto1: any;
concepto2: any;
concepto3: any;
concepto4: any;
concepto5: any;
concepto6: any;
concepto7: any;
concepto8: any;
selection: any;
id_anterior: any;
  // @Input() sideBar: VentasComponent; 
  
  public bankCtrl: FormControl = new FormControl();
  private clientes: any; 
    
    /** control for the MatSelect filter keyword */
  public bankFilterCtrl: FormControl = new FormControl();

  /** list of clientes */

  
  
  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  
  
  
  @ViewChild('singleSelect') singleSelect: MatSelect;
  
  
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  

  ngOnInit() {
    this.remitentes = [
      {id:0, nombre:'LFDI', value:1},
      {id:1, nombre:'TIE', value:2},
      {id:2, nombre:'Begoña', value:3},
      {id:3, nombre:'YLENIA', value:4},
      {id:4, nombre:'Carlos', value:5},
      {id:5, nombre:'Sara', value:6},
      {id:6, nombre:'Maria', value:7},
      {id:7, nombre:'David', value:8},
      {id:8, nombre:'Sergio', value:9}

    ]
    this.importe1 = 0
    this.importe2 = 0
    this.importe3 = 0
    this.importe4 = 0
    this.importe5 = 0
    this.importe6 = 0;
    this.importe7 = 0;
    this.importe8 = 0;
    this.triger = 0;
    this.selection = "1";
    this.presupuestos.getlastid().subscribe(res2 => {
    console.log(res2.presupuestos2[0].numero)
    let presunum = res2.presupuestos2[0].numero
    this.numero_presup = parseInt(presunum, 10)           
    this.numero_presup = this.numero_presup + 1
    console.log(this.numero_presup)
    })
    this.presupuestos.getServicios()
    .subscribe(res => {
this.servicios = res.servicios;
console.log(this.servicios)
/* this.servicios.push({id: 3, nombre: "Elegir servicio", plazo:0, precio:0}); */
this.servicios.splice(0, 0, {id: 3, nombre: "Elegir servicio", plazo:0, precio:0})
console.log(this.servicios)
this.bankCtrl.setValue(this.servicios[0]);
    


    this.filteredBanks.next(this.servicios.slice());
    this.bankFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterBanks();
    });
    })
  

    // listen for search field value changes

    }

    getlistCliente(){


    };
    ngAfterViewInit() {
      this.setInitialValue();
    }
    
    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
    
    private setInitialValue() {
      this.filteredBanks
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          // setting the compareWith property to a comparison function
          // triggers initializing the selection according to the initial value of
          // the form control (i.e. _initializeSelection())
          // this needs to be done after the filteredBanks are loaded initially
          // and after the mat-option elements are available
          this.singleSelect.compareWith = (a: Bank, b: Bank) => a.id === b.id;
         
        });
    }
    private filterBanks() {
      if (!this.servicios) {
        return;
      }
      // get the search keyword
      let search = this.bankFilterCtrl.value;
      if (!search) {
        this.filteredBanks.next(this.servicios.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the clientes
      this.filteredBanks.next(
        this.servicios.filter(servicios => servicios.nombre.toLowerCase().indexOf(search) > -1)
      );
    }


  /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
    constructor(public dialogRef: MatDialogRef<DialogDataExampleDialogComponent>, private presupuestos: PresupuestosService, @Inject(MAT_DIALOG_DATA) public data: any) {
      this.client = data.cliente
      this.selectedValue = data.proyecto
      this.fecha = data.fecha
      this.tel = data.tel
      this.email = data.email
      this.uid = data.uid;
      this.id_anterior = data.id_anterior
      this.selectedValue2 = "remitente"
      console.log(data)
      

    }
    
    saveData(bankCtrl){

  this.triger = 0;
      console.log(bankCtrl.value);
      this.servicename = bankCtrl.value.nombre;
      this.choosedservice = bankCtrl.value.id;
      console.log(this.choosedservice)
        this.servicedata = bankCtrl.value;
        if (this.choosedservice >0){
          this.concepto1 = "";
          this.concepto2 = "";
          this.concepto3 = "";
          this.concepto4 = "";
          this.concepto5 = "";
          this.concepto6 = "";
          this.concepto7 = "";
          this.concepto8 = "";
          this.importe1 = 0;
          this.importe2 = 0;
          this.importe3 = 0;
          this.importe4 = 0;
          this.importe5 = 0;
          this.importe6 = 0;
          this.importe7 = 0;
          this.importe8 = 0;
        }
     if (this.choosedservice == 28){
      this.concepto1 = "";
      this.concepto2 = "";
      this.concepto3 = "";
      this.concepto4 = "";
      this.concepto5 = "";
      this.concepto6 = "";
      this.concepto7 = "";
      this.concepto8 = "";
      this.importe1 = 0;
      this.importe2 = 0;
      this.importe3 = 0;
      this.importe4 = 0;
      this.importe5 = 0;
      this.importe6 = 0;
      this.importe7 = 0;
      this.importe8 = 0;

this.concepto1 = "REGISTRO";
this.concepto2 = "TASAS";

/* this.concepto2 = "TASA DE TRANSMISIÓN";
this.concepto3 = "TASA DE BÚSQUEDA";
this.concepto4 = "TASA DE PRESENTACIÓN INTERNACIONAL"; */
/// this.concepto5 = "REDUCCIÓN EN TASAS POR PRESENTACIÓN ELECTRÓNICA";
this.importe1 = 1399;
 this.importe2 = 3096;
/*this.importe3 = 1775;
this.importe4 = 1034; */
/// this.importe5 = -176;
     }
     if (this.choosedservice == 27){
      this.concepto1 = "";
      this.concepto2 = "";
      this.concepto3 = "";
      this.concepto4 = "";
      this.concepto5 = "";
      this.concepto6 = "";
      this.concepto7 = "";
      this.concepto8 = "";
      this.importe1 = 0;
      this.importe2 = 0;
      this.importe3 = 0;
      this.importe4 = 0;
      this.importe5 = 0;
      this.importe6 = 0;
      this.importe7 = 0;
      this.importe8 = 0;
      this.concepto1 = "Realización de Mockup ";
      this.importe1 = 1175;
           }
           if (this.choosedservice == 26){
            this.concepto1 = "";
            this.concepto2 = "";
            this.concepto3 = "";
            this.concepto4 = "";
            this.concepto5 = "";
            this.concepto6 = "";
            this.concepto7 = "";
            this.concepto8 = "";
            this.importe1 = 0;
            this.importe2 = 0;
            this.importe3 = 0;
            this.importe4 = 0;
            this.importe5 = 0;
            this.importe6 = 0;
            this.importe7 = 0;
            this.importe8 = 0;
            this.concepto1 = "Honorarios de registro de marca nacional ";
            this.concepto2 = "Tasas de registro de marca nacional ";
            this.importe1 = 390;
this.importe2 = 850;
                 }
                 if (this.choosedservice == 1){
                  this.concepto1 = "";
                  this.concepto2 = "";
                  this.concepto3 = "";
                  this.concepto4 = "";
                  this.concepto5 = "";
                  this.concepto6 = "";
                  this.concepto7 = "";
                  this.concepto8 = "";
                  this.importe1 = 0;
                  this.importe2 = 0;
                  this.importe3 = 0;
                  this.importe4 = 0;
                  this.importe5 = 0;
                  this.importe6 = 0;
                  this.importe7 = 0;
                  this.importe8 = 0;
                  this.concepto1 = "Realización de presentación gráfica  ";
                  
                  this.importe1 = 1295;
      
                       }
                       if (this.choosedservice == 13){
                        this.concepto1 = "";
                        this.concepto2 = "";
                        this.concepto3 = "";
                        this.concepto4 = "";
                        this.concepto5 = "";
                        this.concepto6 = "";
                        this.concepto7 = "";
                        this.concepto8 = "";
                        this.importe1 = 0;
                        this.importe2 = 0;
                        this.importe3 = 0;
                        this.importe4 = 0;
                        this.importe5 = 0;
                        this.importe6 = 0;
                        this.importe7 = 0;
                        this.importe8 = 0;
                        this.concepto1 = "Redacción y registro de modelo de utilidad  ";
                        this.concepto2 = "Tasa de Solicitud ";
                        this.importe1 = 1208.83; //1085
                        this.importe2 = 86.17; //1085
            
                             }
                             if (this.choosedservice == 12){
                              this.concepto1 = "";
                              this.concepto2 = "";
                              this.concepto3 = "";
                              this.concepto4 = "";
                              this.concepto5 = "";
                              this.concepto6 = "";
                              this.concepto7 = "";
                              this.concepto8 = "";
                              this.importe1 = 0;
                              this.importe2 = 0;
                              this.importe3 = 0;
                              this.importe4 = 0;
                              this.importe5 = 0;
                              this.importe6 = 0;
                              this.importe7 = 0;
                              this.importe8 = 0;
                              this.concepto1 = "El precio del estudio es de 430€, si nos confirmas antes de 7 dias desde la fecha de este presupuesto el precio se reduce a 330€"; /* Realización de Estudio de Aplicaciones. */
                              
                              this.importe1 = 330; //390
                  
                                   }
                                   if (this.choosedservice == 11){
                                    this.concepto1 = "";
                                    this.concepto2 = "";
                                    this.concepto3 = "";
                                    this.concepto4 = "";
                                    this.concepto5 = "";
                                    this.concepto6 = "";
                                    this.concepto7 = "";
                                    this.concepto8 = "";
                                    this.importe1 = 0;
                                    this.importe2 = 0;
                                    this.importe3 = 0;
                                    this.importe4 = 0;
                                    this.importe5 = 0;
                                    this.importe6 = 0;
                                    this.importe7 = 0;
                                    this.importe8 = 0;
                                    this.concepto1 = "Realización de vídeo comercial. No incluye alquiler de espacios ni de personajes";
                                    
                                    this.importe1 = 1100;
                        
                                         }
                                         if (this.choosedservice == 9){
                                          this.concepto1 = "";
                                          this.concepto2 = "";
                                          this.concepto3 = "";
                                          this.concepto4 = "";
                                          this.concepto5 = "";
                                          this.concepto6 = "";
                                          this.concepto7 = "";
                                          this.concepto8 = "";
                                          this.importe1 = 0;
                                          this.importe2 = 0;
                                          this.importe3 = 0;
                                          this.importe4 = 0;
                                          this.importe5 = 0;
                                          this.importe6 = 0;
                                          this.importe7 = 0;
                                          this.importe8 = 0;
                                          this.concepto1 = "Honorarios de registro de marca nacional";
                                          this.concepto2 = "Tasas de registro de marca nacional ";
                                          this.importe1 = 168.39;
                                          this.importe2 = 126.61;
                                               }
                                               if (this.choosedservice == 32){
                                                this.concepto1 = "";
                                                this.concepto2 = "";
                                                this.concepto3 = "";
                                                this.concepto4 = "";
                                                this.concepto5 = "";
                                                this.concepto6 = "";
                                                this.concepto7 = "";
                                                this.concepto8 = "";
                                                this.importe1 = 0;
                                                this.importe2 = 0;
                                                this.importe3 = 0;
                                                this.importe4 = 0;
                                                this.importe5 = 0;
                                                this.importe6 = 0;
                                                this.importe7 = 0;
                                                this.importe8 = 0;
                                                this.concepto1 = "Honorarios de redacción y registro ";
                                                this.concepto2 = "Tasa de solicitud ";
                                                this.concepto3 = "Tasa de Petición IET ";
                                                this.concepto4 = "Tasa de Solicitud de examen sustantivo ";
                                                this.importe1 = 1741.45;
                                                this.importe2 = 86.17;
                                                this.importe3 = 587.77;
                                                this.importe4 = 334.61;
                                                     }
                                               if (this.choosedservice == 8){
                                                this.concepto1 = "";
                                                this.concepto2 = "";
                                                this.concepto3 = "";
                                                this.concepto4 = "";
                                                this.concepto5 = "";
                                                this.concepto6 = "";
                                                this.concepto7 = "";
                                                this.concepto8 = "";
                                                this.importe1 = 0;
                                                this.importe2 = 0;
                                                this.importe3 = 0;
                                                this.importe4 = 0;
                                                this.importe5 = 0;
                                                this.importe6 = 0;
                                                this.importe7 = 0;
                                                this.importe8 = 0;
                                                this.concepto1 = "Realización de Estudio de Patentes. Debido a la selección previa de todas las ideas que recibimos, el pago deberá efectuarse antes de 10 días contando desde la fecha del presente  presupuesto, fecha hasta la que tendremos disponibilidad para empezar con el proyecto. De no poderse abonar la cantidad total podrás pagar una señal de 50€";
                                                
                                                this.importe1 = 295;
                                    
                                                     }
                                                     if (this.choosedservice == 4){
                                                      this.concepto1 = "";
                                                      this.concepto2 = "";
                                                      this.concepto3 = "";
                                                      this.concepto4 = "";
                                                      this.concepto5 = "";
                                                      this.concepto6 = "";
                                                      this.concepto7 = "";
                                                      this.concepto8 = "";
                                                      this.importe1 = 0;
                                                      this.importe2 = 0;
                                                      this.importe3 = 0;
                                                      this.importe4 = 0;
                                                      this.importe5 = 0;
                                                      this.importe6 = 0;
                                                      this.importe7 = 0;
                                                      this.importe8 = 0;
                                                      this.concepto1 = "Realización de Estudio de Mercado. Debido a la selección previa de todas las ideas que recibimos, el pago deberá efectuarse antes de 10 días contando desde la fecha del presente  presupuesto, fecha hasta la que tendremos disponibilidad para empezar con el proyecto. De no poderse abonar la cantidad total podrás pagar una señal de 50€";
                                                      
                                                      this.importe1 = 485;
                                          
                                                           }
                                                           if (this.choosedservice == 22){
                                                            this.concepto1 = "";
                                                            this.concepto2 = "";
                                                            this.concepto3 = "";
                                                            this.concepto4 = "";
                                                            this.concepto5 = "";
                                                            this.concepto6 = "";
                                                            this.concepto7 = "";
                                                            this.concepto8 = "";
                                                            this.importe1 = 0;
                                                            this.importe2 = 0;
                                                            this.importe3 = 0;
                                                            this.importe4 = 0;
                                                            this.importe5 = 0;
                                                            this.importe6 = 0;
                                                            this.importe7 = 0;
                                                            this.importe8 = 0;
                                                            this.concepto1 = "Búsqueda de fabricantes y obtención de presupuesto";
                        
                                                            this.importe1 = 500;
                                                
                                                                 }
                                                                 if (this.choosedservice == 34){
                                                                  this.concepto1 = "";
                                                                  this.concepto2 = "";
                                                                  this.concepto3 = "";
                                                                  this.concepto4 = "";
                                                                  this.concepto5 = "";
                                                                  this.concepto6 = "";
                                                                  this.concepto7 = "";
                                                                  this.concepto8 = "";
                                                                  this.importe1 = 0;
                                                                  this.importe2 = 0;
                                                                  this.importe3 = 0;
                                                                  this.importe4 = 0;
                                                                  this.importe5 = 0;
                                                                  this.importe6 = 0;
                                                                  this.importe7 = 0;
                                                                  this.importe8 = 0;
                                                                  this.concepto1 = "Honorarios de registro de Diseño Industrial ";
                                                                  this.concepto2 = "Tasa de registro de Diseño Industrial";
                                                                  this.importe1 = 324.39;
                                                                  this.importe2 = 65.61;
                                                                       }
                                                                 if (this.choosedservice == 15){
                                                                  this.concepto1 = "";
                                                                  this.concepto2 = "";
                                                                  this.concepto3 = "";
                                                                  this.concepto4 = "";
                                                                  this.concepto5 = "";
                                                                  this.concepto6 = "";
                                                                  this.concepto7 = "";
                                                                  this.concepto8 = "";
                                                                  this.importe1 = 0;
                                                                  this.importe2 = 0;
                                                                  this.importe3 = 0;
                                                                  this.importe4 = 0;
                                                                  this.importe5 = 0;
                                                                  this.importe6 = 0;
                                                                  this.importe7 = 0;
                                                                  this.importe8 = 0;
                                                                  this.concepto1 = "La realización de los trabajos comerciales, conlleva el pago de una parte fija y el 25% sobre el beneficio que se consiga con los contratos firmados, siendo el otro 75% del beneficio para el inventor";
                                                                 
                                                                  this.importe1 = 1640;
                                                      
                                                                       }
if (this.choosedservice == 29){
  this.concepto1 = "";
  this.concepto2 = "";
  this.concepto3 = "";
  this.concepto4 = "";
  this.concepto5 = "";
  this.concepto6 = "";
  this.concepto7 = "";
  this.concepto8 = "";
  this.importe1 = 0;
  this.importe2 = 0;
  this.importe3 = 0;
  this.importe4 = 0;
  this.importe5 = 0;
  this.importe6 = 0;
  this.importe7 = 0;
  this.importe8 = 0;
this.concepto1 = "El precio del estudio es de 395€, si nos confirmas antes de 7 días desde la fecha de este presupuesto el precio se reduce a 295€";
this.importe1 = 295;
}

if (this.choosedservice == 35){
  this.concepto1 = "";
  this.concepto2 = "";
  this.concepto3 = "";
  this.concepto4 = "";
  this.concepto5 = "";
  this.concepto6 = "";
  this.concepto7 = "";
  this.concepto8 = "";
  this.importe1 = 0;
  this.importe2 = 0;
  this.importe3 = 0;
  this.importe4 = 0;
  this.importe5 = 0;
  this.importe6 = 0;
  this.importe7 = 0;
  this.importe8 = 0;
this.concepto1 = "Dossier grafico";
this.importe1 = 890;
}

/*       this.pagos_r.getProyectosForR(bankCtrl.value.id)
      .subscribe(res => {
        
  this.proyectos = res.proyectos;
  console.log(this.proyectos)

      }) */
    
      }
/*       saveData2(value){

        this.pagos_r.getServicios(value.id)
        .subscribe(res => {
    this.servicios = res.servicios;
    console.log(this.servicios)
  
        })

        console.log(value);
      } */

      saveDatatest(){
        console.log(this.selectedValue2)
      }

      saveDataTotal(fecha, client, selectedValue, selection, descrip, concepto1, concepto2, concepto3, concepto4, concepto5, concepto6, concepto7, concepto8, importe1, importe2, importe3, importe4, importe5, importe6, importe7, importe8, uid, tel, email, selectedValue2){
   console.log(selectedValue2)
   console.log(selection)
   console.log(fecha)
   console.log(uid)
        if (fecha === undefined || fecha === ""){
alert("Te falta la fecha ");
this.fechafalta = 1;
   } else if(this.choosedservice=== undefined || this.choosedservice === ""){
    alert("Te falta el servicio ");
   }
   else if(concepto1=== undefined || concepto1 === ""){
    alert("Te falta el concepto ");
   }
   else if(importe1=== 0 || importe1 === ""){
    alert("Te falta el importe ");
   }
   else if(client=== undefined || client === ""){
    alert("Te falta el cliente ");
   }
   else if(selectedValue=== undefined || selectedValue === ""){
    alert("Te falta el proyecto ");
   }
   else{    
/* let tipo = typeof importe1;
console.log(tipo); */
console.log(fecha._d)
var datefecha = new Date(fecha); 
console.log(datefecha);
var dd = datefecha.getDate();
var mm = datefecha.getMonth() + 1; 
var yyyy = datefecha.getFullYear(); 
console.log(dd);
console.log(mm);
console.log(yyyy);
var datefecha1 = dd + '/' + mm + '/' + yyyy; 
console.log(datefecha1);
var datefecha2 = datefecha1.toLocaleString();
        console.log(datefecha2);
        console.log(concepto1);
        console.log(this.selection)
        console.log(descrip);
        console.log(importe1);
        console.log(importe2);
        console.log(importe3);
        console.log(importe4);
        console.log(importe5);
        console.log(importe6);
        console.log(importe7);
        console.log(importe8);

        console.log(this.uid);
        console.log(this.servicedata);
        this.importe_total = (importe1 + importe2 + importe3 + importe4 + importe5 + importe6 + importe7 + importe8)
        console.log(this.importe_total);
        console.log(this.servicedata);
        this.presupuestos.addPresu(this.numero_presup, this.servicename, datefecha2, client, selectedValue, this.importe_total, this.uid, this.tel, this.email, selectedValue2, this.id_anterior)
      .subscribe(res => {
        console.log(res);



        if (res.response.status === 1){
          console.log(this.importe_total);
  let sig_numero_presup = this.numero_presup
          this.presupuestos.generatePDF(sig_numero_presup, this.choosedservice, datefecha2, client, selectedValue, selection, descrip, concepto1, concepto2, concepto3, concepto4, concepto5, concepto6, concepto7, concepto8, importe1, importe2, importe3, importe4, importe5, importe6, importe7, importe8, this.importe_total, selectedValue2)
          .subscribe(res2 => {
            console.log(res2);
            var fullDate = datefecha;
            var twoDigitMonth = fullDate.getMonth() + "";
            console.log(twoDigitMonth);
            if (twoDigitMonth.length === 1){
                twoDigitMonth = "0" + twoDigitMonth;
            }
            var twoDigitDate = fullDate.getDate() + "";
            if (twoDigitDate.length === 1){
                twoDigitDate = "0" + twoDigitDate;
            }
            
            
            console.log(twoDigitMonth);
            var currentDate = fullDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate; 
            console.log(currentDate);
            var datefecha3 = dd + '-' + mm + '-' + yyyy; 
            this.dialogRef.close({servicio: this.servicename, proyecto: selectedValue, client: client,  fecha: datefecha3, concepto1: concepto1, concepto2: concepto2, concepto3: concepto3, concepto4: concepto4, concepto5: concepto5, concepto6: concepto6, concepto7: concepto7, concepto8: concepto8, importe1: importe1, importe2: importe2, importe3: importe3, importe4: importe4, importe5: importe5, importe6: importe6, importe7: importe7, importe8: importe8, numero: this.numero_presup, importe_total: this.importe_total });
          })

        }

      })
    }
    }
  }
  








    @Component({
      selector: 'fuse-dialog-data-example-dialog2',
      templateUrl: 'dialog-data-example-dialog2.html',
    })
    
    export class Dialog2DataExampleDialogComponent implements OnInit, OnDestroy, AfterViewInit {  
      armtest: any;
      client: any;
      selectedValue: any;
      username: any;
      CIF: any;
telefono: any;
email: any;
descrip: any;
direccion: any;
codigo_postal: any;
ciudad: any;
empresa: any;
fechapresup: any;
      proyecto: any;
      idtoupdate: any;
      id_anterior: any;
      servicios: any;
      Clientes2: any ;
      numero_presup: any;
      choosedservice: any;
      servicename: any;
      fechafalta: any;
      triger: any;
      servicedata: any;

     // @Input() sideBar: VentasComponent; 
      
      public bankCtrl: FormControl = new FormControl();
      private clientes: any; 
        
        /** control for the MatSelect filter keyword */
      public bankFilterCtrl: FormControl = new FormControl();
    
      /** list of clientes */
    
      
      
      public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
      
      
      
      @ViewChild('singleSelect') singleSelect: MatSelect;
      
      
      /** Subject that emits when the component has been destroyed. */
      private _onDestroy = new Subject<void>();
      
    
      ngOnInit() {
        this.presupuestos.getServicios()
        .subscribe(res => {
    this.servicios = res.servicios;
    console.log(this.servicios)
    this.servicios.push({id: 3, nombre: "Elegir servicio", plazo:0, precio:0});
    console.log(this.servicios)
    this.bankCtrl.setValue(this.servicios[22]);
        
    
    
        this.filteredBanks.next(this.servicios.slice());
        this.bankFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterBanks();
        });
        })
       
        
  
    
      
    
        // listen for search field value changes
    
        }
    
        getlistCliente(){
    
    
        };
        ngAfterViewInit() {
          this.setInitialValue();
        }
        
        ngOnDestroy() {
          this._onDestroy.next();
          this._onDestroy.complete();
        }
        
        private setInitialValue() {
          this.filteredBanks
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
              // setting the compareWith property to a comparison function
              // triggers initializing the selection according to the initial value of
              // the form control (i.e. _initializeSelection())
              // this needs to be done after the filteredBanks are loaded initially
              // and after the mat-option elements are available
              this.singleSelect.compareWith = (a: Bank, b: Bank) => a.id === b.id;
             
            });
        }
        private filterBanks() {
          if (!this.servicios) {
            return;
          }
          // get the search keyword
          let search = this.bankFilterCtrl.value;
          if (!search) {
            this.filteredBanks.next(this.servicios.slice());
            return;
          } else {
            search = search.toLowerCase();
          }
          // filter the clientes
          this.filteredBanks.next(
            this.servicios.filter(servicios => servicios.nombre.toLowerCase().indexOf(search) > -1)
          );
        }
    
    
      /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
        constructor(public dialogRef: MatDialogRef<Dialog2DataExampleDialogComponent>, private presupuestos: PresupuestosService, private ventas: VentasService, @Inject(MAT_DIALOG_DATA) public data: any) {
          this.client = data.cliente
          this.proyecto = data.proyecto
          this.CIF = data.CIF;
          this.telefono = data.telefono;
          this.email =  data.email;
          this.descrip = data.comentarios;
          this.fechapresup = data.fecha;
          this.idtoupdate = data.idtoupdate;
          this.id_anterior = data.id_anterior;
          console.log(data)
        }
        
        saveData(bankCtrl){
    
      this.triger = 0;
          console.log(bankCtrl.value);
          this.servicename = bankCtrl.value.nombre;
          this.choosedservice = bankCtrl.value.id;
          console.log(this.choosedservice)
            this.servicedata = bankCtrl.value;
 
    }

        
          

        
         
    
          saveDataTotal2 (client, CIF, fechapresup, proyecto, telefono, email, direccion, codigo_postal, ciudad, empresa, descrip ){
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            var yyyy = today.getFullYear();
            
            fechapresup = dd + '/' + mm + '/' + yyyy;
            if (client === undefined || client === ""){
    alert("Te falta el nombre ");
    this.fechafalta = 1;
       } 
       else if(this.choosedservice=== undefined || this.choosedservice === "" ){ /* || this.choosedservice === 1 || this.choosedservice === "1" */
        alert("Te falta el servicio ");
       }
       else if(proyecto=== 0 || proyecto === ""){
        alert("Te falta el proyecto ");
       }
       else if(client=== undefined || client === ""){
        alert("Te falta el cliente ");
       }
       else if((telefono=== undefined || telefono === "") || (email=== undefined || email === "")){
        alert("Te falta el telefono o email ");
       }
 





       else{    
 
/*             this.ventas.addClientes(client, CIF, fechapresup, proyecto, telefono, email, direccion, codigo_postal, ciudad, empresa, descrip,   this.choosedservice, this.servicename)
          .subscribe(res => {
            console.log(res);
            if (res.response.status === "1"){
              this.ventas.modVentas2(this.idtoupdate).subscribe(res2 => {
                console.log(res2);

                this.dialogRef.close({result: "ik" });
              })
              
            }
    
          }) */
        }
      } 
    }
