
import {ClientesService} from '../clientes/clientes.service';
import { Component, ViewChild, OnInit, RendererStyleFlags2, Inject, OnDestroy, AfterViewInit, Input } from '@angular/core';
// import {VentasService} from './ventas.service';
 import {PresupuestosService} from '../presupuestos/presupuestos.service';
 import {PagosService} from '../pagos/pagos.service';
 import {PagosrService} from '../pagos_r/pagos_r.service';
 import {VentasService} from '../ventas/ventas.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatSelect, VERSION } from '@angular/material';
import {LoginService} from 'app/main/content/login/login.service';
import * as moment from 'moment';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
declare var jquery: any; declare var $: any;
interface Bank {
  id: string;
  name: string;
}
interface Bank2 {
  id: string;
  name: string;
}
interface Bank3 {
  id: string;
  name: string;
}
interface Pais {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'fuse-my-app',
  templateUrl: './anadirpagocomponent3.component.html',
  styleUrls: ['./anadirpagocomponent3.component.scss']
})

  export class Anadirpagocomponent3Component implements OnInit, OnDestroy, AfterViewInit {  
    armtest: any;
    username: any;
    proyectos: any;
    servicios: any;
    choosed: any;
    Clientes2: any ;
    selectedValue: any ;
    selectedValue2: any ;
    importe: any ;
    concepto: any ;
    fecha: any ;
    choosedcliente: any;
    clientename: any;
    triger: any;
    proyecto: any;
    clientedata: any;
    clientData: any;
    remitentes: any;
    remitente2: any;
    choosedProject: any;
    choosedService: any;
    choosedServiceID: any;
    paisctrl: any;
    showApplazado: boolean;
    pushed: boolean;
    justshow: boolean;
    allData: any;
    applazado: any;
    totalapagar: any;
    DatosCliente: any;
    paises: Pais[] = [
      {value: 'spain', viewValue: 'España'},
      {value: 'otro', viewValue: 'Resto del Mundo'},
     
    ];
    // @Input() sideBar: PagosrComponent; 
    
    public bankCtrl: FormControl = new FormControl();
    private clientes: any; 
      
      /** control for the MatSelect filter keyword */
    public bankFilterCtrl: FormControl = new FormControl();
  
    /** list of clientes */
  
    
    
    public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
    
    
    
    @ViewChild('singleSelect') singleSelect: MatSelect;
    




    public bankCtrl2: FormControl = new FormControl();
    public bankCtrl3: FormControl = new FormControl();

  public bankFilterCtrl2: FormControl = new FormControl();
  public bankFilterCtrl3: FormControl = new FormControl();

  public filteredBanks2: ReplaySubject<Bank2[]> = new ReplaySubject<Bank2[]>(1);
  public filteredBanks3: ReplaySubject<Bank3[]> = new ReplaySubject<Bank3[]>(1);

  
    /** Subject that emits when the component has been destroyed. */
    private _onDestroy = new Subject<void>();
    
  
    ngOnInit() {
      this.pushed = false
      this.remitentes = [
        {id: 0, nombre: 'LFDI', value: 1},
        {id: 1, nombre: 'TIE', value: 2}
      ]
      this.choosedProject = []
      this.triger = 0;

    
  
      // listen for search field value changes
      this.showApplazado = false;
      this.justshow = false;
      }
  
      getlistCliente(){
  
  
      };
      ngAfterViewInit() {
        this.setInitialValue();
        this.setInitialValue2();
        this.setInitialValue3();

      }
      
      ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
      }
      
      private setInitialValue() {
        this.filteredBanks
          .pipe(take(1), takeUntil(this._onDestroy))
          .subscribe(() => {
            // setting the compareWith property to a comparison function
            // triggers initializing the selection according to the initial value of
            // the form control (i.e. _initializeSelection())
            // this needs to be done after the filteredBanks are loaded initially
            // and after the mat-option elements are available
            this.singleSelect.compareWith = (a: Bank, b: Bank) => a.id === b.id;
           
          });
      }
      private filterBanks() {
        if (!this.clientes) {
          return;
        }
        // get the search keyword
        let search = this.bankFilterCtrl.value;
        if (!search) {
          this.filteredBanks.next(this.clientes.slice());
          return;
        } else {
          search = search.toLowerCase();
        }
        // filter the clientes
        this.filteredBanks.next(
          this.clientes.filter(clientes => clientes.nombre.toLowerCase().indexOf(search) > -1)
        );
      }

    
      private setInitialValue2() {
        this.filteredBanks2
          .pipe(take(1), takeUntil(this._onDestroy))
          .subscribe(() => {
            // setting the compareWith property to a comparison function
            // triggers initializing the selection according to the initial value of
            // the form control (i.e. _initializeSelection())
            // this needs to be done after the filteredBanks are loaded initially
            // and after the mat-option elements are available
            this.singleSelect.compareWith = (a: Bank2, b: Bank2) => a.id === b.id;
           
          });
      }
      private setInitialValue3() {
        this.filteredBanks3
          .pipe(take(1), takeUntil(this._onDestroy))
          .subscribe(() => {
            // setting the compareWith property to a comparison function
            // triggers initializing the selection according to the initial value of
            // the form control (i.e. _initializeSelection())
            // this needs to be done after the filteredBanks are loaded initially
            // and after the mat-option elements are available
            this.singleSelect.compareWith = (a: Bank3, b: Bank3) => a.id === b.id;
           
          });
      }
      private filterBanks2() {
        if (!this.servicios) {
          return;
        }
        // get the search keyword
        let search = this.bankFilterCtrl2.value;
        if (!search) {
          this.filteredBanks2.next(this.servicios.slice());
          return;
        } else {
          search = search.toLowerCase();
        }
        // filter the clientes
        this.filteredBanks2.next(
          this.servicios.filter(servicios => servicios.nombre.toLowerCase().indexOf(search) > -1)
        );
      }
      private filterBanks3() {
        if (!this.servicios) {
          return;
        }
        // get the search keyword
        let search = this.bankFilterCtrl3.value;
        if (!search) {
          this.filteredBanks3.next(this.servicios.slice());
          return;
        } else {
          search = search.toLowerCase();
        }
        // filter the clientes
        this.filteredBanks3.next(
          this.servicios.filter(servicios => servicios.nombre.toLowerCase().indexOf(search) > -1)
        );
      }
  
    /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
      constructor(public dialogRef: MatDialogRef<Anadirpagocomponent3Component>, private presupuestos: PresupuestosService, private pagos_r: PagosrService, private pagos: PagosService, private clientesserv: ClientesService, private ventas: VentasService, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.showApplazado = false
        console.log(this.showApplazado)
        this.DatosCliente = data
        this.fecha = data.fecha
        console.log(this.DatosCliente)
        if (this.DatosCliente) {
          this.saveData(this.DatosCliente)
        }
      }
     
      saveData(bankCtrl){
        this.clientesserv.getClientesById(this.DatosCliente.id_anterior)
        .subscribe(res => {
          console.log(res)
          this.DatosCliente.CIF = res.clientes[0].CIF
          this.DatosCliente.direccion = res.clientes[0].direccion
          this.DatosCliente.codigo_postal = res.clientes[0].codigo_postal
          this.DatosCliente.ciudad = res.clientes[0].ciudad
          this.DatosCliente.empresa = res.clientes[0].empresa


          console.log(this.DatosCliente)
         /*   this.telefono = res.clientes[0].telefono
          this.email = res.clientes[0].email
          this.direccion = res.clientes[0].direccion
          this.codigo_postal = res.clientes[0].codigo_postal
          this.ciudad = res.clientes[0].ciudad
          this.empresa = res.clientes[0].empresa */
        
        
        })
/*         if (bankCtrl.value.CIF === undefined || bankCtrl.value.CIF === "undefined"){
          alert("Faltan datos de CIF");
          this.triger = 1;
        }
        else if (bankCtrl.value.email === undefined || bankCtrl.value.email === "undefined"){
  
          alert("Faltan datos de email");
          this.triger = 1;
        }
        else if (bankCtrl.value.direccion === undefined || bankCtrl.value.direccion === "undefined"){
  
          alert("Faltan datos de direccion");
          this.triger = 1;
        }
        else if (bankCtrl.value.codigo_postal === undefined || bankCtrl.value.codigo_postal === "undefined"){
  
          alert("Faltan datos de codigo postal");
          this.triger = 1;
        }
        else if (bankCtrl.value.ciudad === undefined || bankCtrl.value.ciudad === "undefined"){
  
          alert("Faltan datos de ciudad");
          this.triger = 1;
        } 
  else{*/
    this.triger = 0;
        console.log(bankCtrl);
    /*     this.clientData = bankCtrl.value;
        this.clientename = bankCtrl.value.nombre;
        this.choosedcliente = bankCtrl.value.id;
          this.clientedata = bankCtrl.value; */
        // this.dialogRef.close({id_cliente: bankCtrl.value.id, nombre: bankCtrl.value.nombre});

        this.presupuestos.getServiciosbypresup2(this.DatosCliente.id_anterior)
        .subscribe(res => {
          console.log(res)
          console.log(this.showApplazado)
    this.servicios = res.presupuestos4;
    this.allData = this.servicios[0]
    console.log(this.servicios)
    if (this.servicios[0].estado === "0"){
      this.showApplazado = true
    }
    else{
      this.showApplazado = false
      this.pagos.getPagosPresupuestos(this.allData.numero).subscribe( result => {
        console.log(result)
        console.log(result.pendiente.pendiente)
    
    this.totalapagar = Number(result.pendiente.pendiente)
      })
      this.justshow = true
    }
    // this.servicios.push({id: 3, nombre: "Elegir servicio", plazo: 0, precio: 0});
    
    console.log(this.servicios[0])
    this.bankCtrl2.setValue(this.servicios[0]);
    this.bankCtrl3.setValue(this.servicios[0]);

this.choosedService = this.servicios[0].servicio
this.choosedServiceID = this.servicios[0].id_servicio   
this.choosed = this.servicios[0].numero
this.totalapagar = this.servicios[0].importe
    
        this.filteredBanks2.next(this.servicios.slice());
        this.bankFilterCtrl2.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterBanks2();

        });

        this.filteredBanks3.next(this.servicios.slice());
        this.bankFilterCtrl3.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterBanks3();

        });


        })



/*         this.pagos_r.getProyectosForR(bankCtrl.value.id)
        .subscribe(res => {
          
    this.proyectos = res.proyectos;
    console.log(this.proyectos)
  
        }) */
      }

      dataChanged2(){
        setTimeout (() => {
          if (this.totalapagar > this.importe && this.allData.estado === '0'){
            this.applazado = Math.ceil(this.totalapagar / this.importe)
           this.showApplazado = true
            }
            else{
              this.applazado = 0
              this.showApplazado = false
            }
       }, 2000);
      
      }
      saveData1(bankCtrl2){
        this.pushed = true

        console.log(bankCtrl2.value);
this.proyecto = bankCtrl2.value.servicio
this.choosed = bankCtrl2.value
 this.bankCtrl3.setValue(bankCtrl2.value);
 this.filteredBanks3.next(bankCtrl2.value);
 this.bankFilterCtrl3.valueChanges
 .pipe(takeUntil(this._onDestroy))
 .subscribe(() => {
   this.filterBanks3();

 });

 this.choosedService = bankCtrl2.value.servicio
this.choosedServiceID = bankCtrl2.value.id_servicio   
console.log(this.choosedService)
console.log(this.choosedServiceID)




      }
      /*   } */
        saveData2(value){
console.log(value)
this.choosed =  value.value.numero
this.totalapagar = value.value.importe
this.allData = value.value
if (value.value.estado === "0"){
  this.showApplazado = true
}
else{
  this.pagos.getPagosPresupuestos(this.allData.numero).subscribe( result => {
    console.log(result)
    console.log(result.pendiente.pendiente)

this.totalapagar = Number(result.pendiente.pendiente)
  })
  this.showApplazado = false
  this.justshow = true
}
/*           this.pagos_r.getServicios(value.id)
          .subscribe(res => {
      this.servicios = res.servicios;
      console.log(this.servicios)
    
          })
  
          console.log(value); */
        }
  


        verpresu(){
  
            window.open("http://lfdi.bitronedev.com/public/" + this.choosed + ".pdf", "_blank");

        }
       
  
        saveDataTotal(fecha, importe, selectedValue2, selectedValue){

          console.log(fecha);
          console.log(importe);
          console.log(this.DatosCliente);
          console.log(this.allData);




     
/*           if (this.choosedcliente === undefined ||  fecha === undefined  || concepto === undefined || importe === undefined ){
  alert("rellene todos los campos")
          }
  
  
          else{ */
            var datefecha = new Date(fecha); 
            console.log(datefecha);
            var dd = datefecha.getDate();
            var mm = datefecha.getMonth() + 1; 
            var yyyy = datefecha.getFullYear(); 
            console.log(dd);
            console.log(mm);
            console.log(yyyy);
            var datefecha1 = dd + '/' + mm + '/' + yyyy; 
            console.log(datefecha1);
            var datefecha2 = datefecha1.toLocaleString();
          console.log(datefecha2);
          console.log(this.clientData);
          console.log(this.choosedcliente);
          console.log(this.clientedata);
          
var totalapagar = 0

          this.pagos.addPayment2(this.DatosCliente, this.allData, importe, datefecha2, this.applazado, this.totalapagar)
          .subscribe(res => {
            console.log(res);  
            this.dialogRef.close()
          
          })
/*             this.ventas.createInvoice(this.clientename, this.clientData.CIF, fecha, this.choosed.proyecto, this.clientData.telefono, this.clientData.email, this.clientData.direccion, this.clientData.codigo_postal, this.clientData.ciudad, this.clientData.empresa, concepto,   this.choosedServiceID, this.choosedService, importe, paisctrl, remitente2.id).subscribe(res4 => {
              console.log(res4);
            }) */


   /*        this.pagos_r.addPagos(this.choosedcliente, datefecha2, concepto, importe, selectedValue2.id, selectedValue.id)
        .subscribe(res => {
          console.log(res); */
  
  
  
          if (status ){
 /*            this.pagos_r.sendEmail(this.clientedata, datefecha2, concepto, importe, selectedValue2.nombre, selectedValue.nombre)
            .subscribe(res2 => {
              console.log(res2);
              var fullDate = datefecha;
              var twoDigitMonth = fullDate.getMonth() + "";
              if (twoDigitMonth.length === 1){
                  twoDigitMonth = "0" + twoDigitMonth;
              }
              var twoDigitDate = fullDate.getDate() + "";
              if (twoDigitDate.length === 1){
                  twoDigitDate = "0" + twoDigitDate;
              }
              
              
              
              var currentDate = fullDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate; console.log(currentDate);
              this.dialogRef.close({cliente: this.clientename, proyecto: selectedValue.nombre, servicio: selectedValue2.nombre, importe: importe, concepto: concepto, fecha: currentDate });
            }) */
/*             var fullDate = datefecha;
            var twoDigitMonth = fullDate.getMonth() + "";
            if (twoDigitMonth.length === 1){
                twoDigitMonth = "0" + twoDigitMonth;
            }
            var twoDigitDate = fullDate.getDate() + "";
            if (twoDigitDate.length === 1){
                twoDigitDate = "0" + twoDigitDate;
            }
            var currentDate = fullDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate; console.log(currentDate);
            this.dialogRef.close({cliente: this.clientename, proyecto: this.proyecto, servicio: this.choosed.servicio, importe: importe, concepto: concepto, fecha: currentDate });
          } */
  
    
      //  }
      }
  
  }
}
