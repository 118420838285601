import { FuseMatSidenavHelperDirective } from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.directive';
import { Component, ViewChild, OnInit, RendererStyleFlags2, OnDestroy, AfterViewInit, Input, Inject } from '@angular/core';
import {PatentesService} from '../../patentes.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect, VERSION } from '@angular/material';
import {LoginService} from 'app/main/content/login/login.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
declare var jquery: any; declare var $: any;
interface Bank {
  id: string;
  name: string;
}
@Component({
  selector: 'fuse-tab-mu',
  templateUrl: './mu.component.html',
  styleUrls: ['./mu.component.scss']
})
export class MuComponent implements OnInit {
  public loading = false;
  public gridApi;
  private gridColumnApi;
  public gridOptions;
 // private rowData: any[];
  trigger: any; 
 // private columnDefs;
  // private rowData;
  private rowSelection;
  checked = true;
  nombre = true;
  apellido = true;
  apellido2 = true;
  butonrowclicked = false;
  addbutton = false;
  dat: any;
  clientetoadd: any;
  datatosend: any;
  sometrig: any;
  datatomod: any;
  columnDefs: any;
  personas: any;
  carMappings: any;
  carMappings_R: any;
  carMappings_R3: any;
  columnTypes: any;
  serviciostosend: any;
  sectorid: any;
  serviciostoadd: any[];
  presup: any[];
  personastoadd: any;
  rowData: any;
  arm: any;
  public trig2;
  editType: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;  private components;
  constructor(private patentes: PatentesService, private router: Router,
    private loginService1: LoginService,   public dialog: MatDialog) { 
    this.editType = 'fullRow';
    this.rowSelection = 'single';

  }

  ngOnInit() {
    this.trigger = false;
   // this.getPersonas();
    this.trig2 = false;
    this.sometrig = false;
   // this.getSectores();
   if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') ||  localStorage.getItem("id_token") === null) {
    console.log(localStorage)
    this.router.navigate(['/login']);
  } else {
    this.loginService1.checkToken().subscribe(rescat => {
      console.log(rescat);
      if (rescat.response){
        if (rescat.response === 1){


          
         // this.getPersonas();
      }
      else{

        this.router.navigate(['/login']);
      }
    }
      else{
        this.router.navigate(['/login']);
      }

    })
      }

      this.getPatentes(this.carMappings, this.carMappings_R)

    


         // this.getProyectos(this.carMappings);
  }



  openDialog(rowindex, dataid): void {
   /*  var dataformod2 = this.gridApi.getRowNode(rowindex);
    console.log(dataformod2);
    this.proyectos.modProyectos(dataformod2.data)
    .subscribe(res => {
        
      console.log(res);
          }); */
   

    const dialogRef = this.dialog.open(DialogDataExampleDialogComponent, {
      width: '600px',
      height: '700px',
      
    });
  
    dialogRef.afterClosed().subscribe(result => {
      var rowNode = this.gridApi.getDisplayedRowAtIndex(rowindex);
      rowNode.setDataValue('cliente',  result.nombre);
      rowNode.setDataValue('email',  result.email);
     // this.armtest = result.age;
     var dataformod = this.gridApi.getRowNode(rowindex);
     console.log(dataformod);
     console.log(dataformod.data.id);
     console.log(result.id_cliente);
     this.clientetoadd = result.id;
     
     console.log(result)
/*      if (dataid.id){
     this.proyectos.modProyectos2(dataformod.data.id, result.id_cliente)
     .subscribe(res => {
         
       console.log(res);
           });
          }
          else{

this.clientetoadd = result.id_cliente;
console.log(this.clientetoadd)
          } */
    });
  }


  









   

  getPatentes(data1, data2){
    this.loading = true;
    function extractValues(mappings) {
      return Object.keys(mappings);
    }
    function lookupValue(mappings, key) {
      return mappings[key];
    }
    function lookupKey(mappings, name) {
      for (var key in mappings) {
        if (mappings.hasOwnProperty(key)) {
          if (name === mappings[key]) {
            return key;
          }
        }
      }
    }
    this.columnDefs = [];
    this.patentes.getMu()
    .subscribe(res => {
      console.log(res);
      this.rowData = res.patentes;
      console.log(res);

        for (let key in res.patentes[0]) {
          if (key === 'id') {
            this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', hide: true, width: 90,   type: 'numericColumn'  };
            console.log('      key:', key);
            this.columnDefs.push(this.dat);
            // console.log(this.columnDefs);

        }        else  if (key === 'estado') {
          this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter',  width: 100 };
          console.log('      key:', key);
          this.columnDefs.push(this.dat);
          // console.log(this.columnDefs);

      }
      else  if (key === 'clientID') {
        this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter',  width: 100, hide: true };
        console.log('      key:', key);
        this.columnDefs.push(this.dat);
        // console.log(this.columnDefs);

    }
    else  if (key === 'patente') {
      this.dat = {headerName: key, field: key, editable: true,  filter: 'agTextColumnFilter' };
      console.log('      key:', key);
      this.columnDefs.push(this.dat);
      // console.log(this.columnDefs);

  }
      else  if (key === 'cliente') {
        this.dat = {headerName: key, field: key, editable: false,  filter: 'agTextColumnFilter' };
        console.log('      key:', key);
        this.columnDefs.push(this.dat);
        // console.log(this.columnDefs);

    }
    else  if (key === 'numero') {
      this.dat = {headerName: key, field: key, editable: true,  filter: 'agTextColumnFilter' };
      console.log('      key:', key);
      this.columnDefs.push(this.dat);
      // console.log(this.columnDefs);

  }

    else  if (key === 'email') {
      this.dat = {headerName: key, field: key, editable: false,  filter: 'agTextColumnFilter' };
      console.log('      key:', key);
      this.columnDefs.push(this.dat);
      // console.log(this.columnDefs);

  }



      else  if (key === 'fecha_registro') {
        this.dat = {headerName: 'Fecha', field: key,  width: 100, editable: true,  valueFormatter: this.dateFormatter,  cellEditor: "datePicker", 
      
      
      };
        console.log('      key:', key);
        this.columnDefs.push(this.dat);
        // console.log(this.columnDefs);   //  type: ["dateColumn", "nonEditableColumn"] cellEditor: "datePicker", 
        

    }
    else  if (key === 'fecha_exp1') {
      this.dat = {headerName: "Caducidad", field: key, editable: false,  valueFormatter: this.dateFormatter, width: 200,  
    
    
    };
      console.log('key:', key);
      this.columnDefs.push(this.dat);
      // console.log(this.columnDefs);   //  type: ["dateColumn", "nonEditableColumn"] cellEditor: "datePicker", 
      

  }
    else  if (key === 'fecha_exp2') {
      this.dat = {headerName: "PCT", field: key, editable: false,  valueFormatter: this.dateFormatter, width: 200,  
    
    
    };
      console.log('      key:', key);
      this.columnDefs.push(this.dat);
      // console.log(this.columnDefs);   //  type: ["dateColumn", "nonEditableColumn"] cellEditor: "datePicker", 
      

  }
/*     else  if (key === 'sector_id') {
      this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter',  width: 10,  hide: true,  type: 'numericColumn'};
      console.log('      key:', key);
      this.columnDefs.push(this.dat);
      // console.log(this.columnDefs);

  } */


        else{
          this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', editable : true, hide:true };
          console.log('      key2:', key);
          this.columnDefs.push(this.dat);

        }
          }
          this.trigger = true;
          console.log(this.columnDefs);
         
    

          this.loading = false;

      });



      this.components = { datePicker: getDatePicker() };
      function getDatePicker() {
      
        function Datepicker() {}
        
        Datepicker.prototype.init = function(params) {
          this.eInput = document.createElement("input");
          this.eInput.value = params.value;
          $(this.eInput).datepicker({ dateFormat: "dd/mm/yy" });
        };
        Datepicker.prototype.getGui = function() {
          return this.eInput;
        };
        Datepicker.prototype.afterGuiAttached = function() {
          this.eInput.focus();
          this.eInput.select();
        };
        Datepicker.prototype.getValue = function() {
          return this.eInput.value;
        };
        Datepicker.prototype.destroy = function() {};
        Datepicker.prototype.isPopup = function() {
          return false;
        };
      
        return Datepicker;
      
      }



      

/*       this.columnTypes = {
        nonEditableColumn: { editable: false },
        dateColumn: {
          filter: "agDateColumnFilter",
          filterParams: {
            comparator: function(filterLocalDateAtMidnight, cellValue) {
              var dateParts = cellValue.split("/");
              var day = Number(dateParts[2]);
              var month = Number(dateParts[1]) - 1;
              var year = Number(dateParts[0]);
              var cellDate = new Date(day, month, year);
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            }
          }
        }
      } */


      
  }

  dateFormatter(params) {
  
    // return params.value ? (new Date(params.value)).toLocaleDateString() : '';
    var timestamp = Date.parse(params.value);
 
 if (isNaN(timestamp) === false) {
   var d = new Date(timestamp).toLocaleDateString();
   console.log(d)
   return d
  
 }
 console.log("222222222")
 // return timestamp
   }
  onCellDoubleClicked(event1: any) { console.log('doubleclick', event1); 
  if (event1.colDef.field === 'nombre' ){ 
    
    var armindex = this.gridApi.getFocusedCell();
    console.log(armindex);
    // this.gridApi.clearFocusedCell();
   
    var col = this.gridColumnApi.getColumn("fecha_ini");

    // obtain the column definition from the column
    var colDef = col.getColDef();
    console.log(colDef);
    // update the header name
    colDef.editable = false;
  }
  else if (event1.colDef.field === 'cliente' ){ 

    var armindex = this.gridApi.getFocusedCell();
    console.log(armindex);
    // this.gridApi.clearFocusedCell();
    this.openDialog(armindex.rowIndex, event1.data);
    var col = this.gridColumnApi.getColumn("fecha_ini");

    // obtain the column definition from the column
    var colDef = col.getColDef();
    console.log(colDef);
    // update the header name
    colDef.editable = false;
  }


else if (event1.colDef.field === 'estado' ){
  var armindex2 = this.gridApi.getFocusedCell();
  console.log(armindex2);
  // this.gridApi.stopEditing();
  
  var col = this.gridColumnApi.getColumn("fecha_ini");

  // obtain the column definition from the column
  var colDef = col.getColDef();
  
  // update the header name
 // colDef.editable = false;
}
else if (event1.colDef.field === 'manager' ){
  var armindex2 = this.gridApi.getFocusedCell();
  console.log(armindex2);
 //  this.gridApi.stopEditing();
 
  var col = this.gridColumnApi.getColumn("fecha_ini");

  // obtain the column definition from the column
  var colDef = col.getColDef();
  
  // update the header name
 // colDef.editable = false;
}
else if (event1.colDef.field === 'comentarios' ){
  var armindex2 = this.gridApi.getFocusedCell();
  console.log(armindex2);
  
  
  var col = this.gridColumnApi.getColumn("fecha_ini");

  // obtain the column definition from the column
//  var colDef = col.getColDef();
  
  // update the header name
 // colDef.editable = false;
}
else if (event1.colDef.field === 'fecha_ini' ){
  var armindex2 = this.gridApi.getFocusedCell();
  console.log(armindex2);
  
  


var col = this.gridColumnApi.getColumn("fecha_ini");

// obtain the column definition from the column
var colDef = col.getColDef();

// update the header name
colDef.editable = true;

// the column is now updated. to reflect the header change, get the grid refresh the header


}

}


  
  saveRow(){
this.sometrig = true;
    var dataformod2 = this.gridApi.getDisplayedRowAtIndex(0);
    console.log(dataformod2);
    this.gridApi.stopEditing();
/*     if (!dataformod2.data.servicios && !dataformod2.data.personas_encargados) {

      dataformod2.data.servicios = null;
      dataformod2.data.personas_encargados = null;
  
      }
      else if (!dataformod2.servicios){
  
        dataformod2.data.servicios = null;
        this.serviciostosend = null;
      }
      else if (!dataformod2.personas_encargados){
  
        dataformod2.data.personas_encargados = null;
      }

    if (dataformod2.data.nombre === "" || !dataformod2.data.cliente ){
      this.openDialog4();

    }
  
    else{





      
    console.log(dataformod2.data);
    console.log(this.clientetoadd);
   
    this.proyectos.addProyectos(dataformod2.data, this.clientetoadd, this.serviciostosend)
    
    .subscribe(res => {
      this.gridApi.stopEditing();
console.log(res);
    });
  
  } */

  } 
  getRowData() {
    var rowData = [];
    this.gridApi.forEachNode(function(node) {
      rowData.push(node.data);
    });
    console.log('Row Data:');
    console.log(rowData);
  }
  clearData() {
    this.gridApi.setRowData([]);
  }

  onAddRow() {
    this.addbutton = true;
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0});
    console.log(res);
    this.datatosend = res.add[0].data;

     
    
    console.log( this.datatosend);
  }
  onRowClicked(event: any) { console.log('row', event);
  this.butonrowclicked = true;
}

  onRemoveSelected() {
    var selectedData = this.gridApi.getSelectedRows();
    if (selectedData.length !== 0){
      console.log(selectedData);
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = '¿Eliminar ' + selectedData[0].nombre + '?';

      this.confirmDialogRef.afterClosed().subscribe(result => {
          if ( result )
          {
           // var selectedData = this.gridApi.getSelectedRows();

            
            var res = this.gridApi.updateRowData({ remove: selectedData });
            console.log(res.remove[0].data.id);
            this.patentes.remPatentes(res.remove[0].data.id)
            .subscribe(res2 => {
        console.log(res2);
            });
            printResult(res);
          }
          this.confirmDialogRef = null;
      });
    }
  }




  Renovar() {
    var selectedData = this.gridApi.getSelectedRows();
    if (selectedData.length !== 0){
      console.log(selectedData);
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = '¿Eliminar ' + selectedData[0].nombre + '?';

      this.confirmDialogRef.afterClosed().subscribe(result => {
          if ( result )
          {
           // var selectedData = this.gridApi.getSelectedRows();

            
            var res = this.gridApi.updateRowData({ remove: selectedData });
            console.log(res.remove[0].data.id);
            this.patentes.remPatentes(res.remove[0].data.id)
            .subscribe(res2 => {
        console.log(res2);
            });
            printResult(res);
          }
          this.confirmDialogRef = null;
      });
    }
  }



  onRowValueChanged(event: any){
    console.log('onRowValueChanged', event.data);
  this.datatomod = event.data;
  console.log('onRowValueChanged', event.data);
  this.datatomod = event.data;
  if (this.datatomod && !this.addbutton){
    this.patentes.modPatente(this.datatomod)
    .subscribe(res => {
        
      console.log(res);
          });
        }
        else{
  
      this.addbutton = false;
      
    console.log(this.datatomod);
    console.log(this.clientetoadd);
   
    this.patentes.addMu(this.datatomod, this.clientetoadd)
    
    .subscribe(res => {
   //   this.gridApi.stopEditing();
console.log(res);
    });
  }

  }
  showHide(e) {
    // var checked = e.target.checked;
    console.log(e.source.id);
   this.gridColumnApi.setColumnVisible(e.source.id, e.checked);
  }


  cancelrow(){
    this.gridApi.forEachNode(function(node) {
      if (node.rowIndex === 0) {
        node.setSelected(true);
        var selectedData = node.data;
        console.log(selectedData);
       
    // dataforcancel(selectedData);
      }
    });
    this.cancelrow2();

    }  
    
    
    cancelrow2()
    {
      var selectedData = this.gridApi.getSelectedRows();
      var res = this.gridApi.updateRowData({ remove: selectedData });
      this.addbutton = false;
    }



  onGridReady(params) {
    console.log(params);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

  }

}
function dataforcancel(selectedData){
  // var res = this.gridApi.updateRowData({ remove: selectedData });
  printResult(selectedData);
  //   printResult(res2);

   }
var newCount = 1;
function createNewRowData() {
  var newData = {
     
  };
  newCount++;
  return newData;
}
function printResult(res) {
  console.log('---------------------------------------');
  if (res.add) {
    res.add.forEach(function(rowNode) {
      console.log('Added Row Node', rowNode);
    });
  }
  if (res.remove) {
    res.remove.forEach(function(rowNode) {

      console.log('Removed Row Node', rowNode.data.id);
    });
  }
  if (res.update) {
    res.update.forEach(function(rowNode) {
      console.log('Updated Row Node', rowNode);
    });
  }
}
@Component({
  selector: 'fuse-dialog-data-example-dialog',
  templateUrl: 'dialog-data-example-dialog.html',
})
export class DialogDataExampleDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  armtest: any;
  username: any;
  nombre: any;
  apellido: any;
  apellido2: any;
  email: any;
  telefono: any;
  CIF: any;
  direccion: any;
  cp: any;
  ciudad: any;

  Clientes2: any ;
  @Input() sideBar: MuComponent;
  
  public bankCtrl: FormControl = new FormControl();
  private clientes: any; 
    
    /** control for the MatSelect filter keyword */
  public bankFilterCtrl: FormControl = new FormControl();

  /** list of clientes */

  
  
  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  
  
  
  @ViewChild('singleSelect') singleSelect: MatSelect;
  
  
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  

  ngOnInit() {
 /*    this.patentes.getClientes()
    .subscribe(res => {
this.clientes = res.clientes2;
console.log(this.clientes)
this.bankCtrl.setValue(this.clientes[10]);
    

  
    this.filteredBanks.next(this.clientes.slice());
    this.bankFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterBanks();
    });
    }) */
  

    // listen for search field value changes

    }

    getlistCliente(){


    };
    ngAfterViewInit() {
      this.setInitialValue();
    }
    
    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
    
    private setInitialValue() {
      this.filteredBanks
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          // setting the compareWith property to a comparison function
          // triggers initializing the selection according to the initial value of
          // the form control (i.e. _initializeSelection())
          // this needs to be done after the filteredBanks are loaded initially
          // and after the mat-option elements are available
          this.singleSelect.compareWith = (a: Bank, b: Bank) => a.id === b.id;
         
        });
    }
    private filterBanks() {
      if (!this.clientes) {
        return;
      }
      // get the search keyword
      let search = this.bankFilterCtrl.value;
      if (!search) {
        this.filteredBanks.next(this.clientes.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the clientes
      this.filteredBanks.next(
        this.clientes.filter(clientes => clientes.nombre.toLowerCase().indexOf(search) > -1)
      );
    }


  /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
    constructor(public dialogRef: MatDialogRef<DialogDataExampleDialogComponent>, private patentes: PatentesService) {}
    
    saveData(bankCtrl){
      console.log(bankCtrl.value);
      this.dialogRef.close({id_cliente: bankCtrl.value.id, nombre: bankCtrl.value.nombre, email: bankCtrl.value.email});
      }
      SaveCliente(){
        if (this.nombre && this.apellido && this.email){
      this.patentes.createCliente(this.nombre, this.apellido, this.apellido2, this.email, this.telefono, this.CIF, this.direccion, this.cp, this.ciudad).subscribe(res => {
      console.log(res.response)
      this.dialogRef.close({ nombre: this.nombre, email: this.email, id: res.response});
      })
    }
    else{
    alert("Faltan datos")
    }
    }
      saveData1(res){
        console.log(res);
        this.dialogRef.close({age: this.armtest, username: this.username});
        }
}

































