import { Component } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';

import { ProfileService } from '../../profile.service';

@Component({
    selector   : 'fuse-profile-photos-videos',
    templateUrl: './pagos.component.html',
    styleUrls  : ['./pagos.component.scss'],
    animations : fuseAnimations
})
export class FuseProfilePagosComponent
{
    pagos: any;
    trigger: boolean
        constructor(private profileService: ProfileService)
        {
            this.trigger = false
            let cliente = localStorage.getItem('user_profile')
            this.profileService.getPagosbyID(cliente).subscribe(pagos => {
                this.pagos = pagos.pagos;
                console.log(this.pagos)
                this.trigger = true
            });
        }
}
