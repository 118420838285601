import { Component, OnInit } from '@angular/core';
import { SectoresService } from './informes.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import {LoginService} from 'app/main/content/login/login.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
@Component({
  selector: 'fuse-app-sectores',
  templateUrl: './informes.component.html',
  styleUrls: ['./informes.component.scss']
})
export class InformesComponent implements OnInit {
  public gridApi;
  private gridColumnApi;
 // private rowData: any[];
  trigger: any; 
  
  columnDefs = [
    { field: 'make', sortable: true, filter: true },
    { field: 'model', sortable: true, filter: true },
    { field: 'price', sortable: true, filter: true }
];

  // private rowData;
  private rowSelection;
  public components;
  checked = true;
  nombre = true;
  apellido = true;
  apellido2 = true;
  butonrowclicked = false;
  addbutton = false;
  dat: any;
  datatosend: any;
  datatomod: any;
 /*  columnDefs: any; */
  start: any;
  end: any;
  googleres: any;
  facebookres: any;
  google: any;
  facebook: any;
  ratio: any;
  rowData: any;
  total: any;
  ingresoMedio: any;
  editType: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  constructor(private sectores: SectoresService,  private router: Router,
    private loginService1: LoginService,  public dialog: MatDialog) { 
      this.columnDefs = [];
    this.editType = 'fullRow';
    this.rowSelection = 'single';
  }

  ngOnInit() {
    this.columnDefs = [];
    this.trigger = false;


    if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') ||  localStorage.getItem("id_token") === null) {
      console.log(localStorage)
      this.router.navigate(['/login']);
    } else {
      this.loginService1.checkToken().subscribe(rescat => {
        console.log(rescat);
        if (rescat.response){
          if (rescat.response === 1){


            
            /* this.getSectores(); */
        }
        else{

          this.router.navigate(['/login']);
        }
      }
        else{
          this.router.navigate(['/login']);
        }

      })
        }
    
  }
/*  getSectores(){
    this.columnDefs = [];
    this.sectores.getInformes2('2021-06-01', '2021-06-15')
    .subscribe(res => {
      this.rowData = res.allInformes;
      console.log(res);
      // this.trigger = true;
         for (let key in res.allInformes[0]) {
          if (key === 'id') {
            this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', rowDrag: true, width: 90, hide: true,  type: 'numericColumn'  };
            console.log('      key:', key);
            this.columnDefs.push(this.dat);
            

        }        else  if (key === 'form_id') {
          this.dat = {headerName: "Formulario", field: key,  filter: 'agTextColumnFilter', editable : true,  sort: "asc" };
          console.log('      key:', key);
          this.columnDefs.push(this.dat);
         

      }
                      else  if (key === 'source') {
          this.dat = {headerName: "Fuente", field: key,  filter: 'agTextColumnFilter', editable : true,  sort: "asc" };
          console.log('      key:', key);
          this.columnDefs.push(this.dat);
         

      }
                      else  if (key === 'value') {
          this.dat = {headerName: "Anuncio", field: key,  filter: 'agTextColumnFilter', editable : true,  sort: "asc" };
          console.log('      key:', key);
          this.columnDefs.push(this.dat);
          

      }
                     else  if (key === 'email') {
          this.dat = {headerName: "Email", field: key,  filter: 'agTextColumnFilter', editable : true,  sort: "asc" };
          console.log('      key:', key);
          this.columnDefs.push(this.dat);
         

      }
                      else  if (key === 'telefono') {
          this.dat = {headerName: "Teléfono", field: key,  filter: 'agTextColumnFilter', editable : true,  sort: "asc" };
          console.log('      key:', key);
          this.columnDefs.push(this.dat);
     

      }
                            else  if (key === 'timestamp') {
          this.dat = {headerName: "Fecha", field: key,  filter: 'agTextColumnFilter', editable : true,  sort: "asc" };
          console.log('      key:', key);
          this.columnDefs.push(this.dat);
          

      }
        else{
          this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', editable : true, hide: true };
          console.log('      key2:', key);
          this.columnDefs.push(this.dat);

        }
          
          
    


      }
      this.trigger = true;
      console.log(this.columnDefs);
     
    
    })
     
  } */
  onSettingsChange(){

    console.log(this.start)
  }

  saveRow(){
    let start = new Date(this.start).toLocaleString()
    let end = new Date(this.end).toLocaleString()

    this.sectores.getInformes2(start, end)
    .subscribe(res => {
      console.log(res)
      this.rowData = res; /* res.allInformes; */
      this.trigger = true;
     this.googleres = (this.google / this.rowData.CountGoogle[0].goocount).toFixed(2)
     this.facebookres = (this.facebook / this.rowData.CountFacebook[0].fbcount).toFixed(2)
     this.ratio = Math.round(this.rowData.allInformes.length / this.rowData.ClientesNuevosGeneral[0].ClientesNuevosGeneral)
     this.total = 0
     this.rowData.IngresoMedio.forEach(item => {
      this.total += parseFloat(item.importe_pagado);
  }); 
  this.ingresoMedio = (this.total / this.rowData.IngresoMedio.length).toFixed(2)
  console.log(this.total)
    })
   

    console.log(this.end)
    console.log(this.google)
    console.log(this.facebook)

/*     this.addbutton = false;
    this.gridApi.stopEditing();
    this.sectores.addSectores(this.datatosend)
    .subscribe(res => {
  if (res.response.status === "0"){
    var selectedData = this.gridApi.getSelectedRows();
    alert(this.datatosend.nombre + "ya existe")
    this.gridApi.updateRowData({ remove: selectedData });
  }
  else{

    this.gridApi.stopEditing();
  }
console.log(res);
    }); */
  }

  getRowData() {
    var rowData = [];
    this.gridApi.forEachNode(function(node) {
      rowData.push(node.data);
    });
    console.log('Row Data:');
    console.log(rowData);
  }

  clearData() {
    this.gridApi.setRowData([]);
  }

  onAddRow() {
    this.gridApi.setFocusedCell(0, 'nombre', null);
    this.addbutton = true;
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0});
    this.datatosend = res.add[0].data;

     
    
    console.log( this.datatosend);
  }
  modclient(){
    console.log(this.datatomod);
    

  }


  onRemoveSelected()
  {
    var selectedData = this.gridApi.getSelectedRows();
    if (selectedData.length !== 0){
      console.log(selectedData);
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = '¿Eliminar ' + selectedData[0].nombre + '?';

      this.confirmDialogRef.afterClosed().subscribe(result => {
          if ( result )
          {
           // var selectedData = this.gridApi.getSelectedRows();

            
            var res = this.gridApi.updateRowData({ remove: selectedData });
            console.log(res.remove[0].data.id);
            this.sectores.remSectores(res.remove[0].data.id)
            .subscribe(res2 => {
        console.log(res2);
            });
            printResult(res);
          }
          this.confirmDialogRef = null;
      });
    }
  }
  onRowClicked(event: any) { console.log('row', event);

this.butonrowclicked = true;
}
onRowValueChanged(event: any){console.log('row', event.data);
this.datatomod = event.data;
if (this.datatomod){
  this.sectores.modSectores(this.datatomod)
  .subscribe(res => {
      
    console.log(res);
        });
// this.modclient();
}
}
showHide(e) {
  // var checked = e.target.checked;
  console.log(e.source.id);
 this.gridColumnApi.setColumnVisible(e.source.id, e.checked);
}


cancelrow(){
  this.gridApi.forEachNode(function(node) {
    if (node.rowIndex === 0) {
      node.setSelected(true);
      var selectedData = node.data;
      console.log(selectedData);
     
  // dataforcancel(selectedData);
    }
  });
  this.cancelrow2();

  }
  cancelrow2()
  {
    var selectedData = this.gridApi.getSelectedRows();
    var res = this.gridApi.updateRowData({ remove: selectedData });
    this.addbutton = false;
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

}
function dataforcancel(selectedData){
  // var res = this.gridApi.updateRowData({ remove: selectedData });
  printResult(selectedData);
  //   printResult(res2);

   }
var newCount = 1;
function createNewRowData() {
  var newData = {
     
  };
  newCount++;
  return newData;
}
function printResult(res) {
  console.log('---------------------------------------');
  if (res.add) {
    res.add.forEach(function(rowNode) {
      console.log('Added Row Node', rowNode);
    });
  }
  if (res.remove) {
    res.remove.forEach(function(rowNode) {

      console.log('Removed Row Node', rowNode.data.id);
    });
  }
  if (res.update) {
    res.update.forEach(function(rowNode) {
      console.log('Updated Row Node', rowNode);
    });
  }
}
