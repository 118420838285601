import { FuseMatSidenavHelperDirective } from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.directive';
import { Component, ViewChild, OnInit, RendererStyleFlags2, OnDestroy, AfterViewInit, Input, Inject } from '@angular/core';
import { Tareas } from './tareas.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ButtonRendererComponent } from './renderer.component';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {DatosclienteComponent} from '../datoscliente/datoscliente.component'
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect, VERSION } from '@angular/material';
import { LoginService } from 'app/main/content/login/login.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { VariableAst } from '@angular/compiler';
declare var jquery: any; declare var $: any;
interface Bank {
  id: string;
  name: string;

}

/* interface Fases {
  value: string;
  viewValue: string;
    service: string;
} */



@Component({
  selector: 'fuse-project-tracking',
  templateUrl: './tareas.component.html',
  styleUrls: ['./tareas.component.scss']
})
export class TareasComponent implements OnInit {
  public loading = false;
  public gridApi;
  private gridColumnApi;
  public gridOptions;
  private autoGroupColumnDef;
  // private rowData: any[];
  trigger: any;
  // private columnDefs;
  // private rowData;
  private rowSelection;
  checked = true;

  butonrowclicked = false;
  addbutton = false;
  dat: any;
  datatosend: any;
  sometrig: any;
  datatomod: any;
  columnDefs: any;
  editrigger =  false;
  newItem: any;
  columnTypes: any;
  rowData: any;
  data3: any;
  data4: any;
  data5: any;
  data6: any;
  table: any;
  htmlid: any;
  selectedRow: any;
  fases: any;
  fase: any;
  usuarios: any;
  meses: any;
  userlevel: any;
  selectedValue: any;
  idupdate: any;
  pressed: any;
  pressed2: any;
  month: any;
  bonos: any;
  total1: any;
  total2: any;
  total3: any;

  total: any;
 manager: any;
 managers: any;
 current_user: any;
 showBottons: Boolean;
 frameworkComponents: any;
  public trig2;
  editType: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>; private components;
  constructor(private tareas: Tareas, private router: Router,
    private loginService1: LoginService, public dialog: MatDialog, private route: ActivatedRoute) {
      this.frameworkComponents = {
        buttonRenderer: ButtonRendererComponent,
      }
    this.editType = 'fullRow';
    this.rowSelection = 'single';
    this.htmlid = "algo";

  }

  ngOnInit() {
   this.month = {}
    var end = new Date().getMonth();
    var end = end - 1
    if (end === 5){
this.month.start = "2021-06-01"
this.month.end = "2021-06-31"
    }
  else  if (end === 6){
      this.month.start = "2021-07-01"
      this.month.end = "2021-07-31"
          }
          else  if (end === 7){
            this.month.start = "2021-08-01"
            this.month.end = "2021-08-31"
                }
                else  if (end === 8){
                  this.month.start = "2021-09-01"
                  this.month.end = "2021-09-31"
                      }
                      else  if (end === 9){
                        this.month.start = "2021-10-01"
                        this.month.end = "2021-10-31"
                            }
                            else  if (end === 10){
                              this.month.start = "2021-11-01"
                              this.month.end = "2021-11-31"
                                  }
                                  else  if (end === 11){
                                    this.month.start = "2021-12-01"
                                    this.month.end = "2021-12-31"
                                        }
                                        else  if (end === 0){
                                          this.month.start = "2022-01-01"
                                          this.month.end = "2022-01-31"
                                              }
                                              else  if (end === 1){
                                                this.month.start = "2022-02-01"
                                                this.month.end = "2022-02-31"
                                                    }
                                                    else  if (end === 2){
                                                      this.month.start = "2022-03-01"
                                                      this.month.end = "2022-03-31"
                                                          }
                                                          else  if (end === 4){
                                                            this.month.start = "2021-05-01"
                                                            this.month.end = "2021-05-31"
                                                                }
   
console.log(end)
  //  this.month = {start: start.toLocaleString(), end: end.toLocaleDateString(), mes: 'Enero'} // new Date(this.fecha).toLocaleString()
    console.log(this.month)
    this.showBottons = true
    this.current_user = localStorage.getItem('user_login')
    console.log(this.current_user)
    this.meses = [
      {start: '2021-01-01', end: '2021-01-31', mes: 'Enero'},
      {start: '2021-02-01', end: '2021-02-31', mes: 'Febrero'},
      {start: '2021-03-01', end: '2021-03-31', mes: 'Marzo'},
      {start: '2021-04-01', end: '2021-04-31', mes: 'Abril'},
      {start: '2021-05-01', end: '2021-05-31', mes: 'Mayo'},
      {start: '2021-06-01', end: '2021-06-31', mes: 'Junio'},
      {start: '2021-07-01', end: '2021-07-31', mes: 'Julio'},
      {start: '2021-08-01', end: '2021-08-31', mes: 'Agosto'},
      {start: '2021-09-01', end: '2021-09-31', mes: 'Septiembre'},
      {start: '2021-10-01', end: '2021-10-31', mes: 'Octubre'},
      {start: '2021-11-01', end: '2021-11-31', mes: 'Noviembre'},
      {start: '2021-12-01', end: '2021-12-31', mes: 'Diciembre'},

    ]
    this.pressed2 = false
    this.pressed = false
this.userlevel = localStorage.getItem('user_level')
    // const id: string = route.snapshot.params.id;
    let id = +this.route.snapshot.params;
    console.log(id)
    
    this.loading = true;
    
    this.tareas.getFases()
    .subscribe(res => {
      this.fases = res.fases
console.log(this.fases)
this.data4 = {};
for (var i = 0; i < res.fases.length; i++) {
  this.data4 [res.fases[i].id] = res.fases[i].fase_name;
}
this.data4['0'] = '-'
console.log(this.data4)
this.tareas.getUsuarios().subscribe(usuarios => {
  console.log(usuarios.members)
  this.usuarios = usuarios.members
  this.data3 = {};
  for (var i = 0; i < usuarios.members.length; i++) {
    this.data3 [usuarios.members[i].id] = usuarios.members[i].nombre;
  }
  this.data3['0'] = '-'
  console.log(this.data3)
  
 /*  this.managers = usuarios.members
  this.manager = usuarios.members[0] */
  this.getAllTareas()
})
   
    });


    this.trigger = false;
    // this.getPersonas();
    this.trig2 = false;
    this.sometrig = false;
    // this.getSectores();
    if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') || localStorage.getItem("id_token") === null) {
      console.log(localStorage)
      this.router.navigate(['/login']);
    } else {
      this.loginService1.checkToken().subscribe(rescat => {
        console.log(rescat);
        if (rescat.response) {
          if (rescat.response === 1) {



            // this.getPersonas();
          }
          else {

            this.router.navigate(['/login']);
          }
        }
        else {
          this.router.navigate(['/login']);
        }

      })
    }






    // this.getProyectos(this.carMappings);
  }
  onBtnClick1(e) {
    window.open("http://lfdi.bitronedev.com/public/" + e.rowData.numero + ".pdf", "_blank");
    console.log(e)
  }


  openDialog(item): void {

    console.log(item)
    /*  var dataformod2 = this.gridApi.getRowNode(rowindex);
     console.log(dataformod2);
     this.proyectos.modProyectos(dataformod2.data)
     .subscribe(res => {
         
       console.log(res);
           }); */


    const dialogRef = this.dialog.open(DatosclienteComponent, {
      width: '650px',
      height: '600',
      data: {
        item: item.rowData
      }

    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }



getAllTareas(){
  let data2 = {0: 'Pendiente', 1: 'Realizado', 2: 'En Proceso'}

  function extractValues(mappings) {
    return Object.keys(mappings);
  }
  function lookupValue(mappings, key) {
    return mappings[key];
  }
  function lookupKey(mappings, name) {
    for (var key in mappings) {
      if (mappings.hasOwnProperty(key)) {
        if (name === mappings[key]) {
          return key;
        }
      }
    }
  }

let data5 = this.data4

  function extractValues2(mappings2) {


    console.log(mappings2)
    return Object.keys(mappings2);
  }
  function lookupValue2(mappings2, key2) {
    console.log(mappings2)
    console.log(key2)

    return mappings2[key2];
  }
  function lookupKey2(mappings2, name2) {
    console.log(mappings2)
    console.log(name2)

    for (var key2 in mappings2) {
      if (mappings2.hasOwnProperty(key2)) {
        if (name2 === mappings2[key2]) {
          return key2;
        }
      }
    }
  }
  this.components = { datePicker: getDatePicker() };
  function getDatePicker() {
    function Datepicker() {}
    Datepicker.prototype.init = function(params) {
      this.eInput = document.createElement("input");
      this.eInput.value = params.value;
      $(this.eInput).datepicker({ dateFormat: "dd/mm/yy" });
    };
    Datepicker.prototype.getGui = function() {
      return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function() {
      this.eInput.focus();
      this.eInput.select();
    };
    Datepicker.prototype.getValue = function() {
      return this.eInput.value;
    };
    Datepicker.prototype.destroy = function() {};
    Datepicker.prototype.isPopup = function() {
      return false;
    };
    return Datepicker;
  }
  this.columnDefs = [ ];
  this.tareas.getTareas()
  .subscribe(res2 => {

    this.rowData = res2.projects;
    if (this.rowData.length === 0){
      this.columnDefs = [
        { headerName: 'Tarea', field: 'Fases' },
            { headerName: 'Servicio', field: 'serviceName' },
            {headerName: "Cliente", field: "Cliente", hide: false, editable: false, cellRenderer: 'buttonRenderer',  cellRendererParams: {
              onClick: this.openDialog.bind(this),
           }, },
            { headerName: 'Proyecto', field: 'proyectoName' },
            { headerName: 'Fecha final', field: 'Fecha' },
    
      ];
    }
  //  this.table = res2.projects
console.log(res2)
this.loading = false;
this.components = {datePicker: getDatePicker()};
for (let key in res2.projects[0]) {

  if (key === 'id') {
    this.dat = {headerName: key, field: key, hide: true };
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    // console.log(this.columnDefs);

}   

else  if (key === 'Fases') {
  this.dat = {headerName: 'Tarea', field: key, hide: false,  editable: false
  };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  
  
  }
else if (key === 'serviceName') {
  console.log(this.rowData)
  this.dat = {headerName: 'Servicio', field: key, hide: false, cellStyle:  this.cellStyling,  editable: false };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}   
else if (key === 'Cliente') {
  this.dat = {headerName: key, field: key, hide: false, editable: false, cellRenderer: 'buttonRenderer',  cellRendererParams: {
    onClick: this.openDialog.bind(this),
 }, };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}  
else if (key === 'proyectoName') {
  this.dat = {headerName: 'Proyecto', field: key, hide: false,  editable: false };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}    

else if (key === 'Fecha') {
  this.dat = {headerName: 'Fecha final', field: key, hide: false,  editable: false };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}    


/* else if (key === 'asignado') {
  this.dat = {headerName: 'Asignado', field: key, hide: false,  editable: false,  cellRenderer: 'buttonRenderer',
  cellRendererParams: {
     onClick: this.openDialog.bind(this),
  },
  
 };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
 

}  */



/*       else  if (key === 'fecha2') {
this.dat = {headerName: 'Fecha', hide: true, field: key, pinned: 'left',   comparator: this.dateComparator, width: 100, editable: true,    cellEditor: "datePicker", 
 };
console.log('      key:', key);
this.columnDefs.push(this.dat);
// console.log(this.columnDefs);

} */


/* else  if (key === 'Fases') {
this.dat = {headerName: key, field: key,   editable: true, width: 300, 
filterParams: {
filterOptions: ['contains', 'notContains'],
textFormatter: function(r) {
if (r == null){ return null}
var r = r.toLowerCase();
// r = r.replace(new RegExp("\\s", 'g'),"");
r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
r = r.replace(new RegExp('æ', 'g'), 'ae');
r = r.replace(new RegExp('ç', 'g'), 'c');
r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
r = r.replace(new RegExp('ñ', 'g'), 'n');
r = r.replace(new RegExp('[òóôõøö]', 'g'), 'o');
r = r.replace(new RegExp('œ', 'g'), 'oe');
r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
return r;
},
debounceMs: 0,
caseSensitive: false,

},



};
console.log('      key:', key);
this.columnDefs.push(this.dat);


} */


/* else  if (key === 'gestor') {
this.dat = {headerName: 'Gestor', field: key,  editable: false,  cellEditor: "agSelectCellEditor", 
cellEditorParams: {values: extractValues(data2) },
valueFormatter: function(params) {
return lookupValue(data2, params.value);
},
valueParser: function(params) {
return lookupKey(data2, params.newValue);
}
};
console.log('      key:', key);
this.columnDefs.push(this.dat);


} */
else if (key === 'status') {
  console.log(this.rowData)
  this.dat = {headerName: 'Estado', field: key, hide: false, cellStyle:  this.cellStyling2,  editable: false,   cellEditor: "agSelectCellEditor", 
  cellEditorParams: {values: extractValues(data2) },
  valueFormatter: function(params) {
  return lookupValue(data2, params.value);
  },
  valueParser: function(params) {
  return lookupKey(data2, params.newValue);
  }
};
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
 //  console.log(this.columnDefs);

} 


else{
  this.dat = {headerName: key, field: key, 
  

  hide: true,
  editable : true};
  console.log('      key2:', key);
  this.columnDefs.push(this.dat);

}
  }



this.trigger = true;
console.log(this.columnDefs);



this.loading = false;
  })
}

cellStyling(params: any){     

  return {'background-color': params.data.color};
}

cellStyling2(params: any){     
if (params.data.status === '0'){
  return {'background-color': 'red' };
}
else if (params.data.status === '2'){
  return {'background-color': 'yellow' };
}
}


terminar(){
  this.tareas.terminar(this.selectedRow)
  .subscribe(res2 => {
console.log(res2)
this.selectedRow = null
console.log(res2)
this.tareas.getTareas()
  .subscribe(res3 => {
    this.rowData = res3.projects;
    this.gridApi.setRowData(this.rowData);
  })
  })
}

empezar(){
  this.tareas.empezar(this.selectedRow)
  .subscribe(res2 => {
    this.selectedRow = null
console.log(res2)
this.tareas.getTareas()
  .subscribe(res3 => {
    this.rowData = res3.projects;
    this.gridApi.setRowData(this.rowData);
  })
  })
}

getAllTareas2(){
  if (this.selectedValue){
    var uid = this.selectedValue.id
    }
    else{
     uid = localStorage.getItem('user_login')
    }
  let data2 = {0: 'Pendiente', 1: 'Realizado', 2: 'En Proceso'}

  function extractValues(mappings) {
    return Object.keys(mappings);
  }
  function lookupValue(mappings, key) {
    return mappings[key];
  }
  function lookupKey(mappings, name) {
    for (var key in mappings) {
      if (mappings.hasOwnProperty(key)) {
        if (name === mappings[key]) {
          return key;
        }
      }
    }
  }
  this.columnDefs = [
    { headerName: 'Fecha final', field: 'Fecha' },
    { headerName: 'Estado', field: 'status', cellStyle:  this.cellStyling2,  editable: false, cellEditor: "agSelectCellEditor", 
    cellEditorParams: {values: extractValues(data2) },
    valueFormatter: function(params) {
    return lookupValue(data2, params.value);
    },
    valueParser: function(params) {
    return lookupKey(data2, params.newValue);
    }},
    { headerName: 'Servicio', field: 'serviceName', cellStyle:  this.cellStyling, },
    { headerName: 'Cliente', field: 'Cliente', cellRenderer: 'buttonRenderer',  cellRendererParams: {
      onClick: this.openDialog.bind(this),
   }, },
    { headerName: 'Proyecto', field: 'proyectoName' },
    { headerName: 'Tarea', field: 'Fases' },
        
       
        
       



  ];
  this.pressed2 = false
this.pressed = false
this.tareas.getTareasById(uid)
  .subscribe(res3 => {
    this.rowData = res3.projects;
    this.gridApi.setRowData(this.rowData);
  })

}

getTareasRealizadas(){
  let data2 = {0: 'Pendiente', 1: 'Realizado', 2: 'En Proceso'}

  function extractValues(mappings) {
    return Object.keys(mappings);
  }
  function lookupValue(mappings, key) {
    return mappings[key];
  }
  function lookupKey(mappings, name) {
    for (var key in mappings) {
      if (mappings.hasOwnProperty(key)) {
        if (name === mappings[key]) {
          return key;
        }
      }
    }
  }
  this.pressed2 = false
  this.pressed = true
if (this.selectedValue){
var uid = this.selectedValue.id
}
else{
 uid = localStorage.getItem('user_login')
}

this.columnDefs = [
  { headerName: 'Fecha final', field: 'Fecha' },
  { headerName: 'Estado', field: 'status', cellStyle:  this.cellStyling2,  editable: false, cellEditor: "agSelectCellEditor", 
  cellEditorParams: {values: extractValues(data2) },
  valueFormatter: function(params) {
  return lookupValue(data2, params.value);
  },
  valueParser: function(params) {
  return lookupKey(data2, params.newValue);
  }},
  { headerName: 'Servicio', field: 'serviceName', cellStyle:  this.cellStyling, },
  { headerName: 'Cliente', field: 'Cliente', cellRenderer: 'buttonRenderer',  cellRendererParams: {
    onClick: this.openDialog.bind(this),
 }, },
  { headerName: 'Proyecto', field: 'proyectoName' },
  { headerName: 'Tarea', field: 'Fases' },
     

];
  this.tareas.getTareasRealizadas(uid)
    .subscribe(res3 => { 
      console.log(res3)
      this.rowData = res3.projects;
      this.gridApi.setRowData(this.rowData);
    })
    
  }

  getInforme(){
    this.pressed2 = true
    if (this.selectedValue){
      var uid = this.selectedValue.id
      }
      else{
       uid = localStorage.getItem('user_login')
      }
    this.tareas.getTareasTerminadas(uid, this.month.start, this.month.end)
    .subscribe(res3 => { 
      this.table = res3.projects
      console.log(res3)
      this.total1 = 0;
      this.total2 = 0;
      this.total3 = 0;

      for (var i = 0; i < this.table.length; i++) {
        if (this.table[i].Importe || this.table[i].precio_gestor) {
          this.total1 += this.table[i].Importe
           if (this.table[i].gestor === parseInt(uid, 10)){
            this.total2 += this.table[i].precio_gestor
           }
           
        }
    }
     
     this.tareas.getBonos(uid, this.month.start, this.month.end)
     .subscribe(res4 => { 
       this.bonos = res4.bonos
       console.log(res4)
       for (var x = 0; x < this.bonos.length; x++) {
        if (this.bonos[x].bono ) {
          this.total3 += this.bonos[x].bono
          console.log(this.total3)
        }
    }
       this.total = this.total1 + this.total2 + this.total3
       console.log(this.total1)
       console.log(this.total2)
       console.log(this.total3)


     })
    })


  }

  changeMonth(){
    console.log(this.month)
    if (this.selectedValue){
      var uid = this.selectedValue.id
      }
      else{
       uid = localStorage.getItem('user_login')
      }
    this.tareas.filterbymonth(uid, this.month.start, this.month.end)
    .subscribe(res3 => { 
      this.table = res3.projects
      console.log(res3)
      let total1 = 0;
      let total2 = 0;
      let total3 = 0
      for (var i = 0; i < this.table.length; i++) {
        if (this.table[i].Importe || this.table[i].precio_gestor) {
            total1 += this.table[i].Importe
           if (this.table[i].gestor === parseInt(uid, 10)){
            total2 += this.table[i].precio_gestor
           }
           
        }
    }
    this.tareas.getBonosByMonth(uid, this.month.start, this.month.end)
    .subscribe(res4 => { 
      this.bonos = res4.bonos
      console.log(res4)
      for (var x = 0; x < this.bonos.length; x++) {
       if (this.bonos[x].bonos ) {
           total3 += this.bonos[x].bonos
          
       }
   }
      this.total = total1 + total2 + total3
    })
  
     
    })
  }


  editCom(e){
    console.log(e)
    this.editrigger = true 
    this.newItem = e.id
    (document.getElementById(e.id) as any).disabled = false;
    
  }

saveCom(e){


(document.getElementById(e.id) as any).disabled  = true;
this.editrigger = false;
this.newItem = null
this.tareas.update(e).subscribe(res => {
console.log(res)

});
}

saveData3(event){
console.log(event)


}


saveData2( event, item){
  console.log(event)
  // if (event.isUserInput && event.source.value !== event.source.viewValue) { 
 // console.log(fase)
  console.log(item)
  console.log(event)

this.tareas.updateFase(event.value.id, item).subscribe(res => {
  console.log(res)

})

  
// }
}



compareFn(c1: any, c2: any): boolean {
 
  return c1 && c2 ? c1.fase_name === c2 : c1 === c2;
}
  dateFormatter(params) {

    // return params.value ? (new Date(params.value)).toLocaleDateString() : '';
    var timestamp = Date.parse(params.value);

    if (isNaN(timestamp) === false) {
      var d = new Date(timestamp).toLocaleDateString();
      console.log(d)
      return d

    }
    console.log("222222222")
    // return timestamp
  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }
  onRowClicked(event: any) { console.log('row', event);
  this.selectedRow = event.data
/*   this.tareas.getFasesbyID(this.selectedRow)
  .subscribe(res => {
   // this.data6 = res.fases
    this.data6 = {};
    for (var i = 0; i < res.fases.length; i++) {
      this.data6 [res.fases[i].id] = res.fases[i].fase_name;
    }
    this.data6['0'] = '-'
    console.log(this.data6)
  }) */

/* this.butonrowclicked = true;
this.clientepresup = event.data.nombre;
this.proyectopresup = event.data.proyecto;
this.CIFpresup = event.data.CIF;
this.telefonopresup = event.data.telefono;
this.emailpresup = event.data.email;
this.commentpresup = event.data.comentarios;
this.fechapresup = event.data.fecha;
this.idtoupdate = event.data.id;
this.empresa = event.data.Empresa; */


}

onCellDoubleClicked(event1: any) { console.log('row', event1); 

}

changeUser(){
  this.showBottons = false
  this.selectedRow = null
console.log(this.selectedValue)

this.tareas.getTareasById(this.selectedValue.id)
  .subscribe(res2 => {
    
    console.log(res2)
    this.rowData = res2.projects;
    this.gridApi.setRowData(this.rowData);
    console.log(this.columnDefs[2])
    this.columnDefs[2] = {headerName: "Cliente", field: "Cliente", hide: false, editable: false, cellRenderer: 'buttonRenderer',  cellRendererParams: {
      onClick: this.openDialog.bind(this),
   }, };  
   console.log(this.columnDefs[2])
/*     var columnDefs = getColumnDefs();
    columnDefs.forEach(function (colDef, index) {
      colDef.headerName = undefined;
    }); 
    this.gridApi.setColumnDefs(columnDefs);*/


    if (this.selectedValue.id === this.current_user){
      this.showBottons = true
      }
      else{
        this.showBottons = false
      }

  })
}

countryCellRenderer(params: any){
  
console.log(params)
return params.value
}


onRowValueChanged(event: any){console.log('row', event.data);
this.datatomod = event.data;
console.log(this.datatomod)
if (this.datatomod){
  this.tareas.modGestor(this.datatomod.gestor, this.datatomod.id)
  .subscribe(res => {

  })

 

      }

}

}



































