import { NgModule } from '@angular/core';
import { MatButtonModule, MatDialogModule } from '@angular/material';

import { FuseConfirmDialog5Component } from '@fuse/components/confirm-dialog5/confirm-dialog5.component';

@NgModule({
    declarations: [
        FuseConfirmDialog5Component
    ],
    imports: [
        MatDialogModule,
        MatButtonModule
    ],
    entryComponents: [
        FuseConfirmDialog5Component
    ],
})
export class FuseConfirmDialog5Module
{
}
