import { Component, ViewChild, OnInit, RendererStyleFlags2, Inject, OnDestroy, AfterViewInit, Input } from '@angular/core';

import { ProjectTracking } from '../project_tracking/project_tracking.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';






import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatSelect, VERSION } from '@angular/material';
import {LoginService} from 'app/main/content/login/login.service';

import * as moment from 'moment';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
declare var jquery: any; declare var $: any;
interface Bank {
  id: string;
  name: string;
}
@Component({
  selector: 'fuse-my-app',
  templateUrl: './asignar2.component.html',
  styleUrls  : ['./asignar2.component.scss'],
})
export class Asignar2Component implements  OnInit  {
  public loading = false;
  public gridApi;
  private gridColumnApi;
 // private rowData: any[];
  trigger: any; 
 // private columnDefs;
  // private rowData;
  private rowSelection;
  checked = true;
  nombre = true;
  apellido = true;
  apellido2 = true;
  idtoupdate: any;
  butonrowclicked = false;
  addbutton = false;
  dat: any;
  item: any;
  sortState: any;
  clientepresup: any;
  empresa: any; 
  proyectopresup: any;
  uid: any;
CIFpresup: any;
telefonopresup: any;
emailpresup: any;
commentpresup: any;
fechapresup: any;
curfecha: any;
  armtest: any;
  datatosend: any;
  datatomod: any;
  columnDefs: any;
  rowData: any;
  editType: any;
  sidebar: any;
  data3: any;




/** control for the selected bank */














  
  constructor( public dialogRef: MatDialogRef<Asignar2Component>, private router: Router, private projects: ProjectTracking,  private loginService1: LoginService,   public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {
 
    this.editType = 'fullRow';
    this.rowSelection = 'single';
    this.sidebar = 'exav';
this.item = data.item
console.log(this.item)
  }
  ngOnInit() {
    // set initial selection
    if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') ||  localStorage.getItem("id_token") === null) {
      console.log(localStorage)
      this.router.navigate(['/login']);
    } else {
      this.loginService1.checkToken().subscribe(rescat => {
        console.log(rescat);
        if (rescat.response){
          if (rescat.response === 1){
            this.projects.getUsuarios().subscribe(usuarios => {
              console.log(usuarios)
              
              this.data3 = {};
              for (var i = 0; i < usuarios.members.length; i++) {
                this.data3 [usuarios.members[i].id] = usuarios.members[i].nombre;
              }
              this.data3['0'] = '-'
              console.log(this.data3)
              this.getClientes2();
              })

            
            
        }
        else{

          this.router.navigate(['/login']);
        }
      }
        else{
          this.router.navigate(['/login']);
        }

      })
        }

    this.trigger = false;
  // this.getClientes2();

  }




/** list of banks filtered by search keyword */








  getClientes2(){

   
}




close() {
  
  this.dialogRef.close()

} 



}
