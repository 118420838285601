import { Component } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';

import { ProfileService } from '../../profile.service';

@Component({
    selector   : 'fuse-profile-about',
    templateUrl: './about.component.html',
    styleUrls  : ['./about.component.scss'],
    animations : fuseAnimations
})
export class FuseProfileAboutComponent
{
    about: any;
    allServices: any;
    trigger: boolean;
    userlevel: any;
    constructor(private profileService: ProfileService)
    {
        this.userlevel = localStorage.getItem('user_level')
        this.trigger = false
        let cliente = localStorage.getItem('user_profile')
        this.profileService.getClientesById(cliente).subscribe(about => {
            this.about = about.clientes[0];
            console.log(this.about)
            this.trigger = true
       
        });
        this.profileService.getServices(cliente).subscribe(services => {
            console.log(services)
            this.allServices = services.allServicebyClient
            })

/* SELECT Proyectos.id FROM Clientes, Proyectos WHERE Clientes.id=5399 AND Clientes.id=Proyectos.cliente */



/* SELECT Servicios.nombre, Pr_members.user_id, Project_tracking.id FROM Pr_members, Project_tracking, Servicios WHERE Project_tracking.project_id=748 AND Project_tracking.id=Pr_members.pr_tracking_id AND Project_tracking.service_id=Servicios.id Group by Servicios.id, Pr_members.user_id */
    }
}
