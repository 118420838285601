import { Component } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';

import { ProfileService } from '../../profile.service';

@Component({
    selector   : 'fuse-profile-timeline',
    templateUrl: './timeline.component.html',
    styleUrls  : ['./timeline.component.scss'],
    animations : fuseAnimations
})
export class FuseProfileTimelineComponent
{
    timeline: any;
trigger: boolean
    constructor(private profileService: ProfileService)
    {
        this.trigger = false
        let cliente = localStorage.getItem('user_profile')
        this.profileService.getServiciosbypresup(cliente).subscribe(timeline => {
            this.timeline = timeline.presupuestosCliente;
            console.log(this.timeline)
            this.trigger = true
        });
    }
}
