
import { Component, ViewChild, OnInit, RendererStyleFlags2, Inject, OnDestroy, AfterViewInit, Input } from '@angular/core';
// import {VentasService} from './ventas.service';
 import {PresupuestosService} from '../../../app/main/content/presupuestos/presupuestos.service';
 import {PagosService} from '../../../app/main/content/pagos/pagos.service';
 import {ProyectosService} from '../../../app/main/content/proyectos/proyectos.service';
 import {ClientesService} from '../../../app/main/content/clientes/clientes.service';

 import {PagosrService} from '../../../app/main/content/pagos_r/pagos_r.service';
 import {VentasService} from '../../../app/main/content/ventas/ventas.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseConfirmDialog2Component } from '@fuse/components/confirm-dialog2/confirm-dialog2.component';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatSelect, VERSION } from '@angular/material';
import {LoginService} from 'app/main/content/login/login.service';
import * as moment from 'moment';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { values } from 'lodash';
declare var jquery: any; declare var $: any;
interface Bank {
  id: string;
  name: string;
}
interface Bank2 {
  id: string;
  name: string;
}
interface Bank3 {
  id: string;
  name: string;
}
interface Pais {
  value: string;
  viewValue: string;
}
@Component({
    selector   : 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog4.component.html',
    styleUrls  : ['./confirm-dialog4.component.scss']
})
export class FuseConfirmDialog4Component implements OnInit, OnDestroy, AfterViewInit
{
     
    armtest: any;
    username: any;
    resclientbyid: any;
    // proyectos: any;
    servicios: any;
    choosed: any;
    Clientes2: any ;
    selectedValue: any ;
    selectedValue2: any ;
    importe: any ;
    concepto: any ;
    fecha: any ;
    venta_id: any;
    curfecha: any; 
    datatosend: any;
    choosedcliente: any;
    clientename: any;
    triger: any;
    proyecto: any;
    proyecto2: any;
    datosnuevos: any;
    comments: any;
    clientedata: any;
    clientData: any;
    remitentes: any;
    remitente2: any;
    todosproyectos: any;
    choosedProject: any;
    choosedService: any;
    choosedServiceID: any;
    paisctrl: any;
    clientexsitente: any;
    confirmDialogRef2: MatDialogRef<FuseConfirmDialog2Component>;
    paises: Pais[] = [
      {value: 'spain', viewValue: 'España'},
      {value: 'otro', viewValue: 'Resto del Mundo'},
     
    ];
    // @Input() sideBar: PagosrComponent; 
    
    public bankCtrl: FormControl = new FormControl();
    private clientes: any; 
      
      /** control for the MatSelect filter keyword */
    public bankFilterCtrl: FormControl = new FormControl();
  
    /** list of clientes */
  
    
    
    public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
    
    
    
    @ViewChild('singleSelect') singleSelect: MatSelect;
    




    public bankCtrl2: FormControl = new FormControl();
    public bankCtrl3: FormControl = new FormControl();

  public bankFilterCtrl2: FormControl = new FormControl();
  public bankFilterCtrl3: FormControl = new FormControl();

  public filteredBanks2: ReplaySubject<Bank2[]> = new ReplaySubject<Bank2[]>(1);
  public filteredBanks3: ReplaySubject<Bank3[]> = new ReplaySubject<Bank3[]>(1);

  
    /** Subject that emits when the component has been destroyed. */
    private _onDestroy = new Subject<void>();
    
  
    ngOnInit() {
      this.fecha = new Date()
    /*   this.fecha = new Date().toLocaleString([], {dateStyle: 'short'})
      console.log(this.fecha) */
      this.remitentes = [
        {id: 0, nombre: 'LFDI', value: 1},
        {id: 1, nombre: 'TIE', value: 2}
      ]
      this.choosedProject = []
      this.triger = 0;
      this.pagos_r.getClientes()
      .subscribe(res => {
        
  this.clientes = res.clientes2;

  console.log(this.clientes)
  if (this.resclientbyid !== undefined){
    var foo = this.clientes.length;
    console.log(foo)
    this.clientes.push(this.resclientbyid)
    console.log(this.clientes)
    this.bankCtrl.setValue(this.clientes[foo]);
    this.proyectos.getProyectosById(this.resclientbyid.id)
    .subscribe(res2 => {
      console.log(res)
      
this.todosproyectos = res2.proyectos;
this.todosproyectos.push({nombre: 'Nuevo proyecto'})
console.log(this.todosproyectos)



    })

  }
 
  else{
  
  this.bankCtrl.setValue(this.clientes[10]);
}
  console.log(this.clientes)
      // load the initial bank list
      this.filteredBanks.next(this.clientes.slice());
      this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
      })
    
  
      // listen for search field value changes
  
      }
  
      getlistCliente(){
  
  
      };
      ngAfterViewInit() {
        this.setInitialValue();
        this.setInitialValue2();
        this.setInitialValue3();

      }
      
      ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
      }
      
      private setInitialValue() {
        this.filteredBanks
          .pipe(take(1), takeUntil(this._onDestroy))
          .subscribe(() => {
            // setting the compareWith property to a comparison function
            // triggers initializing the selection according to the initial value of
            // the form control (i.e. _initializeSelection())
            // this needs to be done after the filteredBanks are loaded initially
            // and after the mat-option elements are available
            this.singleSelect.compareWith = (a: Bank, b: Bank) => a.id === b.id;
           
          });
      }
      private filterBanks() {
        if (!this.clientes) {
          return;
        }
        // get the search keyword
        let search = this.bankFilterCtrl.value;
        if (!search) {
          this.filteredBanks.next(this.clientes.slice());
          return;
        } else {
          search = search.toLowerCase();
        }
        // filter the clientes
        this.filteredBanks.next(
          this.clientes.filter(clientes => clientes.nombre.toLowerCase().indexOf(search) > -1)
        );

       
      }

    
      private setInitialValue2() {
        this.filteredBanks2
          .pipe(take(1), takeUntil(this._onDestroy))
          .subscribe(() => {
            // setting the compareWith property to a comparison function
            // triggers initializing the selection according to the initial value of
            // the form control (i.e. _initializeSelection())
            // this needs to be done after the filteredBanks are loaded initially
            // and after the mat-option elements are available
            this.singleSelect.compareWith = (a: Bank2, b: Bank2) => a.id === b.id;
           
          });
      }
      private setInitialValue3() {
        this.filteredBanks3
          .pipe(take(1), takeUntil(this._onDestroy))
          .subscribe(() => {
            // setting the compareWith property to a comparison function
            // triggers initializing the selection according to the initial value of
            // the form control (i.e. _initializeSelection())
            // this needs to be done after the filteredBanks are loaded initially
            // and after the mat-option elements are available
            this.singleSelect.compareWith = (a: Bank3, b: Bank3) => a.id === b.id;
           
          });
      }
      private filterBanks2() {
        if (!this.servicios) {
          return;
        }
        // get the search keyword
        let search = this.bankFilterCtrl2.value;
        if (!search) {
          this.filteredBanks2.next(this.servicios.slice());
          return;
        } else {
          search = search.toLowerCase();
        }
        // filter the clientes
        this.filteredBanks2.next(
          this.servicios.filter(servicios => servicios.nombre.toLowerCase().indexOf(search) > -1)
        );
      }
      private filterBanks3() {
        if (!this.servicios) {
          return;
        }
        // get the search keyword
        let search = this.bankFilterCtrl3.value;
        if (!search) {
          this.filteredBanks3.next(this.servicios.slice());
          return;
        } else {
          search = search.toLowerCase();
        }
        // filter the clientes
        this.filteredBanks3.next(
          this.servicios.filter(servicios => servicios.nombre.toLowerCase().indexOf(search) > -1)
        );
      }
  
    /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
      constructor(public dialogRef: MatDialogRef<FuseConfirmDialog4Component>, private clientesbyid: ClientesService,  public dialog: MatDialog, private presupuestos: PresupuestosService, private proyectos: ProyectosService,  private pagos_r: PagosrService, private pagos: PagosService, private ventas: VentasService,  @Inject(MAT_DIALOG_DATA) public data: any) {
        this.clientexsitente = data.cliente
        this.datosnuevos = data.datosnuevos
        console.log(  this.clientexsitente)
        console.log(  this.datosnuevos)

        if (data.cliente !== undefined ){
          this.clientesbyid.getClientesById(data.cliente.id)
          .subscribe(res => {
            console.log(res)
            this.resclientbyid = res.clientes[0]
            console.log(this.resclientbyid)
            this.proyectos.getProyectosById(res.clientes[0].id)
        .subscribe(res2 => {
          console.log(res2)
        
    this.todosproyectos = res2.proyectos;
    this.todosproyectos.push({nombre: 'Nuevo Proyecto'})
    this.proyecto2 = this.datosnuevos.proyecto
    this.comments = this.datosnuevos.comentarios
    this.fecha = this.datosnuevos.fecha
    console.log(this.todosproyectos)
this.newproject('Nuevo Proyecto')


        })
        })
      }
      }
    
      
      saveData(bankCtrl){
/*         if (bankCtrl.value.CIF === undefined || bankCtrl.value.CIF === "undefined"){
          alert("Faltan datos de CIF");
          this.triger = 1;
        }
        else if (bankCtrl.value.email === undefined || bankCtrl.value.email === "undefined"){
  
          alert("Faltan datos de email");
          this.triger = 1;
        }
        else if (bankCtrl.value.direccion === undefined || bankCtrl.value.direccion === "undefined"){
  
          alert("Faltan datos de direccion");
          this.triger = 1;
        }
        else if (bankCtrl.value.codigo_postal === undefined || bankCtrl.value.codigo_postal === "undefined"){
  
          alert("Faltan datos de codigo postal");
          this.triger = 1;
        }
        else if (bankCtrl.value.ciudad === undefined || bankCtrl.value.ciudad === "undefined"){
  
          alert("Faltan datos de ciudad");
          this.triger = 1;
        } 
  else{*/

    if (this.resclientbyid !== undefined){
      this.choosedcliente = this.resclientbyid.id
    }

else{
    this.triger = 0;
        console.log(bankCtrl.value);
        this.clientData = bankCtrl.value;
        this.clientename = bankCtrl.value.nombre;
        this.choosedcliente = bankCtrl.value.id;
          this.clientedata = bankCtrl.value;
        // this.dialogRef.close({id_cliente: bankCtrl.value.id, nombre: bankCtrl.value.nombre});
}
        this.proyectos.getProyectosById(this.choosedcliente)
        .subscribe(res => {
          console.log(res)
          
    this.todosproyectos = res.proyectos;
  
      this.todosproyectos.push({nombre: 'Nuevo proyecto'})
  
    
    console.log(this.todosproyectos)



        })



/*         this.pagos_r.getProyectosForR(bankCtrl.value.id)
        .subscribe(res => {
          
    this.proyectos = res.proyectos;
    console.log(this.proyectos)
  
        }) */
      }


      newproject(value){
        console.log(value)
        if (value.nombre === "Nuevo proyecto"){
          
          this.triger = 1;
          this.venta_id = ""
        }
        else{
          this.triger = 0;
          this.venta_id = value.ventas_id
        }


      }

      saveData1(bankCtrl2){


        console.log(bankCtrl2.value);
this.proyecto = bankCtrl2.value.servicio
this.choosed = bankCtrl2.value
 this.bankCtrl3.setValue(bankCtrl2.value);
 this.filteredBanks3.next(bankCtrl2.value);
 this.bankFilterCtrl3.valueChanges
 .pipe(takeUntil(this._onDestroy))
 .subscribe(() => {
   this.filterBanks3();

 });

 this.choosedService = bankCtrl2.value.servicio
this.choosedServiceID = bankCtrl2.value.id_servicio   
console.log(this.choosedService)
console.log(this.choosedServiceID)




      }
      /*   } */
        saveData2(value){

/*           this.pagos_r.getServicios(value.id)
          .subscribe(res => {
      this.servicios = res.servicios;
      console.log(this.servicios)
    
          })
  
          console.log(value); */
        }
  


        verpresu(){
  
            window.open("http://lfdi.bitronedev.com/public/" + this.choosed.numero + ".pdf", "_blank");

        }
       
  
        saveDataTotal(fecha, concepto, importe, selectedValue2, selectedValue, paisctrl, remitente2){
          console.log(this.choosedcliente);
          console.log(fecha);
          console.log(importe);
          console.log(concepto);
          console.log(this.proyecto);
     
/*           if (this.choosedcliente === undefined ||  fecha === undefined  || concepto === undefined || importe === undefined ){
  alert("rellene todos los campos")
          }
  
  
          else{ */
  var datefecha = new Date(fecha._d); 
  var datefecha2 = datefecha.toLocaleString();
          console.log(datefecha2);
          console.log(concepto);
          console.log(importe);
          console.log(selectedValue2);
          console.log(selectedValue);
          console.log(this.clientedata);
          console.log(this.choosedcliente);
var totalapagar = 0

          this.pagos.addPagos(this.clientename, this.proyecto, this.choosedServiceID,  totalapagar, importe, datefecha2)
          .subscribe(res => {
            console.log(res);
            this.ventas.createInvoice(this.clientename, this.clientData.CIF, fecha, this.choosed.proyecto, this.clientData.telefono, this.clientData.email, this.clientData.direccion, this.clientData.codigo_postal, this.clientData.ciudad, this.clientData.empresa, concepto,   this.choosedServiceID, this.choosedService, importe, paisctrl, remitente2.id).subscribe(res4 => {
              console.log(res4);
            })


   /*        this.pagos_r.addPagos(this.choosedcliente, datefecha2, concepto, importe, selectedValue2.id, selectedValue.id)
        .subscribe(res => {
          console.log(res); */
  
  
  
          if (res.response.status ){
 /*            this.pagos_r.sendEmail(this.clientedata, datefecha2, concepto, importe, selectedValue2.nombre, selectedValue.nombre)
            .subscribe(res2 => {
              console.log(res2);
              var fullDate = datefecha;
              var twoDigitMonth = fullDate.getMonth() + "";
              if (twoDigitMonth.length === 1){
                  twoDigitMonth = "0" + twoDigitMonth;
              }
              var twoDigitDate = fullDate.getDate() + "";
              if (twoDigitDate.length === 1){
                  twoDigitDate = "0" + twoDigitDate;
              }
              
              
              
              var currentDate = fullDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate; console.log(currentDate);
              this.dialogRef.close({cliente: this.clientename, proyecto: selectedValue.nombre, servicio: selectedValue2.nombre, importe: importe, concepto: concepto, fecha: currentDate });
            }) */
            var fullDate = datefecha;
            var twoDigitMonth = fullDate.getMonth() + "";
            if (twoDigitMonth.length === 1){
                twoDigitMonth = "0" + twoDigitMonth;
            }
            var twoDigitDate = fullDate.getDate() + "";
            if (twoDigitDate.length === 1){
                twoDigitDate = "0" + twoDigitDate;
            }
            var currentDate = fullDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate; console.log(currentDate);
           // this.dialogRef.close({cliente: this.clientename, proyecto: this.proyecto, servicio: this.choosed.servicio, importe: importe, concepto: concepto, fecha: currentDate });
          }
  
        })
      //  }
      }



      saveRow(){  


        
        this.curfecha = new Date(this.fecha).toLocaleString()
        console.log(this.curfecha)

        if (this.resclientbyid !== undefined){
          this.datatosend = {
            comentarios: this.comments,
            fecha: this.curfecha,
            proyecto: this.proyecto.nombre,
            nombre:   this.resclientbyid.nombre,
            email: this.resclientbyid.email,
            telefono: this.resclientbyid.telefono,
            id: this.resclientbyid.id,
            Empresa: this.resclientbyid.Empresa
          }
        }
        else{
        this.datatosend = {
          comentarios: this.comments,
          fecha: this.curfecha,
          proyecto: this.proyecto.nombre,
          nombre:   this.clientData.nombre,
          email: this.clientData.email,
          telefono: this.clientData.telefono,
          id: this.clientData.id,
          Empresa: this.clientData.empresa


        }
      }
        if (this.triger){
          this.datatosend.proyecto = this.proyecto2
        }
        else{

          this.datatosend.venta_id = this.venta_id
        }
        
        console.log(this.datatosend)
    
     

         if ( this.datatosend.proyecto !== "" && this.datatosend.comentarios !== "" &&  this.datatosend.fecha !== "undefined"){

          if (this.resclientbyid !== undefined){
            console.log(this.resclientbyid, this.datatosend)
            this.ventas.addVentasExistente(this.datatosend, this.resclientbyid.id)
            .subscribe(res2 => {
              console.log(res2)
              this.dialogRef.close(true)
            // window.location.reload()
            })
          }

else{



         this.ventas.addVentas(this.datatosend)
         .subscribe(res => {
         //  if (res){
          console.log(res);
             console.log(this.datatosend)
             if (res.response.status === "0"){
               console.log("hesav")
               this.ventas.addVentasExistente(this.datatosend, res.response.id[0].id)
                     .subscribe(res2 => {
                       console.log(res2)
                       this.dialogRef.close(true)
                     })
/*                this.confirmDialogRef2 = this.dialog.open(FuseConfirmDialog2Component, {
                   disableClose: false 
               });
         
               this.confirmDialogRef2.componentInstance.confirmMessage = 'Ya existe el cliente con el mimso email o telefono.' + '  Nombre: ' + res.response[0].nombre + ', telefono: ' + res.response[0].telefono + ', email: ' + res.response[0].email + '?';
         
               this.confirmDialogRef2.afterClosed().subscribe(result => {
                 console.log(result)
                   if ( result )
                   {
     
     
                     this.ventas.addVentasExistente(this.datatosend, 'NULL')
                     .subscribe(res2 => {
                       console.log(res2)
                       this.dialogRef.close(true)
                     //  window.location.reload()
                     })
                    
                   }
                   else {
                     this.ventas.addVentasExistente(this.datatosend, res.response[0].id)
                     .subscribe(res2 => {
                       console.log(res2)
                       this.dialogRef.close(true)
                    //  window.location.reload()
                     })
     
                   }
         
                   this.confirmDialogRef2 = null;
                 
               }); */
             }
             else{
             // this.dialogRef.close(true)
              // window.location.reload()
             }
         //  }
           console.log("stexem")
           
          // this.cancelrow2();
     console.log(res);
     
     
     
     // window.location.reload() 
     /* var params = {
       force: true
     }; 
      this.gridApi.refreshCells(params); */
      // this.router.navigate(['/ventas'])
     // this.sortByAthleteAsc()
         });
       }
      }
       else { 

         
         alert ("Debes rellenar todos los campos ");
     
       }
     
    
     }








      save(){
       // this.dialogRef.close(true)
      }
  
  }
