import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformesComponent } from './informes.component';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { SectoresService } from './informes.service';
import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import { MatButtonModule, MatDatepickerModule, MatFormFieldModule, MatCheckboxModule, MatIconModule, MatInputModule, MatSlideToggleModule, MatToolbarModule, MatSelectModule } from '@angular/material';

import { FuseConfirmDialogModule } from '@fuse/components';
const routes = [
  {
      path     : 'informes',
      component: InformesComponent
  }
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatIconModule,
    TranslateModule,
    AgGridModule.withComponents([]),
    FuseSharedModule,
    HttpClientModule, HttpModule,
    FuseConfirmDialogModule
  ],
  exports     : [
    InformesComponent
],
  declarations: [InformesComponent],
  providers: [SectoresService]
})
export class InformesModule { }
