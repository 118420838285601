export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'SAMPLE'        : {
                'TITLE': 'Sample',
                'BADGE': '25'
            },
            'Contactos2'   : 'Contactos',
            'Ventas' : 'Ventas',
            'CLIENTES'    : 'Clientes',
            'CLIENTESPEND'    : 'Clientes pend',
            'PRESUPUESTOS'    : 'Presupuestos',
            'PROYECTOS'   : 'Proyectos',
            'PATENTES'   : 'Patentes',
            'ServiceEnLine'   : 'ServiceEnLine',
            'SECTORES'      : 'Sectores',
            'SERVICIOS'   : 'Servicios',
            'RRHH'   : 'RRHH',
            'temp_pro' : 'Estado-Proyectos',
            'temp_pro_history' : 'Estado-Proyectos_Log',
            'PAGOS'        : 'Resumen de pagos',
            'FACTURAS'        : 'Facturas',
            'PAGOSAPLAZADOS'        : 'PAGOS APLAZADOS',
            'TAREAS'        : 'TAREAS',

            'PAGOS2'        : 'Pagos'
        }
    }
};
