import { Component, Input, ViewEncapsulation } from '@angular/core';
import {LoginService} from 'app/main/content/login/login.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
@Component({
    selector     : 'fuse-navigation',
    templateUrl  : './navigation.component.html',
    styleUrls    : ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseNavigationComponent
{
    @Input() layout = 'vertical';
    @Input() navigation: any;
    userlevel: any;

    constructor( private loginService1: LoginService, private router: Router)
    {
      console.log(this.navigation)
        this.loginService1.checkToken().subscribe(rescat => {
            console.log(rescat);
            if (rescat.response){
              if (rescat.response === 1){
    
this.userlevel = localStorage.getItem('user_level')
                console.log(this.userlevel)
                
            }
            else{
    
              this.router.navigate(['/login']);
            }
          }
            else{
              this.router.navigate(['/login']);
            }
    
          })

    }
}
