import { Component, ViewChild, OnInit, RendererStyleFlags2, Inject, OnDestroy, AfterViewInit, Input } from '@angular/core';

import { ProjectTracking } from '../project_tracking/project_tracking.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseConfirmDialog2Component } from '@fuse/components/confirm-dialog2/confirm-dialog2.component';





import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatSelect, VERSION } from '@angular/material';
import {LoginService} from 'app/main/content/login/login.service';
import {Asignar2Component} from '../asignar2/asignar2.component'
import * as moment from 'moment';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
declare var jquery: any; declare var $: any;
interface Bank {
  id: string;
  name: string;
}
@Component({
  selector: 'fuse-my-app',
  templateUrl: './asignar.component.html',
  styleUrls  : ['./asignar.component.scss'],
})
export class AsignarComponent implements  OnInit  {
  public loading = false;
  public gridApi;
  private gridColumnApi;
 // private rowData: any[];
  trigger: any; 
 // private columnDefs;
  // private rowData;
  private rowSelection;
  checked = true;
  nombre = true;
  apellido = true;
  apellido2 = true;
  idtoupdate: any;
  butonrowclicked = false;
  addbutton = false;
  dat: any;
  item: any;
  sortState: any;
  clientepresup: any;
  empresa: any; 
  proyectopresup: any;
  uid: any;
CIFpresup: any;
telefonopresup: any;
emailpresup: any;
commentpresup: any;
fechapresup: any;
curfecha: any;
  armtest: any;
  datatosend: any;
  datatomod: any;
  columnDefs: any;
  rowData: any;
  editType: any;
  sidebar: any;
  data3: any;



  private components;


/** control for the selected bank */














  
  constructor( public dialogRef: MatDialogRef<AsignarComponent>, private router: Router, private projects: ProjectTracking,  private loginService1: LoginService,   public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {
 
    this.editType = 'fullRow';
    this.rowSelection = 'single';
    this.sidebar = 'exav';
this.item = data.item
console.log(this.item)
  }
  ngOnInit() {
    // set initial selection
    if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') ||  localStorage.getItem("id_token") === null) {
      console.log(localStorage)
      this.router.navigate(['/login']);
    } else {
      this.loginService1.checkToken().subscribe(rescat => {
        console.log(rescat);
        if (rescat.response){
          if (rescat.response === 1){
            this.projects.getUsuarios().subscribe(usuarios => {
              console.log(usuarios)
              
              this.data3 = {};
              for (var i = 0; i < usuarios.members.length; i++) {
                this.data3 [usuarios.members[i].id] = usuarios.members[i].nombre;
              }
              this.data3['0'] = '-'
              console.log(this.data3)
              this.getClientes2();
              })

            
            
        }
        else{

          this.router.navigate(['/login']);
        }
      }
        else{
          this.router.navigate(['/login']);
        }

      })
        }

    this.trigger = false;
  // this.getClientes2();

  }




/** list of banks filtered by search keyword */






openDialog(item): void {

  console.log(item)
  /*  var dataformod2 = this.gridApi.getRowNode(rowindex);
   console.log(dataformod2);
   this.proyectos.modProyectos(dataformod2.data)
   .subscribe(res => {
       
     console.log(res);
         }); */


  const dialogRef = this.dialog.open(  Asignar2Component, {
    width: '650px',
    height: '600',
    data: {
      item: item.rowData
    }

  });

  dialogRef.afterClosed().subscribe(result => {
    this.projects.getAllProject()
    .subscribe(res2 => {
      this.rowData = res2.projects;
      this.gridApi.setRowData(this.rowData);

    })
  });
}

  getClientes2(){

    let data3 = {0: 'Pendiente', 1: 'Realizado', 2: 'En Proceso'}

    function extractValues2(mappings) {
      return Object.keys(mappings);
    }
    function lookupValue2(mappings, key) {
      return mappings[key];
    }
    function lookupKey2(mappings, name) {
      for (var key in mappings) {
        if (mappings.hasOwnProperty(key)) {
          if (name === mappings[key]) {
            return key;
          }
        }
      }
    }



    let data2 = this.data3

    function extractValues(mappings) {
      return Object.keys(mappings);
    }
    function lookupValue(mappings, key) {
      return mappings[key];
    }
    function lookupKey(mappings, name) {
      for (var key in mappings) {
        if (mappings.hasOwnProperty(key)) {
          if (name === mappings[key]) {
            return key;
          }
        }
      }
    }

    this.columnDefs = [];

    this.components = { datePicker: getDatePicker() };
    function getDatePicker() {
      function Datepicker() {}
      Datepicker.prototype.init = function(params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        $(this.eInput).datepicker({ dateFormat: "dd/mm/yy" });
      };
      Datepicker.prototype.getGui = function() {
        return this.eInput;
      };
      Datepicker.prototype.afterGuiAttached = function() {
        this.eInput.focus();
        this.eInput.select();
      };
      Datepicker.prototype.getValue = function() {
        return this.eInput.value;
      };
      Datepicker.prototype.destroy = function() {};
      Datepicker.prototype.isPopup = function() {
        return false;
      };
      return Datepicker;
    }






    this.loading = true;
console.log(this.item.id)
    this.projects.getFases2(this.item.id) /*  */
    .subscribe(res => {
      this.rowData = res.fases;
      console.log(res.fases);
          
this.components = {datePicker: getDatePicker()};



        for (let key in res.fases[0]) {
          if (key === 'id') {
            this.dat = {headerName: key, field: key, hide: true };
            console.log('      key:', key);
            this.columnDefs.push(this.dat);
            // console.log(this.columnDefs);

        }   
        else if (key === 'pr_tracking_id') {
          this.dat = {headerName: key, field: key, hide: true };
          console.log('      key:', key);
          this.columnDefs.push(this.dat);
          // console.log(this.columnDefs);

      }      
/*       else  if (key === 'fecha2') {
        this.dat = {headerName: 'Fecha', hide: true, field: key, pinned: 'left',   comparator: this.dateComparator, width: 100, editable: true,    cellEditor: "datePicker", 
         };
        console.log('      key:', key);
        this.columnDefs.push(this.dat);
        // console.log(this.columnDefs);

    } */

 
  else  if (key === 'Fases') {
    this.dat = {headerName: key, field: key,   editable: true, width: 300, 
    filterParams: {
      filterOptions: ['contains', 'notContains'],
      textFormatter: function(r) {
        if (r == null){ return null}
        var r = r.toLowerCase();
        // r = r.replace(new RegExp("\\s", 'g'),"");
        r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
        r = r.replace(new RegExp('æ', 'g'), 'ae');
        r = r.replace(new RegExp('ç', 'g'), 'c');
        r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
        r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
        r = r.replace(new RegExp('ñ', 'g'), 'n');
        r = r.replace(new RegExp('[òóôõøö]', 'g'), 'o');
        r = r.replace(new RegExp('œ', 'g'), 'oe');
        r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
        r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
        return r;
      },
      debounceMs: 0,
      caseSensitive: false,
     // suppressAndOrCondition: true,
    },
  
  
  
  };
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    // console.log(this.columnDefs);

}

else  if (key === 'Equipo') {
  this.dat = {headerName: key, field: key,  editable: true,  /* cellEditor: "agSelectCellEditor", width: 295,
  cellEditorParams: {values: extractValues(data2) },
  valueFormatter: function(params) {
    return lookupValue(data2, params.value);
  },
  valueParser: function(params) {
    return lookupKey(data2, params.newValue);
  } */cellRenderer: 'buttonRenderer', 
  cellRendererParams: {
    onClick: this.openDialog.bind(this),
 },
};
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}

else if (key === 'status') {
  this.dat = {headerName: 'Estado', field: key, hide: false, editable: true, cellEditor: "agSelectCellEditor",
  cellEditorParams: {values: extractValues2(data3) },
  valueFormatter: function(params) {
    return lookupValue2(data3, params.value);
  },
  valueParser: function(params) {
    return lookupKey2(data3, params.newValue);
  } };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}  

        else{
          this.dat = {headerName: key, field: key, 
          

          
          editable : true, hide: true };
          console.log('      key2:', key);
          this.columnDefs.push(this.dat);

        }
          }
          this.trigger = true;
          console.log(this.columnDefs);
         
    

          this.loading = false;

      });
  }
/*  
  setPriceOnToyota() {   
    var rowNode = this.gridApi.getRowNode("aa");
    var newPrice = Math.floor(Math.random() * 100000);
    rowNode.setDataValue("price", newPrice);
  } */
  dateFormatter(params) {
  
   // return params.value ? (new Date(params.value)).toLocaleDateString() : '';
   var timestamp = Date.parse(params.value);

if (isNaN(timestamp) === false) {
  var d = new Date(timestamp).toLocaleDateString();
  console.log(d)
  return d
 
}
console.log("222222222")
// return timestamp
  }

  sortwaschanged(event: any){console.log('sort', event);

  /* this.sortState = this.gridApi.getSortModel();
   console.log(this.sortState[0].sort) */
  }

  sortByAthleteAsc() {
    
    var sort = [
        {colId: 'fecha', sort: 'asc'}
    ];
    this.gridApi.setSortModel(sort);
}

  saveRow(){  
   console.log(this.datatosend.fecha)
   this.gridApi.stopEditing();
    console.log(this.datatosend)
    if (this.datatosend.nombre){
    if (this.datatosend.nombre !== "" && this.datatosend.telefono !== "" && this.datatosend.email !== "" &&  this.datatosend.fecha !== "undefined" && this.datatosend.Empresa !== "undefined"){
    this.projects.getFases2(this.item.service_id)
    .subscribe(res => {
      if (res){
        // console.log(res.response[0].nombre)
        this.addbutton = false;

      }
      console.log("stexem")
      
     // this.cancelrow2();
console.log(res);



// window.location.reload() 
/* var params = {
  force: true
}; 
 this.gridApi.refreshCells(params); */
 // this.router.navigate(['/ventas'])
// this.sortByAthleteAsc()
    });
  }
  else { 
this.onBtStartEditing();
   // this.addbutton = false;
/*     this.gridApi.forEachNode(function(node) {
      if (node.rowIndex === 0) {
        node.setSelected(true);
        var selectedData = node.data;
        console.log(selectedData);
        
  
      }
    }); */
    
    alert ("Debes rellenar los campos de fecha, nombre, telefono y email");

  }
}
else{
  alert ("Debes rellenar los campos de fecha, nombre, telefono y email");
}
}


  getRowData() {
    var rowData = [];
    this.gridApi.forEachNode(function(node) {
      rowData.push(node.data);
    });
    console.log('Row Data:');
    console.log(rowData);
  }

  clearData() {
    this.gridApi.setRowData([]);
  }








 


  addItems() {
    var newItems = [createNewRowData(), createNewRowData(), createNewRowData()];
    var res = this.gridApi.updateRowData({ add: newItems });
    printResult(res);
  }

  addItemsAtIndex() {
    var newItems = [createNewRowData(), createNewRowData(), createNewRowData()];
    var res = this.gridApi.updateRowData({
      add: newItems,
      addIndex: 2
    });
    printResult(res);
  }

  updateItems() {
    var itemsToUpdate = [];
    this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
      if (index >= 5) {
        return;
      }
      var data = rowNode.data;
      data.price = Math.floor(Math.random() * 20000 + 20000);
      itemsToUpdate.push(data);
    });
    var res = this.gridApi.updateRowData({ update: itemsToUpdate });
    printResult(res);
  }

  onInsertRowAt2() {
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({
      add: [newItem],
      addIndex: 0
    });
    printResult(res);
  }
  onRemoveSelected()
  {
    var selectedData = this.gridApi.getSelectedRows();

  }
  onCellDoubleClicked(event1: any) { console.log('row', event1); 


}

onBtStartEditing() { 
  this.gridApi.setFocusedCell(0, 'fecha', 0)
 

  this.gridApi.startEditingCell({
      rowIndex: 0,
      colKey: 'fecha',
      // set to 'top', 'bottom' or undefined
     
  });
}

testfunc(){

 var armpordz = this.gridApi.getRowNode(0);
 console.log(armpordz);
}


  onRowClicked(event: any) { console.log('row', event);
  
/* this.butonrowclicked = true;
this.clientepresup = event.data.nombre;
this.proyectopresup = event.data.proyecto;
this.CIFpresup = event.data.CIF;
this.telefonopresup = event.data.telefono;
this.emailpresup = event.data.email;
this.commentpresup = event.data.comentarios;
this.fechapresup = event.data.fecha;
this.idtoupdate = event.data.id;
this.empresa = event.data.Empresa;
 */

}


onRowValueChanged(event: any){console.log('row', event.data);
this.datatomod = event.data;
if (this.datatomod){
  this.projects.asignar(this.datatomod)
  .subscribe(res => {
console.log(res)
  })
}
}
close() {
  this.gridApi.stopEditing();
  this.dialogRef.close()

} 




  showHide(e) {
    // var checked = e.target.checked;
    console.log(e.source.id);
   this.gridColumnApi.setColumnVisible(e.source.id, e.checked);
  }


  cancelrow(){
    this.addbutton = false;
    this.gridApi.forEachNode(function(node) {
      if (node.rowIndex === 0) {
        node.setSelected(true);
        var selectedData = node.data;
        console.log(selectedData);
       
    // dataforcancel(selectedData);
      }
    });
    this.cancelrow2();

    }  
    
    
    cancelrow2()
    {
      var selectedData = this.gridApi.getSelectedRows();
      var res = this.gridApi.updateRowData({ remove: selectedData });
      this.addbutton = false;
    }



  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit()
  }
}
function dataforcancel(selectedData){
  // var res = this.gridApi.updateRowData({ remove: selectedData });
  printResult(selectedData);
  //   printResult(res2);

   }
var newCount = 1;
function createNewRowData() {
  var newData = {
    /*  nombre: '',
    apellido: '',
    CIF: '',
    telefono: '',
    email: '',
    direccion: ''  */
  };
  newCount++;
  return newData;
}
function printResult(res) {
  console.log('---------------------------------------');
  if (res.add) {
    res.add.forEach(function(rowNode) {
      console.log('Added Row Node', rowNode);
    });
  }
  if (res.remove) {
    res.remove.forEach(function(rowNode) {

      console.log('Removed Row Node', rowNode.data.id);
    });
  }
  if (res.update) {
    res.update.forEach(function(rowNode) {
      console.log('Updated Row Node', rowNode);
    });
  }
}
