import { Component, ViewChild, OnInit, RendererStyleFlags2 } from '@angular/core';
import {PagosApService} from './pagos_aplazados.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {LoginService} from 'app/main/content/login/login.service';
import { ButtonRendererComponent } from './renderer.component';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
@Component({
  selector: 'fuse-app-pagos',
  templateUrl: './pagos_aplazados.component.html',
  styleUrls: ['./pagos_aplazados.component.scss']
})
export class PagosApComponent implements OnInit {
  public loading = false;
  public gridApi;
  private gridColumnApi;
  frameworkComponents: any;
 // private rowData: any[];
  trigger: any; 
 // private columnDefs;
  // private rowData;
  private rowSelection;
  checked = true;
  nombre = true;
  apellido = true;
  apellido2 = true;
  butonrowclicked = false;
  addbutton = false;
  dat: any;
  datatosend: any;
  datatomod: any;
  columnDefs: any;
  rowData: any;
  editType: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  constructor(private pagos: PagosApService,  private router: Router,
    private loginService1: LoginService,  public dialog: MatDialog) {
    this.editType = 'fullRow';
    this.rowSelection = 'single';
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    }
   }

  ngOnInit() {
    this.trigger = false;
    if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') ||  localStorage.getItem("id_token") === null) {
      console.log(localStorage)
      this.router.navigate(['/login']);
    } else {
      this.loginService1.checkToken().subscribe(rescat => {
        console.log(rescat);
        if (rescat.response){
          if (rescat.response === 1){


            
            this.getPagos();
        }
        else{

          this.router.navigate(['/login']);
        }
      }
        else{
          this.router.navigate(['/login']);
        }

      })
        }
    
  }

  onBtnClick1(e) {
    window.open("http://lfdi.bitronedev.com/public/" + e.rowData.factura + ".pdf", "_blank");
    console.log(e)
  }
  getPagos(){
    this.loading = true;
    this.columnDefs = [];
    this.pagos.getPagos()
    .subscribe(res => {
      this.rowData = res.pagos;
      console.log(res);

        for (let key in res.pagos[0]) {
          if (key === 'id') {
            this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', hide: true,  rowDrag: true, width: 90,   type: 'numericColumn'  };
            console.log('      key:', key);
            this.columnDefs.push(this.dat);
            // console.log(this.columnDefs);

        }       /*  else  if (key === 'pagado') {
          this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', hide: true,  width: 100 };
          console.log('      key:', key);
          this.columnDefs.push(this.dat);
          // console.log(this.columnDefs);

      } */
      else  if (key === 'cliente') {
        this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter',   width: 100 };
        console.log('      key:', key);
        this.columnDefs.push(this.dat);
        // console.log(this.columnDefs);

    }
/*     else  if (key === 'plazos_pendientes') {
      this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter',   width: 150 };
      console.log('      key:', key);
      this.columnDefs.push(this.dat);
      

  } */
      else  if (key === 'fecha') {
        this.dat = {headerName: 'fecha', field: key,  width: 100, editable: true,  valueFormatter: this.dateFormatter,  cellEditor: "datePicker", 

    
  };

  
        console.log('      key:', key);
        this.columnDefs.push(this.dat);

  
    }


    else  if (key === 'Total') {
      this.dat = {headerName: 'Total', field: key,  width: 100, editable: true,  valueFormatter: this.dateFormatter, hide: true};
      console.log('      key:', key);
      this.columnDefs.push(this.dat);


  }


        else{
          this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter',  editable : true };
          console.log('      key2:', key);
          this.columnDefs.push(this.dat);

        }
          }
          this.trigger = true;
          console.log(this.columnDefs);
         
    


          this.loading = false;
      });
  }

  dateFormatter(params) {
  
    // return params.value ? (new Date(params.value)).toLocaleDateString() : '';
    var timestamp = Date.parse(params.value);
 
 if (isNaN(timestamp) === false) {
   var d = new Date(timestamp).toLocaleDateString();
   console.log(d)
   return d
  
 }
 console.log("222222222")
 // return timestamp
   }

/*   saveRow(){
    this.gridApi.stopEditing();
    this.pagos.addPagos2(this.datatosend)
    .subscribe(res => {
      
console.log(res);
    });
  } */

  getRowData() {
    var rowData = [];
    this.gridApi.forEachNode(function(node) {
      rowData.push(node.data);
    });
    console.log('Row Data:');
    console.log(rowData);
  }

  clearData() {
    this.gridApi.setRowData([]);
  }

  onAddRow() {
    this.addbutton = true;
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0});
    this.datatosend = res.add[0].data;

     
    
    console.log( this.datatosend);
  }
  modclient(){
    console.log(this.datatomod);
    

  }
  onRemoveSelected()
  {
    var selectedData = this.gridApi.getSelectedRows();
    if (selectedData.length !== 0){
      console.log(selectedData);
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = '¿Eliminar ' + selectedData[0].nombre + '?';

      this.confirmDialogRef.afterClosed().subscribe(result => {
          if ( result )
          {
           // var selectedData = this.gridApi.getSelectedRows();

            
            var res = this.gridApi.updateRowData({ remove: selectedData });
            console.log(res.remove[0].data.id);
            this.pagos.remPagos(res.remove[0].data.id)
            .subscribe(res2 => {
        console.log(res2);
            });
            printResult(res);
          }
          this.confirmDialogRef = null;
      });
    }
  }
  onRowClicked(event: any) { console.log('row', event);

this.butonrowclicked = true;
}
onRowValueChanged(event: any){console.log('row', event.data);
this.datatomod = event.data;
if (this.datatomod){
  this.pagos.modPagos(this.datatomod)
  .subscribe(res => {
      
    console.log(res);
        });
// this.modclient();
}
}
  
  showHide(e) {
    // var checked = e.target.checked;
    console.log(e.source.id);
   this.gridColumnApi.setColumnVisible(e.source.id, e.checked);
  }


  cancelrow(){
    this.gridApi.forEachNode(function(node) {
      if (node.rowIndex === 0) {
        node.setSelected(true);
        var selectedData = node.data;
        console.log(selectedData);
       
    // dataforcancel(selectedData);
      }
    });
    this.cancelrow2();

    }  
    
    
    cancelrow2()
    {
      var selectedData = this.gridApi.getSelectedRows();
      var res = this.gridApi.updateRowData({ remove: selectedData });
      this.addbutton = false;
    }



  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


}
function dataforcancel(selectedData){
  // var res = this.gridApi.updateRowData({ remove: selectedData });
  printResult(selectedData);
  //   printResult(res2);

   }
var newCount = 1;
function createNewRowData() {
  var newData = {
     nombre: '',
    apellido: '',
    CIF: '',
    telefono: '',
    email: '',
    direccion: '' 
  };
  newCount++;
  return newData;
}
function printResult(res) {
  console.log('---------------------------------------');
  if (res.add) {
    res.add.forEach(function(rowNode) {
      console.log('Added Row Node', rowNode);
    });
  }
  if (res.remove) {
    res.remove.forEach(function(rowNode) {

      console.log('Removed Row Node', rowNode.data.id);
    });
  }
  if (res.update) {
    res.update.forEach(function(rowNode) {
      console.log('Updated Row Node', rowNode);
    });
  }
}
