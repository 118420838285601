import { Component, ViewChild, OnInit, RendererStyleFlags2, Inject, OnDestroy, AfterViewInit, Input } from '@angular/core';
import {PresupuestosService} from './presupuestos.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatSelect, VERSION } from '@angular/material';
import {Anadirpagocomponent2Component} from '../anadirpagocomponent2/anadirpagocomponent2.component'
import {LoginService} from 'app/main/content/login/login.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ButtonRendererComponent } from './renderer.component';
declare var jquery: any; declare var $: any;
interface Bank {
  id: string;
  name: string;
}
@Component({
  selector: 'fuse-my-app',
  templateUrl: './presupuestos.component.html',
  styleUrls: ['./presupuestos.component.scss']
})
export class PresupuestosComponent implements OnInit  {
  public loading = false;
  public gridApi;
  private gridColumnApi;
  frameworkComponents: any;
 // private rowData: any[];
  trigger: any; 
 // private columnDefs;
  // private rowData;
  curfecha:any
  private rowSelection;
  checked = true;
  nombre = true;
  apellido = true;
  apellido2 = true;
  butonrowclicked = false;
  addbutton = false;
  dat: any;
  armtest: any;
  datatosend: any;
  datatomod: any;
  rowDataClicked1 = {};
  columnDefs: any;
  rowData: any;
  editType: any;
  sidebar: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  private components;


/** control for the selected bank */














  
  constructor(private presupuestos: PresupuestosService,  private router: Router,
    private loginService1: LoginService,   public dialog: MatDialog) {
      this.frameworkComponents = {
        buttonRenderer: ButtonRendererComponent,
      }
    this.editType = 'fullRow';
    this.rowSelection = 'single';
    this.sidebar = 'exav';

  }
  ngOnInit() {
    // set initial selection
    if (localStorage.getItem('id_token') === "" || !localStorage.getItem('id_token') ||  localStorage.getItem("id_token") === null) {
      console.log(localStorage)
      this.router.navigate(['/login']);
    } else {
      this.loginService1.checkToken().subscribe(rescat => {
        console.log(rescat);
        if (rescat.response){
          if (rescat.response === 1){


            
            this.getClientes2();
        }
        else{

          this.router.navigate(['/login']);
        }
      }
        else{
          this.router.navigate(['/login']);
        }

      })
        }

    this.trigger = false;
  // this.getClientes2();

  }





/** list of banks filtered by search keyword */




openDialog(rowindex): void {
  const dialogRef = this.dialog.open(DialogDataExampleDialogComponent, {
    width: '800px',
    height: '800px',
    
  });

  dialogRef.afterClosed().subscribe(result => {
    var newItem =  {
      cliente: result.client,
      proyecto: result.proyecto,
      servicio: result.servicio,
      fecha: result.fecha,
      numero: result.numero,
      importe: result.importe_total
      /* importe1: result.importe1,
      importe2: result.importe2,
      importe3: result.importe3,
      importe4: result.importe4,
      importe5: result.importe5,
      importe6: result.importe6,
      importe7: result.importe7,
      importe8: result.importe8,
      concepto1: result.concepto1,
      concepto2: result.concepto2,
      concepto3: result.concepto3,
      concepto4: result.concepto4,
      concepto5: result.concepto5,
      concepto6: result.concepto6,
      concepto7: result.concepto7,
      concepto8: result.concepto8,
       */
    };
    var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0 });
    window.open('http://lfdi.bitronedev.com/public/' + result.numero + '.pdf', "_blank");
  });
}




anadirpago(rowindex): void {
  this.curfecha = new Date()


  const dialogRef = this.dialog.open(Anadirpagocomponent2Component, {
    width: '800px',
    height: '800px',
   /*  data: {
      cliente: this.name,
      proyecto: this.proyecto,
      fecha: this.curfecha
    } */
     });
  dialogRef.afterClosed().subscribe(result => {
    console.log(result)
     });
}





/* openDialog(rowindex): void {
  const dialogRef = this.dialog.open(DialogDataExampleDialogComponent, {
    width: '450px',
    height: '450px',
    
  });

  dialogRef.afterClosed().subscribe(result => {
    var rowNode = this.gridApi.getDisplayedRowAtIndex(rowindex);
    rowNode.setDataValue('apellido',  result.age);
   // this.armtest = result.age;
   var dataformod = this.gridApi.getRowNode(rowindex);
   this.presupuestos.modClientes(dataformod.data)
   .subscribe(res => {
       
     console.log(res);
         });
   console.log(this.armtest);
  });
} */
onBtnClick1(e) {
  window.open("http://lfdi.bitronedev.com/public/" + e.rowData.numero + ".pdf", "_blank");
  console.log(e)
}
  getClientes2(){
    this.columnDefs = [{
      headerName: 'Archivo',
      cellRenderer: 'buttonRenderer',
      cellRendererParams: {
         onClick: this.onBtnClick1.bind(this),
        label: 'Ver'
      },
    }];

    this.components = { datePicker: getDatePicker() };
    function getDatePicker() {
      function Datepicker() {}
      Datepicker.prototype.init = function(params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        $(this.eInput).datepicker({ dateFormat: "dd/mm/yy" });
      };
      Datepicker.prototype.getGui = function() {
        return this.eInput;
      };
      Datepicker.prototype.afterGuiAttached = function() {
        this.eInput.focus();
        this.eInput.select();
      };
      Datepicker.prototype.getValue = function() {
        return this.eInput.value;
      };
      Datepicker.prototype.destroy = function() {};
      Datepicker.prototype.isPopup = function() {
        return false;
      };
      return Datepicker;
    }






    this.loading = true;
    this.presupuestos.getPresupuestos()
    .subscribe(res => {
      this.rowData = res.presupuestos;
      console.log(res);

        for (let key in res.presupuestos[0]) {
          if (key === 'id') {
            this.dat = {headerName: key, field: key, hide: true,  filter: 'agTextColumnFilter', rowDrag: true, width: 90,   type: 'numericColumn'  };
            console.log('      key:', key);
            this.columnDefs.push(this.dat);
            // console.log(this.columnDefs);

        }      
          
      else  if (key === 'Archivo') {
        this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter',  width: 90, editable: true };
        console.log('      key:', key);
        this.columnDefs.push(this.dat);
        // console.log(this.columnDefs);

    }
    else  if (key === 'fecha_mod') {
      this.dat = {headerName: key, field: key, hide: true, filter: 'agTextColumnFilter',  width: 90, editable: true };
      console.log('      key:', key);
      this.columnDefs.push(this.dat);
      // console.log(this.columnDefs);

  }
  else  if (key === 'email') {
    this.dat = {headerName: key, field: key, hide: true, filter: 'agTextColumnFilter',  width: 90, editable: true };
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    // console.log(this.columnDefs);

}
else  if (key === 'venta_id') {
  this.dat = {headerName: key, field: key, hide: true, filter: 'agTextColumnFilter',  width: 90, editable: true };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}
else  if (key === 'id_cliente') {
  this.dat = {headerName: key, field: key, hide: true, filter: 'agTextColumnFilter',  width: 90, editable: true };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}
else  if (key === 'estado') {
  this.dat = {headerName: key, field: key, hide: true, filter: 'agTextColumnFilter',  width: 90, editable: true };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}
    else  if (key === 'CIF') {
      this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter',  width: 100, editable: true};
      console.log('      key:', key);
      this.columnDefs.push(this.dat);
      // console.log(this.columnDefs);

  }
  else  if (key === 'telefono') {
    this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter',  width: 100, editable: true, type: 'numericColumn'  };
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    // console.log(this.columnDefs);

}
  else  if (key === 'codigo_postal') {
    this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter',  width: 100, editable: true, type: 'numericColumn' };
    console.log('      key:', key);
    this.columnDefs.push(this.dat);
    // console.log(this.columnDefs);

}
else  if (key === 'ciudad') {
  this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter',  width: 100, editable: true };
  console.log('      key:', key);
  this.columnDefs.push(this.dat);
  // console.log(this.columnDefs);

}
        else{
          this.dat = {headerName: key, field: key,  filter: 'agTextColumnFilter', 
          filterParams: {
            filterOptions: ['contains', 'notContains'],
            textFormatter: function(r) {
              if (r == null){ return null}
              var r = r.toLowerCase();
              // r = r.replace(new RegExp("\\s", 'g'),"");
              r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
              r = r.replace(new RegExp('æ', 'g'), 'ae');
              r = r.replace(new RegExp('ç', 'g'), 'c');
              r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
              r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
              r = r.replace(new RegExp('ñ', 'g'), 'n');
              r = r.replace(new RegExp('[òóôõøö]', 'g'), 'o');
              r = r.replace(new RegExp('œ', 'g'), 'oe');
              r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
              r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
              return r;
            },
            debounceMs: 0,
            caseSensitive: false,
           // suppressAndOrCondition: true,
          },
          
          editable : true };
          console.log('      key2:', key);
          this.columnDefs.push(this.dat);

        }
          }
          this.trigger = true;
          console.log(this.columnDefs);
         
    

          this.loading = false;

      });
  }
/*  
  setPriceOnToyota() {   
    var rowNode = this.gridApi.getRowNode("aa");
    var newPrice = Math.floor(Math.random() * 100000);
    rowNode.setDataValue("price", newPrice);
  } */

  saveRow(){
    this.addbutton = false;
    this.gridApi.stopEditing();
    this.presupuestos.addClientes(this.datatosend)
    .subscribe(res => {
      
console.log(res);
    });
  }

  getRowData() {
    var rowData = [];
    this.gridApi.forEachNode(function(node) {
      rowData.push(node.data);
    });
    console.log('Row Data:');
    console.log(rowData);
  }

  clearData() {
    this.gridApi.setRowData([]);
  }

  onAddRow() {
    this.gridApi.setFocusedCell(0, 'nombre', null);
    this.addbutton = true;
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({ add: [newItem], addIndex: 0});
    this.datatosend = res.add[0].data;

     
    
    console.log( this.datatosend);
  }
  modclient(){
    console.log(this.datatomod);
    

  }

  addItems() {
    var newItems = [createNewRowData(), createNewRowData(), createNewRowData()];
    var res = this.gridApi.updateRowData({ add: newItems });
    printResult(res);
  }

  addItemsAtIndex() {
    var newItems = [createNewRowData(), createNewRowData(), createNewRowData()];
    var res = this.gridApi.updateRowData({
      add: newItems,
      addIndex: 2
    });
    printResult(res);
  }

  updateItems() {
    var itemsToUpdate = [];
    this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
      if (index >= 5) {
        return;
      }
      var data = rowNode.data;
      data.price = Math.floor(Math.random() * 20000 + 20000);
      itemsToUpdate.push(data);
    });
    var res = this.gridApi.updateRowData({ update: itemsToUpdate });
    printResult(res);
  }

  onInsertRowAt2() {
    var newItem = createNewRowData();
    var res = this.gridApi.updateRowData({
      add: [newItem],
      addIndex: 0
    });
    printResult(res);
  }
  onRemoveSelected()
  {
    var selectedData = this.gridApi.getSelectedRows();
    if (selectedData.length !== 0){
      console.log(selectedData);
      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = '¿Eliminar ' + selectedData[0].proyecto + '-' + selectedData[0].servicio + '?';

      this.confirmDialogRef.afterClosed().subscribe(result => {
          if ( result )
          {
           // var selectedData = this.gridApi.getSelectedRows();

            
            var res = this.gridApi.updateRowData({ remove: selectedData });
            console.log(res.remove[0].data.id);
            this.presupuestos.remPresu(res.remove[0].data.id)
            .subscribe(res2 => {
        console.log(res2);
            });
            printResult(res);
          }
          this.confirmDialogRef = null;
      });
    }
  }
  onCellDoubleClicked(event1: any) { console.log('row', event1); 

if (event1.colDef.field === 'fecha_contacto' && this.addbutton === false){

  var armindex = this.gridApi.getFocusedCell();
  console.log(armindex);
   this.gridApi.stopEditing();
  this.openDialog(armindex.rowIndex);

}

}

testfunc(){

 var armpordz = this.gridApi.getRowNode(0);
 console.log(armpordz);
}


  onRowClicked(event: any) { console.log('row', event);
//  window.open("http://lfdi.bitronedev.com/public/" + event.data.numero + ".pdf", "_blank");
this.butonrowclicked = true;
}


onRowValueChanged(event: any){console.log('row', event.data);
this.datatomod = event.data;
if (this.datatomod){
  this.presupuestos.modClientes(this.datatomod)
  .subscribe(res => {
      
    console.log(res);
        });
// this.modclient();
}
}
  
  showHide(e) {
    // var checked = e.target.checked;
    console.log(e.source.id);
   this.gridColumnApi.setColumnVisible(e.source.id, e.checked);
  }


  cancelrow(){
    this.addbutton = false;
    this.gridApi.forEachNode(function(node) {
      if (node.rowIndex === 0) {
        node.setSelected(true);
        var selectedData = node.data;
        console.log(selectedData);
       
    // dataforcancel(selectedData);
      }
    });
    this.cancelrow2();

    }  
    
    
    cancelrow2()
    {
      var selectedData = this.gridApi.getSelectedRows();
      var res = this.gridApi.updateRowData({ remove: selectedData });
      this.addbutton = false;
    }



  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
}
function dataforcancel(selectedData){
  // var res = this.gridApi.updateRowData({ remove: selectedData });
  printResult(selectedData);
  //   printResult(res2);

   }
var newCount = 1;
function createNewRowData() {
  var newData = {
     nombre: '',
    apellido: '',
    CIF: '',
    telefono: '',
    email: '',
    direccion: '' 
  };
  newCount++;
  return newData;
}
function printResult(res) {
  console.log('---------------------------------------');
  if (res.add) {
    res.add.forEach(function(rowNode) {
      console.log('Added Row Node', rowNode);
    });
  }
  if (res.remove) {
    res.remove.forEach(function(rowNode) {

      console.log('Removed Row Node', rowNode.data.id);
    });
  }
  if (res.update) {
    res.update.forEach(function(rowNode) {
      console.log('Updated Row Node', rowNode);
    });
  }
}
@Component({
  selector: 'fuse-dialog-data-example-dialog',
  templateUrl: 'dialog-data-example-dialog.html',
})

export class DialogDataExampleDialogComponent implements OnInit, OnDestroy, AfterViewInit {  
  armtest: any;
  username: any;
  proyectos: any;
  servicios: any;
  Clientes2: any ;
  remitentes: any;
  selectedValue: any;
  selectedValue2: any;
  client: any;
  fecha: any;
  uid: any;
  numero_presup: any;
  choosedservice: any;
  servicename: any;
  triger: any;
  servicedata: any;
  importe_total: any;
  importe1: any;
  importe2: any;
  importe3: any;
  importe4: any;
  importe5: any;
  importe6: any;
  importe7: any;
  importe8: any;
concepto1: any;
concepto2: any;
concepto3: any;
concepto4: any;
concepto5: any;
concepto6: any;
concepto7: any;
concepto8: any;
selection: any;
  @Input() sideBar: PresupuestosComponent; 
  
  public bankCtrl: FormControl = new FormControl();
  private clientes: any; 
    
    /** control for the MatSelect filter keyword */
  public bankFilterCtrl: FormControl = new FormControl();

  /** list of clientes */

  
  
  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  
  
  
  @ViewChild('singleSelect') singleSelect: MatSelect;
  
  
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  

  ngOnInit() {
    this.remitentes=[
      {id:0, nombre:'LFDI', value:1},
      {id:1, nombre:'TIE', value:2},
      {id:2, nombre:'Begoña', value:3},
      {id:3, nombre:'YLENIA', value:4},
      {id:4, nombre:'Carlos', value:5},
      {id:5, nombre:'Sara', value:6},
      {id:6, nombre:'Maria', value:7},
      {id:7, nombre:'David', value:8}

    ]
    this.importe1=0
    this.importe2=0
    this.importe3=0
    this.importe4=0
    this.importe5=0
    this.importe6=0;
    this.importe7=0;
    this.importe8=0;
    this.triger = 0;
    this.selection="1";
    this.presupuestos.getlastid().subscribe(res2 => {
    console.log(res2.presupuestos2[0].numero)
    let presunum = res2.presupuestos2[0].numero
    this.numero_presup = parseInt(presunum, 10)
    this.numero_presup = this.numero_presup + 1
    console.log(this.numero_presup)
    })
    this.presupuestos.getServicios()
    .subscribe(res => {
this.servicios = res.servicios;
console.log(this.servicios)
this.servicios.push({id: 3, nombre: "Elegir servicio", plazo:0, precio:0});
console.log(this.servicios)
this.bankCtrl.setValue(this.servicios[20]);
    


    this.filteredBanks.next(this.servicios.slice());
    this.bankFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterBanks();
    });
    })
  

    // listen for search field value changes

    }

    getlistCliente(){


    };
    ngAfterViewInit() {
      this.setInitialValue();
    }
    
    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
    
    private setInitialValue() {
      this.filteredBanks
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          // setting the compareWith property to a comparison function
          // triggers initializing the selection according to the initial value of
          // the form control (i.e. _initializeSelection())
          // this needs to be done after the filteredBanks are loaded initially
          // and after the mat-option elements are available
          this.singleSelect.compareWith = (a: Bank, b: Bank) => a.id === b.id;
         
        });
    }
    private filterBanks() {
      if (!this.servicios) {
        return;
      }
      // get the search keyword
      let search = this.bankFilterCtrl.value;
      if (!search) {
        this.filteredBanks.next(this.servicios.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the clientes
      this.filteredBanks.next(
        this.servicios.filter(servicios => servicios.nombre.toLowerCase().indexOf(search) > -1)
      );
    }


  /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
    constructor(public dialogRef: MatDialogRef<DialogDataExampleDialogComponent>, private presupuestos: PresupuestosService, @Inject(MAT_DIALOG_DATA) public data: any) {
      this.client = data.cliente
      this.selectedValue = data.proyecto
      this.fecha = data.fecha
      this.uid = data.uid;
      this.selectedValue2 = "remitente"
      console.log(data)
    }
    
    saveData(bankCtrl){

  this.triger = 0;
      console.log(bankCtrl.value);
      this.servicename = bankCtrl.value.nombre;
      this.choosedservice = bankCtrl.value.id;
      console.log(this.choosedservice)
        this.servicedata = bankCtrl.value;
     if (this.choosedservice == 28){
      this.concepto1 = "REGISTRO";
      this.concepto2 = "TASAS";
      
      /* this.concepto2 = "TASA DE TRANSMISIÓN";
      this.concepto3 = "TASA DE BÚSQUEDA";
      this.concepto4 = "TASA DE PRESENTACIÓN INTERNACIONAL"; */
      /// this.concepto5 = "REDUCCIÓN EN TASAS POR PRESENTACIÓN ELECTRÓNICA";
      this.importe1 = 1399;
       this.importe2 = 3096;
// this.importe5 = -176;
     }
     if (this.choosedservice == 27){
      this.concepto1 = "Realización de Mockup ";
      this.importe1 = 1175;
           }
           if (this.choosedservice == 26){
            this.concepto1 = "Honorarios de registro de marca nacional ";
            this.concepto2 = "Tasas de registro de marca nacional ";
            this.importe1 = 330; //390
this.importe2 = 850;
                 }
                 if (this.choosedservice == 1){
                  this.concepto1 = "Realización de presentación gráfica  ";
                  
                  this.importe1 = 1295; //1080      
                       }
                       if (this.choosedservice == 13){
                        this.concepto1 = "Redacción y registro de modelo de utilidad  ";
                        this.concepto2 = "Tasa de Solicitud ";
                        this.importe1 = 1208.83; //1085
                        this.importe2 = 86.17; //1085
            
                             }
                             if (this.choosedservice == 12){
                              this.concepto1 = "Realización de Estudio de Aplicaciones. ";
                              
                              this.importe1 = 330; //390
                  
                                   }
                                   if (this.choosedservice == 11){
                                    this.concepto1 = "Realización de vídeo comercial. No incluye alquiler de espacios ni de personajes";
                                    
                                    this.importe1 = 1100;
                        
                                         }
                                         if (this.choosedservice == 9){
                                          this.concepto1 = "Honorarios de registro de marca nacional";
                                          this.concepto2 = "Tasas de registro de marca nacional ";
                                          this.importe1 = 168.39;
                                          this.importe2 = 126.61;
                                               }
                                               if (this.choosedservice == 8){
                                                this.concepto1 = "Realización de Estudio de Patentes. Debido a la selección previa de todas las ideas que recibimos, el pago deberá efectuarse antes de 10 días contando desde la fecha del presente  presupuesto, fecha hasta la que tendremos disponibilidad para empezar con el proyecto. De no poderse abonar la cantidad total.";
                                                
                                                this.importe1 = 295;
                                    
                                                     }
                                                     if (this.choosedservice == 4){
                                                      this.concepto1 = "Realización de Estudio de Mercado. Debido a la selección previa de todas las ideas que recibimos, el pago deberá efectuarse antes de 10 días contando desde la fecha del presente  presupuesto, fecha hasta la que tendremos disponibilidad para empezar con el proyecto. De no poderse abonar la cantidad total.";
                                                      
                                                      this.importe1 = 485;
                                          
                                                           }
                                                           if (this.choosedservice == 22){
                                                            this.concepto1 = "Búsqueda de fabricantes y obtención de presupuesto";
                        
                                                            this.importe1 = 500;
                                                
                                                                 }
                                                                 if (this.choosedservice == 15){
                                                                  this.concepto1 = "La realización de los trabajos comerciales, conlleva el pago de una parte fija y el 25% sobre el beneficio que se consiga con los contratos firmados, siendo el otro 75% del beneficio para el inventor";
                                                                 
                                                                  this.importe1 = 1640;
                                                      
                                                                       }
if (this.choosedservice == 29){
this.concepto1 = "El precio del estudio es de 395€, si nos confirmas antes de 7 días desde la fecha de este presupuesto el precio se reduce a 295€";
this.importe1 = 295;
}
if (this.choosedservice == 35){

this.concepto1 = "Dossier grafico";
this.importe1 = 890;
}
/*       this.pagos_r.getProyectosForR(bankCtrl.value.id)
      .subscribe(res => {
        
  this.proyectos = res.proyectos;
  console.log(this.proyectos)

      }) */
    
      }
/*       saveData2(value){

        this.pagos_r.getServicios(value.id)
        .subscribe(res => {
    this.servicios = res.servicios;
    console.log(this.servicios)
  
        })

        console.log(value);
      } */

     

      saveDataTotal(fecha, client, selectedValue, selection, descrip, concepto1, concepto2, concepto3, concepto4, concepto5, concepto6, concepto7, concepto8, importe1, importe2, importe3, importe4, importe5, importe6, importe7, importe8, selectedValue2){
let tipo = typeof importe1;
console.log(tipo);
console.log(fecha._d)
var datefecha = new Date(fecha._d); 
console.log(datefecha);
var dd = datefecha.getDate();
var mm = datefecha.getMonth() + 1; 
var yyyy = datefecha.getFullYear(); 
console.log(dd);
console.log(mm);
console.log(yyyy);
var datefecha1 = dd + '/' + mm + '/' + yyyy; 
console.log(datefecha1);
var datefecha2 = datefecha1.toLocaleString();
        console.log(datefecha2);
        console.log(concepto1);
        console.log(this.selection)
        console.log(descrip);
        console.log(importe1);
        console.log(this.servicedata);
        this.importe_total = (importe1 + importe2 + importe3 + importe4 + importe5 + importe6 + importe7 + importe8).toFixed(0)
        console.log(this.importe_total);
        console.log(this.servicedata);
        this.presupuestos.addPresu(this.numero_presup, this.servicename, datefecha1, client, selectedValue, this.importe_total, this.uid, '', '', selectedValue2, '')
      .subscribe(res => {
        console.log(res);



        if (res.response.status === 1){
          console.log(this.importe_total);
  let sig_numero_presup = this.numero_presup
          this.presupuestos.generatePDF(sig_numero_presup, this.choosedservice, datefecha2, client, selectedValue, selection, descrip, concepto1, concepto2, concepto3, concepto4, concepto5, concepto6, concepto7, concepto8, importe1, importe2, importe3, importe4, importe5, importe6, importe7, importe8, this.importe_total, selectedValue2)
          .subscribe(res2 => {
            console.log(res2);
            var fullDate = datefecha;
            var twoDigitMonth = fullDate.getMonth() + "";
            console.log(twoDigitMonth);
            if (twoDigitMonth.length === 1){
                twoDigitMonth = "0" + twoDigitMonth;
            }
            var twoDigitDate = fullDate.getDate() + "";
            if (twoDigitDate.length === 1){
                twoDigitDate = "0" + twoDigitDate;
            }
            
            
            console.log(twoDigitMonth);
            var currentDate = fullDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate; 
            console.log(currentDate);
            var datefecha3 = dd + '-' + mm + '-' + yyyy; 
            this.dialogRef.close({servicio: this.servicename, proyecto: selectedValue, client: client,  fecha: datefecha3, concepto1: concepto1, concepto2: concepto2, concepto3: concepto3, concepto4: concepto4, concepto5: concepto5, concepto6: concepto6, concepto7: concepto7, concepto8: concepto8, importe1: importe1, importe2: importe2, importe3: importe3, importe4: importe4, importe5: importe5, importe6: importe6, importe7: importe7, importe8: importe8, numero: this.numero_presup, importe_total: this.importe_total });
          })

        }

      })
      
    }

}

