import { ClientesModule } from './main/content/clientes/clientes.module';
import { ClientespendModule } from './main/content/clientespend/clientespend.module';
import { TempproModule } from './main/content/temp_pro/temp_pro.module';
import { TempproHistoryModule } from './main/content/temp_pro_history/temp_pro_history.module';

import { PresupuestosModule } from './main/content/presupuestos/presupuestos.module';
import { ProyectosModule } from './main/content/proyectos/proyectos.module';
import { PatentesModule } from './main/content/patentes/patentes.module'; /*  */
import { ProjectTrackingModule } from './main/content/project_tracking/project_tracking.module';
import { TareasModule } from './main/content/tareas/tareas.module';
import { ProfileModule } from './main/content/profile/profile.module'
import { NgxLoadingModule } from 'ngx-loading';
import { PatModule } from './main/content/patentes/tabs/patentes/pat.module';
import { MuModule } from './main/content/patentes/tabs/mu/mu.module';

import { VentasModule } from './main/content/ventas/ventas.module';
import { VentasNuevasModule } from './main/content/ventas/tabs/nuevaventa/nuevaventa.module';

import { Contactos2Module } from './main/content/contactos2/contactos2.module';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { ServiciosModule } from './main/content/servicios/servicios.module';
import { RrhhModule } from './main/content/rrhh/rrhh.module';
import { PagosModule } from './main/content/pagos/pagos.module';
import { PagosApModule } from './main/content/pagos_aplazados/pagos_aplazados.module';

import { FacturasModule } from './main/content/facturas/facturas.module';

import { PagosrModule } from './main/content/pagos_r/pagos_r.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { LoginModule } from './main/content/login/login.module';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseFakeDbService } from './fuse-fake-db/fuse-fake-db.service';
import { fuseConfig } from './fuse-config';
import { MatFormFieldModule, MatSelectModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AppComponent } from './app.component';
import { FuseMainModule } from './main/main.module';
import { FuseSampleModule } from './main/content/sample/sample.module';
import { FuseProfileComponent } from './main/content/profile/profile.component';


// import { ProjectsComponent } from './projects/projects.component';
import { ClientesComponent } from './main/content/clientes/clientes.component';
import { TempproComponent } from './main/content/temp_pro/temp_pro.component';
import { TempproHistoryComponent } from './main/content/temp_pro_history/temp_pro_history.component';
// import { AnadirpagocomponentComponent } from './main/content/addpago/anadirpagocomponent/anadirpagocomponent.component';


import { ClientespendComponent } from './main/content/clientespend/clientespend.component';
import { PresupuestosComponent } from './main/content/presupuestos/presupuestos.component';
import { PagosComponent } from './main/content/pagos/pagos.component';
import { PagosApComponent } from './main/content/pagos_aplazados/pagos_aplazados.component';


import { FacturasComponent } from './main/content/facturas/facturas.component';

import { FuseLoginComponent } from './main/content/login/login.component';
import { Contactos2Component } from './main/content/contactos2/contactos2.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import { MomentDateModule, MomentDateAdapter} from '@angular/material-moment-adapter';

import { PagosrComponent } from './main/content/pagos_r/pagos_r.component';
import { ServiceEnLineComponent } from './main/content/ServiceEnLine/ServiceEnLine.component';
import { ServiceEnLineModule } from './main/content/ServiceEnLine/ServiceEnLine.module';
import { SectoresModule } from './main/content/sectores/sectores.module';
import { InformesModule } from './main/content/informes/informes.module';
import { InformesComponent } from './main/content/informes/informes.component';

import { SectoresComponent } from './main/content/sectores/sectores.component';
import { FuseContactsComponent } from './main/content/contacts/contacts.component';

import { ProyectosComponent } from './main/content/proyectos/proyectos.component';
import { PatentesComponent } from './main/content/patentes/patentes.component';
import { ServiciosComponent } from './main/content/servicios/servicios.component';
import { RrhhComponent } from './main/content/rrhh/rrhh.component';
import { AgGridModule } from 'ag-grid-angular';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
// import { ProjectTrackingModule } from './main/content/project_tracking/project_tracking.module';
import { ProjectTrackingComponent } from './main/content/project_tracking/project_tracking.component';
import { TareasComponent } from './main/content/tareas/tareas.component';


export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MM YYYY',
    },
};

const appRoutes: Routes = [
    {
        path      : '**',
        redirectTo: 'login'
      //  component: FuseLoginComponent TempproComponent
    },
    {
        path : 'clientes',
        component : ClientesComponent
    },
    {
        path : 'temp_pro',
        component : TempproComponent
    },
    {
        path : 'profile/:id',
        component : FuseProfileComponent
    },
    {
        path : 'temp_pro_history',
        component : TempproHistoryComponent
    },
    {
        path : 'clientespend',
        component : ClientespendComponent
    },
    {
        path : 'presupuestos',
        component : PresupuestosComponent
    },
    {
        path : 'proyectos',
        component : ProyectosComponent
    },
    {
        path : 'patentes',
        component : PatentesComponent
    },
  

    {
    path : 'contactos2',
    component : Contactos2Component
},
    {
        path : 'sectores',
        component : SectoresComponent
    },
        {
        path : 'informes',
        component : InformesComponent
    },
    {
        path : 'ServiceEnLine',
        component : ServiceEnLineComponent
    },
    {
        path : 'servicios',
        component : ServiciosComponent
    },
    {
        path : 'rrhh',
        component : RrhhComponent
    },
    {
        path : 'pagos',
        component : PagosComponent
    },
    {
        path : 'facturas',
        component : FacturasComponent
    },
    {
    path : 'pagos aplazados',
    component : PagosApComponent
},
{
    path : 'tareas',
    component : TareasComponent
},
    {
        path : 'project_tracking',
        component : ProjectTrackingComponent
    },
    
    {
        path : 'pagos_r',
        component : PagosrComponent
    },
];

@NgModule({
    declarations: [
        AppComponent,
        // ProjectsComponent,
        // ClientesComponent
    ],
    providers: [{
        provide: MAT_DATE_LOCALE,
        useValue: 'es'
    },
     {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE]
    }, {
        provide: MAT_DATE_FORMATS,
        useValue: MY_FORMATS
    },
    {provide : LocationStrategy , useClass: HashLocationStrategy}
],
    imports     : [
        ProjectTrackingModule,
        TareasModule,
        LoginModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        SweetAlert2Module.forRoot(),
        NgxLoadingModule.forRoot({}),
        RouterModule.forRoot(appRoutes),
        AgGridModule.withComponents([]),
        TranslateModule.forRoot(),
        MatSelectModule,
        MatFormFieldModule,
        NgxMatSelectSearchModule,
        Contactos2Module,
        // Fuse Main and Shared modules
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        FuseMainModule,
        SectoresModule,
        InformesModule,
        FuseSampleModule,
        ClientesModule,
        TempproModule,
        TempproHistoryModule,
        PatModule,
        MuModule,
       // AnadirpagocomponentComponent,
        ClientespendModule,
        PresupuestosModule,
       // FuseFakeDbService,
        ProyectosModule,
        PatentesModule,
        VentasModule,
        ProfileModule,
        
        
        ServiciosModule,
        ServiceEnLineModule,
        InMemoryWebApiModule.forRoot(FuseFakeDbService, {
            delay             : 0,
            passThruUnknownUrl: true
        }),

        RrhhModule,
        PagosModule,
        PagosApModule,
        FacturasModule,
        PagosrModule
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
