
import {Injectable} from '@angular/core';
// import {AuthHttp} from 'angular2-jwt';
import {Response, Http, Headers, RequestOptions} from '@angular/http';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
@Injectable(/* {
  providedIn: 'root'
} */)
export class VentasService {
  private baseUrl = 'http://lfdi.bitronedev.com/public/';
constructor(public http: Http ) {


   }

   getVentas(): any {
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();

    if (jwt) {
      authHeader.append('Authorization', jwt);
    }

    let options = new RequestOptions({headers: authHeader});

    return this.http.get(`${this.baseUrl}ventas/getVentas`, options)
      .map(this.extractData)
      .catch(this.handleError);
  }
  getVentas2(): any {
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();

    if (jwt) {
      authHeader.append('Authorization', jwt);
    }

    let options = new RequestOptions({headers: authHeader});

    return this.http.get(`${this.baseUrl}ventas/getVentas2`, options)
      .map(this.extractData)
      .catch(this.handleError);
  }
  addVentas(datatosend): Observable<any> {
    console.log(datatosend);
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'nombre=' + datatosend.nombre + '&fecha=' + datatosend.fecha + '&comentarios=' + datatosend.comentarios + '&telefono=' + datatosend.telefono + '&email=' + datatosend.email + '&id=' + datatosend.id + '&proyecto=' + datatosend.proyecto  + '&Empresa=' + datatosend.Empresa ;
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}ventas/addVentas`, body, options)
      .map(this.extractData)
      .catch(this.handleError);
  }




  addVentasExistente(datatosend, id_anterior): Observable<any> {
    console.log(datatosend);
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    if (datatosend.venta_id){
if (datatosend.venta_id === ""){
  datatosend.venta_id = 0
}

    }
    else{
      datatosend.venta_id = 0
    }
console.log(datatosend.venta_id)
    var body = 'nombre=' + datatosend.nombre + '&fecha=' + datatosend.fecha + '&comentarios=' + datatosend.comentarios + '&telefono=' + datatosend.telefono + '&email=' + datatosend.email +  '&proyecto=' + datatosend.proyecto + '&id_anterior=' + id_anterior + '&venta_id=' + datatosend.venta_id + '&Empresa=' + datatosend.Empresa ; 
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}ventas/addVentas`, body, options)
      .map(this.extractData2)
      .catch(this.handleError2);
  }


  modVentas( datatomod ): any {
    
    // let jwt = localStorage.getItem('id_token');
   // let user_id= localStorage.getItem('user_login');
   let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
   let body = 'id=' + datatomod.id + '&nombre=' + datatomod.nombre   + '&fecha=' + datatomod.fecha +  '&telefono=' + datatomod.telefono + '&email=' + datatomod.email + '&comentarios=' + datatomod.comentarios + '&proyecto=' + datatomod.proyecto + '&empresa=' + datatomod.Empresa + '&accion=' + datatomod.accion;
    let authHeader = new Headers();

    if (jwt) {
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');

      authHeader.append('Authorization', jwt);
    //  console.log(authHeader);
    }

    let options = new RequestOptions({headers: authHeader});
 //   console.log(body);

    return this.http.post(`${this.baseUrl}ventas/modVentas`, body,  options).map(this.extractData).catch(this.handleError);
  }

  modVentas3( datatomod ): any {
    console.log(datatomod)
    // let jwt = localStorage.getItem('id_token');
   // let user_id= localStorage.getItem('user_login');
   let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
   let body = 'id=' + datatomod.id + '&nombre=' + datatomod.nombre   + '&fecha=' + datatomod.fecha +  '&telefono=' + datatomod.telefono + '&email=' + datatomod.email + '&comentarios=' + datatomod.comentarios + '&proyecto=' + datatomod.proyecto + '&accion=' + datatomod.accion ;
    let authHeader = new Headers();

    if (jwt) {
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');

      authHeader.append('Authorization', jwt);
    //  console.log(authHeader);
    }

    let options = new RequestOptions({headers: authHeader});
 //   console.log(body);

    return this.http.post(`${this.baseUrl}ventas/modVentas3`, body,  options).map(this.extractData2).catch(this.handleError);
  }

  modVentas2( datatomod ): any {
    console.log(datatomod)
    // let jwt = localStorage.getItem('id_token');
   // let user_id= localStorage.getItem('user_login');
   let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
   let body = 'id=' + datatomod ;
    let authHeader = new Headers();

    if (jwt) {
        authHeader.append('Content-Type', 'application/x-www-form-urlencoded');

      authHeader.append('Authorization', jwt);
    //  console.log(authHeader);
    }

    let options = new RequestOptions({headers: authHeader});
 //   console.log(body);

    return this.http.post(`${this.baseUrl}ventas/modVentas2`, body,  options).map(this.extractData).catch(this.handleError);
  }

  















  remVentas(datatosend): Observable<any> {
   
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'id=' + datatosend  ;
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}ventas/remVentas`, body, options)
      .map(this.extractData2)
      .catch(this.handleError);
  }


  addClientes(client, CIF, fecha_contacto, proyecto, telefono, email, direccion, codigo_postal, ciudad, empresa, descrip, service_id, choosedservice, uid, pagado, aplazado, pais, remitente, totalapagar, id_anterior, presupuesto): Observable<any> {
    console.log(client, CIF, fecha_contacto, proyecto, telefono, email, direccion, codigo_postal, ciudad, empresa, descrip, service_id, choosedservice, uid, pagado, aplazado, pais, remitente, totalapagar, id_anterior, presupuesto)
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'nombre=' + client + '&apellido=' + '-' + '&apellido2=' + '-' + '&CIF=' + CIF + '&fecha_contacto=' + fecha_contacto + '&fecha_ini=' + '-' + '&telefono=' + telefono + '&proyectos=' + proyecto + '&email=' + email + '&direccion=' + direccion + '&codigo_postal=' + codigo_postal + '&ciudad=' + ciudad + '&empresa=' + empresa + '&comentarios=' + descrip + '&servicios=' + choosedservice + '&servicios_id=' + service_id + '&sectornames=' + '-' + '&personas_encargados=' + '-' + '&estado=' + '-' + '&manager=' + '-' + '&comentarios=' + '-' + '&uid=' + uid + '&pagado=' + pagado + '&aplazado=' + aplazado + '&pais=' + pais + '&remitente=' + remitente + '&totalapagar=' + totalapagar
    + '&id_anterior=' + id_anterior + '&presupuesto=' + presupuesto;
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}clientes/addClientes`, body, options)
      .map(this.extractData)
      .catch(this.handleError);
  }


  createInvoice(client, CIF, fecha_contacto, proyecto, telefono, email, direccion, codigo_postal, ciudad, empresa, descrip, service_id, choosedservice, pagado,  pais, remitente): Observable<any> {
    console.log(client, CIF, fecha_contacto, proyecto, telefono, email, direccion, codigo_postal, ciudad, empresa, descrip, service_id, choosedservice, pagado,  pais, remitente)
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'client=' + client + '&apellido=' + '-' + '&apellido2=' + '-' + '&CIF=' + CIF + '&fecha_contacto=' + fecha_contacto + '&fecha_ini=' + '-' + '&telefono=' + telefono + '&proyectos=' + proyecto + '&email=' + email + '&direccion=' + direccion + '&codigo_postal=' + codigo_postal + '&ciudad=' + ciudad + '&empresa=' + empresa + '&comentarios=' + descrip + '&servicios=' + choosedservice + '&servicios_id=' + service_id + '&pagado=' + pagado + '&pais=' + pais + '&remitente=' + remitente + '&sectornames=' + '-' + '&personas_encargados=' + '-' + '&estado=' + '-' + '&manager=' + '-' + '&comentarios=' + '-';
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}pdf/createInvoice`, body, options)
      .map(this.extractData)
      .catch(this.handleError);
  }



  checkemail(datatosend): Observable<any> {
    console.log(datatosend);
    let jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1Mjg5MTg4ODMsImV4cCI6MTUzNjY5NDg4MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjEwIn19fQ.ttkdipBl6rCdGSvKyx7Zg3jd-Ee6ntUCGaup5LZ6JV0'; // localStorage.getItem('id_token');
    let authHeader = new Headers();
    authHeader.append('Content-Type', 'application/x-www-form-urlencoded');
    
    if (jwt) {
      authHeader.append('Authorization', jwt);
    }
    let body = 'email=' + datatosend;
    
    let options = new RequestOptions({headers: authHeader});

    return this.http.post(`${this.baseUrl}clientes/checkemail`, body, options)
      .map(this.extractData)
      .catch(this.handleError);
  }





  /**
   * Extracting data.
   *
   * @param res
   * @returns {any|{}}
   */
  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private extractData2(res: Response) {
    let body = res;
    return body || {};
  }
 /**
  * Handling errors.
  *
  * @param error
  * @returns {ErrorObservable}
  */

  private handleError2(error: any) {
    let body = error;
    return body || {};
  }
 private handleError(error: any) {
   let errMsg = (error.message) ? error.message :
     error.status ? `${error.status} - ${error.statusText}` : 'Server error';
   console.log(errMsg); // log to console instead
   console.log(error)
   return Observable.throw(errMsg);
 }
}

