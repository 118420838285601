import { Component, ViewChild, OnInit, RendererStyleFlags2, Inject, OnDestroy, AfterViewInit, Input } from '@angular/core';
import {VentasService} from '../ventas/ventas.service';
import {PresupuestosService} from '../presupuestos/presupuestos.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseConfirmDialog2Component } from '@fuse/components/confirm-dialog2/confirm-dialog2.component';
import { FuseConfirmDialog3Component } from '@fuse/components/confirm-dialog3/confirm-dialog3.component';
import { FuseConfirmDialog4Component } from '@fuse/components/confirm-dialog4/confirm-dialog4.component';



import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatSelect, VERSION } from '@angular/material';
import {LoginService} from 'app/main/content/login/login.service';
interface Bank {
  id: string;
  name: string;
}
@Component({
  selector: 'fuse-dialog-data-example-dialog',
  templateUrl: 'crearpresupuestos.component.html',
})

export class CrearPresupuestoComponent implements OnInit, OnDestroy, AfterViewInit {  
  armtest: any;
  client: any;
  fecha: any;
  remitentes: any;
  selectedValue: any;
  selectedValue2: any;
  username: any;
  proyectos: any;
  servicios: any;
  Clientes2: any ;
  uid: any;
  numero_presup: any;
  choosedservice: any;
  servicename: any;
  fechafalta: any;
  triger: any;
  tel: any;
  email: any;
  servicedata: any;
  importe_total: any;
  importe1: any;
  importe2: any;
  importe3: any;
  importe4: any;
  importe5: any;
  importe6: any;
  importe7: any;
  importe8: any;
concepto1: any;
concepto2: any;
concepto3: any;
concepto4: any;
concepto5: any;
concepto6: any;
concepto7: any;
concepto8: any;
selection: any;
id_anterior: any;
 // @Input() sideBar: VentasComponent; 
  
  public bankCtrl: FormControl = new FormControl();
  private clientes: any; 
    
    /** control for the MatSelect filter keyword */
  public bankFilterCtrl: FormControl = new FormControl();

  /** list of clientes */

  
  
  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  
  
  
  @ViewChild('singleSelect') singleSelect: MatSelect;
  
  
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  

  ngOnInit() {
    this.remitentes = [
      {id: 0, nombre: 'LFDI', value: 1},
      {id: 1, nombre: 'TIE', value: 2},
      {id: 2, nombre: 'Begoña', value: 3},
      {id: 3, nombre: 'YLENIA', value: 4},
      {id: 4, nombre: 'Carlos', value: 5},
      {id: 5, nombre: 'Sara', value: 6},
      {id: 6, nombre: 'Maria', value: 7},
      {id: 7, nombre: 'David', value: 8},
      {id: 8, nombre: 'Sergio', value: 9}

    ]
    this.importe1 = 0
    this.importe2 = 0
    this.importe3 = 0
    this.importe4 = 0
    this.importe5 = 0
    this.importe6 = 0;
    this.importe7 = 0;
    this.importe8 = 0;
    this.triger = 0;
    this.selection = "1";
    this.presupuestos.getlastid().subscribe(res2 => {
    console.log(res2.presupuestos2[0].numero)
    let presunum = res2.presupuestos2[0].numero
    this.numero_presup = parseInt(presunum, 10)           
    this.numero_presup = this.numero_presup + 1
    console.log(this.numero_presup)
    })
    this.presupuestos.getServicios()
    .subscribe(res => {
this.servicios = res.servicios;
console.log(this.servicios)
/* this.servicios.push({id: 3, nombre: "Elegir servicio", plazo:0, precio:0}); */
this.servicios.splice(0, 0, {id: 3, nombre: "Elegir servicio", plazo: 0, precio: 0})
console.log(this.servicios)
this.bankCtrl.setValue(this.servicios[0]);
    


    this.filteredBanks.next(this.servicios.slice());
    this.bankFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterBanks();
    });
    })
  

    // listen for search field value changes

    }

    getlistCliente(){


    };
    ngAfterViewInit() {
      this.setInitialValue();
    }
    
    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
    
    private setInitialValue() {
      this.filteredBanks
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          // setting the compareWith property to a comparison function
          // triggers initializing the selection according to the initial value of
          // the form control (i.e. _initializeSelection())
          // this needs to be done after the filteredBanks are loaded initially
          // and after the mat-option elements are available
          this.singleSelect.compareWith = (a: Bank, b: Bank) => a.id === b.id;
         
        });
    }
    private filterBanks() {
      if (!this.servicios) {
        return;
      }
      // get the search keyword
      let search = this.bankFilterCtrl.value;
      if (!search) {
        this.filteredBanks.next(this.servicios.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the clientes
      this.filteredBanks.next(
        this.servicios.filter(servicios => servicios.nombre.toLowerCase().indexOf(search) > -1)
      );
    }


  /* constructor(@Inject(MAT_DIALOG_DATA) public data: any) { */
    constructor(public dialogRef: MatDialogRef<CrearPresupuestoComponent>, private presupuestos: PresupuestosService, @Inject(MAT_DIALOG_DATA) public data: any) {
      this.client = data.cliente
      this.selectedValue = data.proyecto
      this.fecha = data.fecha
      this.tel = data.tel
      this.email = data.email
      this.uid = data.uid;
      this.id_anterior = data.id_anterior
      this.selectedValue2 = "remitente"
      console.log(data)
      

    }
    
    saveData(bankCtrl){

  this.triger = 0;
      console.log(bankCtrl.value);
      this.servicename = bankCtrl.value.nombre;
      this.choosedservice = bankCtrl.value.id;
      console.log(this.choosedservice)
        this.servicedata = bankCtrl.value;
        if (this.choosedservice > 0){
          this.concepto1 = "";
          this.concepto2 = "";
          this.concepto3 = "";
          this.concepto4 = "";
          this.concepto5 = "";
          this.concepto6 = "";
          this.concepto7 = "";
          this.concepto8 = "";
          this.importe1 = 0;
          this.importe2 = 0;
          this.importe3 = 0;
          this.importe4 = 0;
          this.importe5 = 0;
          this.importe6 = 0;
          this.importe7 = 0;
          this.importe8 = 0;
        }
     if (this.choosedservice === 28){
      this.concepto1 = "";
      this.concepto2 = "";
      this.concepto3 = "";
      this.concepto4 = "";
      this.concepto5 = "";
      this.concepto6 = "";
      this.concepto7 = "";
      this.concepto8 = "";
      this.importe1 = 0;
      this.importe2 = 0;
      this.importe3 = 0;
      this.importe4 = 0;
      this.importe5 = 0;
      this.importe6 = 0;
      this.importe7 = 0;
      this.importe8 = 0;

this.concepto1 = "REGISTRO";
this.concepto2 = "TASAS";

/* this.concepto2 = "TASA DE TRANSMISIÓN";
this.concepto3 = "TASA DE BÚSQUEDA";
this.concepto4 = "TASA DE PRESENTACIÓN INTERNACIONAL"; */
/// this.concepto5 = "REDUCCIÓN EN TASAS POR PRESENTACIÓN ELECTRÓNICA";
this.importe1 = 1399;
 this.importe2 = 3096;
/*this.importe3 = 1775;
this.importe4 = 1034; */
/// this.importe5 = -176;
     }
     if (this.choosedservice === 27){
      this.concepto1 = "";
      this.concepto2 = "";
      this.concepto3 = "";
      this.concepto4 = "";
      this.concepto5 = "";
      this.concepto6 = "";
      this.concepto7 = "";
      this.concepto8 = "";
      this.importe1 = 0;
      this.importe2 = 0;
      this.importe3 = 0;
      this.importe4 = 0;
      this.importe5 = 0;
      this.importe6 = 0;
      this.importe7 = 0;
      this.importe8 = 0;
      this.concepto1 = "Realización de Mockup ";
      this.importe1 = 1175;
           }
           if (this.choosedservice === 26){
            this.concepto1 = "";
            this.concepto2 = "";
            this.concepto3 = "";
            this.concepto4 = "";
            this.concepto5 = "";
            this.concepto6 = "";
            this.concepto7 = "";
            this.concepto8 = "";
            this.importe1 = 0;
            this.importe2 = 0;
            this.importe3 = 0;
            this.importe4 = 0;
            this.importe5 = 0;
            this.importe6 = 0;
            this.importe7 = 0;
            this.importe8 = 0;
            this.concepto1 = "Honorarios de registro de marca nacional ";
            this.concepto2 = "Tasas de registro de marca nacional ";
            this.importe1 = 390;
this.importe2 = 850;
                 }
                 if (this.choosedservice === 1){
                  this.concepto1 = "";
                  this.concepto2 = "";
                  this.concepto3 = "";
                  this.concepto4 = "";
                  this.concepto5 = "";
                  this.concepto6 = "";
                  this.concepto7 = "";
                  this.concepto8 = "";
                  this.importe1 = 0;
                  this.importe2 = 0;
                  this.importe3 = 0;
                  this.importe4 = 0;
                  this.importe5 = 0;
                  this.importe6 = 0;
                  this.importe7 = 0;
                  this.importe8 = 0;
                  this.concepto1 = "Realización de presentación gráfica  ";
                  
                  this.importe1 = 1295;
      
                       }
                       if (this.choosedservice === 13){
                        this.concepto1 = "";
                        this.concepto2 = "";
                        this.concepto3 = "";
                        this.concepto4 = "";
                        this.concepto5 = "";
                        this.concepto6 = "";
                        this.concepto7 = "";
                        this.concepto8 = "";
                        this.importe1 = 0;
                        this.importe2 = 0;
                        this.importe3 = 0;
                        this.importe4 = 0;
                        this.importe5 = 0;
                        this.importe6 = 0;
                        this.importe7 = 0;
                        this.importe8 = 0;
                        this.concepto1 = "Redacción y registro de modelo de utilidad  ";
                        this.concepto2 = "Tasa de Solicitud ";
                        this.importe1 = 1208.83; // 1085
                        this.importe2 = 86.17; // 1085
            
                             }
                             if (this.choosedservice === 12){
                              this.concepto1 = "";
                              this.concepto2 = "";
                              this.concepto3 = "";
                              this.concepto4 = "";
                              this.concepto5 = "";
                              this.concepto6 = "";
                              this.concepto7 = "";
                              this.concepto8 = "";
                              this.importe1 = 0;
                              this.importe2 = 0;
                              this.importe3 = 0;
                              this.importe4 = 0;
                              this.importe5 = 0;
                              this.importe6 = 0;
                              this.importe7 = 0;
                              this.importe8 = 0;
                              this.concepto1 = "El precio del estudio es de 430€, si nos confirmas antes de 7 dias desde la fecha de este presupuesto el precio se reduce a 330€"; /* Realización de Estudio de Aplicaciones. */
                              
                              this.importe1 = 330; // 390
                  
                                   }
                                   if (this.choosedservice === 11){
                                    this.concepto1 = "";
                                    this.concepto2 = "";
                                    this.concepto3 = "";
                                    this.concepto4 = "";
                                    this.concepto5 = "";
                                    this.concepto6 = "";
                                    this.concepto7 = "";
                                    this.concepto8 = "";
                                    this.importe1 = 0;
                                    this.importe2 = 0;
                                    this.importe3 = 0;
                                    this.importe4 = 0;
                                    this.importe5 = 0;
                                    this.importe6 = 0;
                                    this.importe7 = 0;
                                    this.importe8 = 0;
                                    this.concepto1 = "Realización de vídeo comercial. No incluye alquiler de espacios ni de personajes";
                                    
                                    this.importe1 = 1100;
                        
                                         }
                                         if (this.choosedservice === 9){
                                          this.concepto1 = "";
                                          this.concepto2 = "";
                                          this.concepto3 = "";
                                          this.concepto4 = "";
                                          this.concepto5 = "";
                                          this.concepto6 = "";
                                          this.concepto7 = "";
                                          this.concepto8 = "";
                                          this.importe1 = 0;
                                          this.importe2 = 0;
                                          this.importe3 = 0;
                                          this.importe4 = 0;
                                          this.importe5 = 0;
                                          this.importe6 = 0;
                                          this.importe7 = 0;
                                          this.importe8 = 0;
                                          this.concepto1 = "Honorarios de registro de marca nacional";
                                          this.concepto2 = "Tasas de registro de marca nacional ";
                                          this.importe1 = 168.39;
                                          this.importe2 = 126.61;
                                               }
                                               if (this.choosedservice === 32){
                                                this.concepto1 = "";
                                                this.concepto2 = "";
                                                this.concepto3 = "";
                                                this.concepto4 = "";
                                                this.concepto5 = "";
                                                this.concepto6 = "";
                                                this.concepto7 = "";
                                                this.concepto8 = "";
                                                this.importe1 = 0;
                                                this.importe2 = 0;
                                                this.importe3 = 0;
                                                this.importe4 = 0;
                                                this.importe5 = 0;
                                                this.importe6 = 0;
                                                this.importe7 = 0;
                                                this.importe8 = 0;
                                                this.concepto1 = "Honorarios de redacción y registro ";
                                                this.concepto2 = "Tasa de solicitud ";
                                                this.concepto3 = "Tasa de Petición IET ";
                                                this.concepto4 = "Tasa de Solicitud de examen sustantivo ";
                                                this.importe1 = 1741.45;
                                                this.importe2 = 86.17;
                                                this.importe3 = 587.77;
                                                this.importe4 = 334.61;
                                                     }
                                               if (this.choosedservice === 8){
                                                this.concepto1 = "";
                                                this.concepto2 = "";
                                                this.concepto3 = "";
                                                this.concepto4 = "";
                                                this.concepto5 = "";
                                                this.concepto6 = "";
                                                this.concepto7 = "";
                                                this.concepto8 = "";
                                                this.importe1 = 0;
                                                this.importe2 = 0;
                                                this.importe3 = 0;
                                                this.importe4 = 0;
                                                this.importe5 = 0;
                                                this.importe6 = 0;
                                                this.importe7 = 0;
                                                this.importe8 = 0;
                                                this.concepto1 = "Realización de Estudio de Patentes. Debido a la selección previa de todas las ideas que recibimos, el pago deberá efectuarse antes de 10 días contando desde la fecha del presente  presupuesto, fecha hasta la que tendremos disponibilidad para empezar con el proyecto. De no poderse abonar la cantidad total podrás pagar una señal de 50€";
                                                
                                                this.importe1 = 295;
                                    
                                                     }
                                                     if (this.choosedservice === 4){
                                                      this.concepto1 = "";
                                                      this.concepto2 = "";
                                                      this.concepto3 = "";
                                                      this.concepto4 = "";
                                                      this.concepto5 = "";
                                                      this.concepto6 = "";
                                                      this.concepto7 = "";
                                                      this.concepto8 = "";
                                                      this.importe1 = 0;
                                                      this.importe2 = 0;
                                                      this.importe3 = 0;
                                                      this.importe4 = 0;
                                                      this.importe5 = 0;
                                                      this.importe6 = 0;
                                                      this.importe7 = 0;
                                                      this.importe8 = 0;
                                                      this.concepto1 = "Realización de Estudio de Mercado. Debido a la selección previa de todas las ideas que recibimos, el pago deberá efectuarse antes de 10 días contando desde la fecha del presente  presupuesto, fecha hasta la que tendremos disponibilidad para empezar con el proyecto. De no poderse abonar la cantidad total podrás pagar una señal de 50€";
                                                      
                                                      this.importe1 = 485;
                                          
                                                           }
                                                           if (this.choosedservice === 22){
                                                            this.concepto1 = "";
                                                            this.concepto2 = "";
                                                            this.concepto3 = "";
                                                            this.concepto4 = "";
                                                            this.concepto5 = "";
                                                            this.concepto6 = "";
                                                            this.concepto7 = "";
                                                            this.concepto8 = "";
                                                            this.importe1 = 0;
                                                            this.importe2 = 0;
                                                            this.importe3 = 0;
                                                            this.importe4 = 0;
                                                            this.importe5 = 0;
                                                            this.importe6 = 0;
                                                            this.importe7 = 0;
                                                            this.importe8 = 0;
                                                            this.concepto1 = "Búsqueda de fabricantes y obtención de presupuesto";
                        
                                                            this.importe1 = 500;
                                                
                                                                 }
                                                                 if (this.choosedservice === 34){
                                                                  this.concepto1 = "";
                                                                  this.concepto2 = "";
                                                                  this.concepto3 = "";
                                                                  this.concepto4 = "";
                                                                  this.concepto5 = "";
                                                                  this.concepto6 = "";
                                                                  this.concepto7 = "";
                                                                  this.concepto8 = "";
                                                                  this.importe1 = 0;
                                                                  this.importe2 = 0;
                                                                  this.importe3 = 0;
                                                                  this.importe4 = 0;
                                                                  this.importe5 = 0;
                                                                  this.importe6 = 0;
                                                                  this.importe7 = 0;
                                                                  this.importe8 = 0;
                                                                  this.concepto1 = "Honorarios de registro de Diseño Industrial ";
                                                                  this.concepto2 = "Tasa de registro de Diseño Industrial";
                                                                  this.importe1 = 324.39;
                                                                  this.importe2 = 65.61;
                                                                       }
                                                                 if (this.choosedservice === 15){
                                                                  this.concepto1 = "";
                                                                  this.concepto2 = "";
                                                                  this.concepto3 = "";
                                                                  this.concepto4 = "";
                                                                  this.concepto5 = "";
                                                                  this.concepto6 = "";
                                                                  this.concepto7 = "";
                                                                  this.concepto8 = "";
                                                                  this.importe1 = 0;
                                                                  this.importe2 = 0;
                                                                  this.importe3 = 0;
                                                                  this.importe4 = 0;
                                                                  this.importe5 = 0;
                                                                  this.importe6 = 0;
                                                                  this.importe7 = 0;
                                                                  this.importe8 = 0;
                                                                  this.concepto1 = "La realización de los trabajos comerciales, conlleva el pago de una parte fija y el 25% sobre el beneficio que se consiga con los contratos firmados, siendo el otro 75% del beneficio para el inventor";
                                                                 
                                                                  this.importe1 = 1640;
                                                      
                                                                       }
if (this.choosedservice === 29){
  this.concepto1 = "";
  this.concepto2 = "";
  this.concepto3 = "";
  this.concepto4 = "";
  this.concepto5 = "";
  this.concepto6 = "";
  this.concepto7 = "";
  this.concepto8 = "";
  this.importe1 = 0;
  this.importe2 = 0;
  this.importe3 = 0;
  this.importe4 = 0;
  this.importe5 = 0;
  this.importe6 = 0;
  this.importe7 = 0;
  this.importe8 = 0;
this.concepto1 = "El precio del estudio es de 395€, si nos confirmas antes de 7 días desde la fecha de este presupuesto el precio se reduce a 295€";
this.importe1 = 295;
}

if (this.choosedservice === 35){
  this.concepto1 = "";
  this.concepto2 = "";
  this.concepto3 = "";
  this.concepto4 = "";
  this.concepto5 = "";
  this.concepto6 = "";
  this.concepto7 = "";
  this.concepto8 = "";
  this.importe1 = 0;
  this.importe2 = 0;
  this.importe3 = 0;
  this.importe4 = 0;
  this.importe5 = 0;
  this.importe6 = 0;
  this.importe7 = 0;
  this.importe8 = 0;
this.concepto1 = "Dossier grafico";
this.importe1 = 890;
}

/*       this.pagos_r.getProyectosForR(bankCtrl.value.id)
      .subscribe(res => {
        
  this.proyectos = res.proyectos;
  console.log(this.proyectos)

      }) */
    
      }
/*       saveData2(value){

        this.pagos_r.getServicios(value.id)
        .subscribe(res => {
    this.servicios = res.servicios;
    console.log(this.servicios)
  
        })

        console.log(value);
      } */

      saveDatatest(){
        console.log(this.selectedValue2)
      }

      saveDataTotal(fecha, client, selectedValue, selection, descrip, concepto1, concepto2, concepto3, concepto4, concepto5, concepto6, concepto7, concepto8, importe1, importe2, importe3, importe4, importe5, importe6, importe7, importe8, uid, tel, email, selectedValue2){
   console.log(selectedValue2)
   console.log(selection)
   console.log(fecha)
   console.log(uid)
        if (fecha === undefined || fecha === ""){
alert("Te falta la fecha ");
this.fechafalta = 1;
   } else if (this.choosedservice === undefined || this.choosedservice === ""){
    alert("Te falta el servicio ");
   }
   else if (concepto1 === undefined || concepto1 === ""){
    alert("Te falta el concepto ");
   }
   else if (importe1 === 0 || importe1 === ""){
    alert("Te falta el importe ");
   }
   else if (client === undefined || client === ""){
    alert("Te falta el cliente ");
   }
   else if (selectedValue === undefined || selectedValue === ""){
    alert("Te falta el proyecto ");
   }
   else{    
/* let tipo = typeof importe1;
console.log(tipo); */
console.log(fecha._d)
var datefecha = new Date(fecha); 
console.log(datefecha);
var dd = datefecha.getDate();
var mm = datefecha.getMonth() + 1; 
var yyyy = datefecha.getFullYear(); 
console.log(dd);
console.log(mm);
console.log(yyyy);
var datefecha1 = dd + '/' + mm + '/' + yyyy; 
console.log(datefecha1);
var datefecha2 = datefecha1.toLocaleString();
        console.log(datefecha2);
        console.log(concepto1);
        console.log(this.selection)
        console.log(descrip);
        console.log(importe1);
        console.log(importe2);
        console.log(importe3);
        console.log(importe4);
        console.log(importe5);
        console.log(importe6);
        console.log(importe7);
        console.log(importe8);

        console.log(this.uid);
        console.log(this.servicedata);
        this.importe_total = (importe1 + importe2 + importe3 + importe4 + importe5 + importe6 + importe7 + importe8)
        console.log(this.importe_total);
        console.log(this.servicedata);
        this.presupuestos.addPresu(this.numero_presup, this.servicename, datefecha2, client, selectedValue, this.importe_total, this.uid, this.tel, this.email, selectedValue2, this.id_anterior)
      .subscribe(res => {
        console.log(res);



        if (res.response.status === 1){
          console.log(this.importe_total);
  let sig_numero_presup = this.numero_presup
          this.presupuestos.generatePDF(sig_numero_presup, this.choosedservice, datefecha2, client, selectedValue, selection, descrip, concepto1, concepto2, concepto3, concepto4, concepto5, concepto6, concepto7, concepto8, importe1, importe2, importe3, importe4, importe5, importe6, importe7, importe8, this.importe_total, selectedValue2)
          .subscribe(res2 => {
            console.log(res2);
            var fullDate = datefecha;
            var twoDigitMonth = fullDate.getMonth() + "";
            console.log(twoDigitMonth);
            if (twoDigitMonth.length === 1){
                twoDigitMonth = "0" + twoDigitMonth;
            }
            var twoDigitDate = fullDate.getDate() + "";
            if (twoDigitDate.length === 1){
                twoDigitDate = "0" + twoDigitDate;
            }
            
            
            console.log(twoDigitMonth);
            var currentDate = fullDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate; 
            console.log(currentDate);
            var datefecha3 = dd + '-' + mm + '-' + yyyy; 
            this.dialogRef.close({servicio: this.servicename, proyecto: selectedValue, client: client,  fecha: datefecha3, concepto1: concepto1, concepto2: concepto2, concepto3: concepto3, concepto4: concepto4, concepto5: concepto5, concepto6: concepto6, concepto7: concepto7, concepto8: concepto8, importe1: importe1, importe2: importe2, importe3: importe3, importe4: importe4, importe5: importe5, importe6: importe6, importe7: importe7, importe8: importe8, numero: this.numero_presup, importe_total: this.importe_total });
          })

        }

      })
    }
    }
  }
